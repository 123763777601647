import React, { useContext, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { LanguageContext } from "../contexts/LanguageContext";
import { useHistory } from "react-router-dom";
import dropdownContent from "../content/dropdownContent";
// z index 1401

const StyledMenu = withStyles({
    paper: {
        // border: "1px solid black",
        paddingTop: "0",
        borderRadius: "0",
    },
})((props) => (
    <Menu
        style={{ paddingTop: "0" }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const styles = {
    "@global": {
        "ul.MuiList-root": {
            borderTop: "1px solid rgba(0,0,0,0.1)",
        },
    },
};

// adds/overrides styles of the menuitem component(in ())
const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.secondary,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.black,
            },
        },
    },
    // component which has its styles overridden
}))(MenuItem);

// const dropdownContent = {
//     bulgarian: {
//         first: "[BG]Bulgarische Schulsystem",
//         second: "[BG]Bildergallerie",
//         third: "[BG]Archiv Dokumente",
//         fourth: "[BG]Geschichte des Projekts",
//     },
//     english: {
//         first: "[ENG]Bulgarische Schulsystem",
//         second: "[ENG]Bildergallerie",
//         third: "[ENG]Archiv Dokumente",
//         fourth: "[ENG]Geschichte des Projekts",
//     },
//     german: {
//         first: "[DE]Bulgarische Schulsystem",
//         second: "[DE]Bildergallerie",
//         third: "[DE]Archiv Dokumente",
//         fourth: "[DE]Geschichte des Projekts",
//     },
// };

function CustomizedMenus({
    anchorEl,
    // handleClick,
    classes,
    handleClose,
}) {
    const { language } = useContext(LanguageContext);
    const styledMenu = useRef(null);
    let history = useHistory();
    // console.log("current", styledMenu.current);

    const { first, second, third, fourth } = dropdownContent[language];

    return (
        <div className={classes.root}>
            <StyledMenu
                ref={styledMenu}
                style={{
                    // border: "2px solid red",
                    padding: "0",
                    margin: "0",
                    zIndex: 1600,
                    // position: "absolute",
                    position: "fixed",
                    top: "18px",

                    // top: "17px",
                }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem
                    onClick={(e) => {
                        history.push("/bgschulsystem");
                        handleClose();
                    }}
                >
                    <ListItemText primary={`${first}`} />
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={(e) => {
                        history.push("/gallery");
                        handleClose();
                    }}
                >
                    <ListItemText primary={`${second}`} />
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={(e) => {
                        history.push("/archive");
                        handleClose();
                    }}
                >
                    <ListItemText primary={`${third}`} />
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={(e) => {
                        history.push("/history");
                        handleClose();
                    }}
                >
                    <ListItemText primary={`${fourth}`} />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}
export default withStyles(styles)(CustomizedMenus);

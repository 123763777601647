import img_1 from "../images/carousel_images/IMG_20201031_0001.jpg";
import img_2 from "../images/carousel_images/IMG_20201031_0002.jpg";
import img_3 from "../images/carousel_images/IMG_20201031_0003.jpg";
import img_4 from "../images/carousel_images/IMG_20201031_0004.jpg";
import img_5 from "../images/carousel_images/IMG_20201031_0005.jpg";
import img_6 from "../images/carousel_images/IMG_20201031_0006.jpg";
import img_7 from "../images/carousel_images/IMG_20201031_0007.jpg";
import img_8 from "../images/carousel_images/IMG_20201031_0008.jpg";
import img_9 from "../images/carousel_images/IMG_20201031_0009.jpg";
import img_10 from "../images/carousel_images/IMG_20201031_0010.jpg";
import img_11 from "../images/carousel_images/IMG_20201031_0011.jpg";
import img_12 from "../images/carousel_images/IMG_20201031_0012.jpg";
import img_13 from "../images/carousel_images/IMG_20201031_0013.jpg";
import img_14 from "../images/carousel_images/IMG_20201031_0014.jpg";
import img_15 from "../images/carousel_images/IMG_20201031_0015.jpg";
import img_16 from "../images/carousel_images/IMG_20201031_0016.jpg";
import img_17 from "../images/carousel_images/IMG_20201031_0017.jpg";
import img_18 from "../images/carousel_images/IMG_20201031_0018.jpg";
import img_19 from "../images/carousel_images/IMG_20201031_0019.jpg";
import img_20 from "../images/carousel_images/IMG_20201031_0020.jpg";
import img_21 from "../images/carousel_images/IMG_20201031_0021.jpg";
import img_22 from "../images/carousel_images/IMG_20201031_0022.jpg";
import img_23 from "../images/carousel_images/IMG_20201031_0023.jpg";

const carousel_images = [
    // img_2,
    img_5,
    img_3,
    img_4,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    // img_14,
    img_15,
    img_16,
    img_17,
    img_18,
    img_1,
    img_19,
    img_20,
    img_21,
    // img_22,
    img_23,
];
export default carousel_images;

import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import sizes from "../styles/sizes";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        borderRadius: "0",
        // justifyContent: "center",
        // maxWidth: 345,
        boxShadow: "none",
        padding: "2rem 2rem",

        backgroundColor: "rgba(255,255,255,0.9)",
        [sizes.down("sm")]: {
            flexFlow: "column",
        },
        height: "100%",
    },
    large: {
        width: "13rem",
        height: "13rem",
        borderBottom: "6.5rem",
    },
    med: {
        width: "6.5rem",
        height: "6.5rem",
    },
}));

export default function ImgMediaCard({
    rolle,
    email,
    title,
    text,
    imageUrl,
    size,
}) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Avatar className={classes[size]} src={imageUrl}></Avatar>
            {/* centers the names of all who have no text about the school yet  */}
            <CardContent style={{ flexGrow: "1" }}>
                <Typography gutterBottom variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                    {rolle}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {text}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {email}
                </Typography>
            </CardContent>
        </Card>
    );
}

const systemTablesContent = {
    english: {
        first: {
            firstCol: [
                "The school year grades in the main education report after the 7th grade",
                "A (6)	",
                "B (5)",
                "C (4)",
                "D (3)",
                "F (2)",
            ],
            secondCol: ["Points", "50", "39", "26", "15", "0"],
        },
        second: {
            firstCol: [
                "Results of both exams (Bulgarian & Mathematics)",
                "1 x Bulgarian (100 Points) + 3 x Mathematics (300 Points)",
                "3 x Bulgarian (300 Points) + 1 x Mathematics (100 Points)",
                "2 x Bulgarian (200 Points) + 2 x Mathematics (200 Points)",
                "1 x Bulgarian (100 Points) + 1 x Mathematics (100 Points) + 2 x Ability Assessment (200 Points)	",
            ],
            secondCol: [
                "Max Points from both examinations",
                "400 Points",
                "400 Points",
                "400 Points",
                "400 Points",
            ],
            thirdCol: [
                "Max Points from the school year marks",
                "I. Subject (50 Punkte) + II. Subject (50 Punkte) = 100 Punkte",
            ],
            fourthCol: [
                "Max. Points",
                "500 Points",
                "500 Points",
                "500 Points",
                "500 Points",
            ],
        },
        third: {
            firstCol: [
                "Bildungsniveau",
                "Professional Bachelor",
                "Bachelor",
                "Master",
                "Doctor",
            ],
            secondCol: [
                "Ausbildungsdauer",
                "3 Years",
                "4 Years",
                "5 Years (after school education: Law, Political science etc.), 2 Years after professional Bachelor, 1 Year after Bachelor",
                "3 Years after Master",
            ],
            thirdCol: [
                "School type",
                "Hochschule/Fachhochschule",
                "University, College",
                "University",
                'University, College, BAS (Bulgarian Academy of Sciences - "Българска академия на науките" - БАН)',
            ],
        },
    },
    bulgarian: {
        first: {
            firstCol: [
                "Оценки от свидетелството за основно образование след VII клас",
                "Отличен (6)",
                "Много добър (5)",
                "Добър (4)",
                "Среден (3)",
                "Слаб (2)",
            ],
            secondCol: ["Брой точки", "50", "39", "26", "15", "0"],
        },
        second: {
            firstCol: [
                "Резултати от националното външно оценяване",
                "1 х Български език и литература (100т.) + 3 х Математика (300т.)",
                "3 х Български език и литература (300т.) + 1 х Математика (100т.)",
                "2 х Български език и литература (200т.) + 2 х Математика (200т.)",
                "1 х Български език и литература (100т.) + 1 х Математика (100т.) + 2 х Проверка на способности (200т.)",
            ],
            secondCol: [
                "Макс. сбор точки от НВО или НВО + проверка на способности",
                "400 т.",
                "400 т.",
                "400 т.",
                "400 т",
            ],
            thirdCol: [
                "Макс. сбор точки от свидетелството съгласно скалата",
                "I-ви предмет(50т.) + II-ри предмет (50т.) = 100 т.",
            ],
            fourthCol: [
                "Максимален бал",
                "500 т.",
                "500 т.",
                "500 т.",
                "500 т.",
            ],
        },
        third: {
            firstCol: [
                "Образователна степен",
                "Професионален бакалавър",
                "Бакалавър",
                "Магистър",
                "Доктор",
            ],
            secondCol: [
                "Срок на обучение минимум",
                "3 години",
                "4 години",
                "5 години след средно образование (право, международни отношения), 2 години след професионален бакалавър, 1 година след бакалавър ",
                "3 години след магистър",
            ],
            thirdCol: [
                "Училища",
                "Колеж",
                "Университет, колеж, СВУ",
                "Университет, СВУ",
                "Университет, научна организация (БАН), СВУ",
            ],
        },
    },
    german: {
        first: {
            firstCol: [
                "Die Schuljahresnoten im Hauptausbildungszeugnis nach der 7.Klasse",
                "Ausgezeichnet (6)	",
                "Sehr gut (5)",
                "Gut (4)",
                "Mangelhaft (3)",
                "Ungenügend (2)",
            ],
            secondCol: ["Punktzahl", "50", "39", "26", "15", "0"],
        },
        second: {
            firstCol: [
                "Ergebnisse aus beiden Prüfungen (Bulgarisch & Mathematik)",
                "1 x Bulgarisch (100 Punkte) + 3 x Mathematik (300 Punkte)",
                "3 x Bulgarisch (300 Punkte) + 1 x Mathematik (100 Punkte)",
                "2 x Bulgarisch (200 Punkte) + 2 x Mathematik (200 Punkte)",
                "1 x Bulgarisch (100 Punkte) + 1 x Mathematik (100 Punkte) + 2 x Fähigkeitsprüfung (200 Punkte)	",
            ],
            secondCol: [
                "Max. Punktzahl aus beiden Prüfungen",
                "400 Punkte",
                "400 Punkte",
                "400 Punkte",
                "400 Punkte",
            ],
            thirdCol: [
                "Max. Punktzahl aus den Schuljahrnoten",
                "I. Fach (50 Punkte) + II. Fach (50 Punkte) = 100 Punkte",
            ],
            fourthCol: [
                "Max. Punktzahl",
                "500 Punkte",
                "500 Punkte",
                "500 Punkte",
                "500 Punkte",
            ],
        },
        third: {
            firstCol: [
                "Bildungsniveau",
                "Professioneller Bachelor",
                "Bachelor",
                "Master",
                "Doktor",
            ],
            secondCol: [
                "Ausbildungsdauer",
                "3 Jahre",
                "4 Jahre",
                "5 Jahre (nach der schulischen Ausbildung: bei Jura, Politikwissenschaften usw.), 2 Jahre nach dem professionellen Bachelor, 1 Jahr nach dem Bachelor",
                "3 Jahre nach dem Master",
            ],
            thirdCol: [
                "Schultyp",
                "Hochschule/Fachhochschule",
                "(spez.) Universität, Hochschule",
                "(spez.) Universität",
                '(spez.) Universität, Hochschule, BAW (Bulgarische Akademie der Wissenschaften - "Българска академия на науките" - БАН)',
            ],
        },
    },
};
export default systemTablesContent;

import alex from "../images/school_photos_compressed/alex.JPG";
import alexandra from "../images/school_photos_compressed/alexandra.JPG";
import eli2 from "../images/school_photos_compressed/eli2.JPG";
import eliza from "../images/school_photos_compressed/eliza.JPG";
import ivana from "../images/school_photos_compressed/ivana.JPG";
import yana from "../images/school_photos_compressed/yana.JPG";
import yoana from "../images/school_photos_compressed/yoana.JPG";
import venelin from "../images/school_photos_compressed/venelin.JPG";
import dina from "../images/school_photos_compressed/dina.JPG";
import deni from "../images/school_photos_compressed/deni.JPG";
import lubo from "../images/school_photos_compressed/lubo.JPG";
import eli from "../images/school_photos_compressed/eli.JPG";
import yossif from "../images/school_photos_compressed/yossif.JPG";
import stefcho from "../images/school_photos_compressed/stefcho.JPG";
import sasho from "../images/school_photos_compressed/sasho.JPG";
import dari from "../images/school_photos_compressed/dari.JPG";
import mila from "../images/school_photos_compressed/mila.JPG";
import neda from "../images/school_photos_compressed/neda.JPG";
import mihaela from "../images/school_photos_compressed/mihaela.JPG";
import irina from "../images/school_photos_compressed/irina.JPG";
import boris from "../images/school_photos_compressed/boris.JPG";
import geo from "../images/school_photos_compressed/geo.JPG";
import dimitar from "../images/school_photos_compressed/dimitar.JPG";
import duygu from "../images/school_photos_compressed/duygu.JPG";
import petko from "../images/school_photos_compressed/petko.JPG";
import radina from "../images/school_photos_compressed/radina.jpg";
import inna from "../images/school_photos_compressed/inna.JPG";
import georgi from "../images/school_photos_compressed/GeorgiG.JPG";
import stefan from "../images/school_photos_compressed/stefan.JPG";
import viktoria from "../images/school_photos_compressed/viktoria.jpeg";
import atanas from "../images/school_photos_compressed/atanas.jpg";
import nedaB from "../images/school_photos_compressed/NedaB.jpg";
import viktoriaS from "../images/school_photos_compressed/ViktoriaS.jpg";
import kristiana from "../images/school_photos_compressed/Kristiana.jpg";

const secondaryCards = {
    bulgarian: [
        {
            title: "Александър С.",
            imgUrl: `${alex}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Александра",
            imgUrl: `${alexandra}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Елица И.",
            imgUrl: `${eli2}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Елиза",
            imgUrl: `${eliza}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Ивана",
            imgUrl: `${ivana}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Яна",
            imgUrl: `${yana}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Йоана",
            imgUrl: `${yoana}`,
            rolle: "11. Клас, интервюта и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Венелин",
            imgUrl: `${venelin}`,
            rolle: "12. Клас, архив и видеообработка",

            // text: "Zitat",
        },
        {
            title: "Дина",
            imgUrl: `${dina}`,
            rolle: "12. Клас, интервюта и видеообработка",
            // text: "Zitat",
        },
        {
            title: "Деница",
            imgUrl: `${deni}`,
            rolle: "11. Клас, интервюта и уебсайт",
            // text: "Zitat",
        },
        {
            title: "Йосиф",
            imgUrl: `${yossif}`,
            rolle: "11. Клас, интервюта и видеообработка",
            // text: "Zitat",
        },
        {
            title: "Стефан",
            imgUrl: `${stefcho}`,
            rolle: "11. Клас, интервюта и видеообработка",
            // text: "Zitat",
        },
        {
            title: "Дарина",
            imgUrl: `${dari}`,
            rolle: "11. Клас, интервюта и субтитри",
            // text: "Zitat",
        },
        {
            title: "Елица Д.",
            imgUrl: `${eli}`,
            rolle: "11. Клас, интервюта и уебсайт",
            // text: "Zitat",
        },
        {
            title: "Любомир",
            imgUrl: `${lubo}`,
            rolle: "11. Клас, уебсайт",
            // text: "Zitat",
        },
        {
            title: "Михаела",
            imgUrl: `${mihaela}`,
            rolle: "12. Клас, интервюта, архив и видеообработка",
            text:
                "Намирам развитието на нашата гимназия по време и след социализма за особено интересно, поради което се записах за проекта. Благодарение на приятната работна атмосфера не само научих много, но и създадох нови приятелства! Благодаря за прекрасното преживяване!",
        },
        {
            title: "Ирина",
            imgUrl: `${irina}`,
            rolle: "12. Клас, интервюта и архив",
            text:
                "Много обичам училището си и неговата история и от над 5 години имам интерес към Студената война, така че не е изненада, че се включих в проекта.",
        },
        {
            title: "Александър Г.",
            imgUrl: `${sasho}`,
            rolle: "12. Клас, интервюта и съавтор",
            // text: "Zitat",
        },
        {
            title: "Неда",
            imgUrl: `${neda}`,
            rolle: "12. Клас, интервюта",
            // text: "Zitat",
        },
        {
            title: "Мила",
            imgUrl: `${mila}`,
            rolle: "12. Клас, интервюта, архив и видеообработка",
            text:
                "Реших да участвам в проектa, защото, от една страна, намирам историята като цяло за много вълнуваща, а от друга страна, исках да се запозная с историята на моето училище от самото начало. За мен беше много интересно да интервюирам свидетели, а също и да обработвам видеоклипове, въпреки че второто изискваше много мотивация и търпение. Бих искала също така да покажа на по-малките випуски колко необикновено е миналото на нашето училище.",
        },
        {
            title: "Борис",
            imgUrl: `${boris}`,
            rolle: "12. Клас, интервюта и видеообработка",
            // text: "Zitat",
        },
        {
            title: "Георги-Феликс",
            imgUrl: `${geo}`,
            rolle: "12. Клас, интервюта и видеообработка",
            // text: "Zitat",
        },
        {
            title: "Димитър",
            imgUrl: `${dimitar}`,
            rolle: "12. Клас, интервюта",
            // text: "Zitat",
        },
        {
            title: "Дуигу",
            imgUrl: `${duygu}`,
            rolle: "12. Клас, интервюта",
            // text: "Zitat",
        },
        {
            title: "Петко",
            imgUrl: `${petko}`,
            rolle: "12. Клас, интервюта",
            // text: "Zitat",
        },
        {
            title: "Радина",
            imgUrl: `${radina}`,
            rolle: "12. Клас, архив",
            // text: "Zitat",
        },
        {
            title: "Инна",
            imgUrl: `${inna}`,
            rolle: "12. Клас, интервюта",
            // text: "Zitat",
        },
        {
            title: "Стефан",
            imgUrl: `${stefan}`,
            rolle: "12. Клас, интервюта",
            // text: "Zitat",
        },
        {
            title: "Георги",
            imgUrl: `${georgi}`,
            rolle: "12. Клас, интервюта, видеообработка и съавтор",
            // text: "Zitat",
        },
        {
            title: "Виктория",
            imgUrl: `${viktoria}`,
            rolle: "12. Клас, архив",
            text:
                "Имах възможността да науча много нови неща, както и да създам нови приятелства. Много съм благодарна за приятната атмосфера и страхотната работа в екип!",
        },
        {
            title: "Атанас",
            imgUrl: `${atanas}`,
            rolle: "12. Клас, интервюта",
            text:
                "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Неда Б.",
            imgUrl: `${nedaB}`,
            rolle: "12. Клас, интервюта",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Виктория С.",
            imgUrl: `${viktoriaS}`,
            rolle: "12. Клас, интервюта",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Кристияна",
            imgUrl: `${kristiana}`,
            rolle: "12. Клас, интервюта",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
    ],
    english: [
        {
            title: "Alexander S.",
            imgUrl: `${alex}`,
            rolle: "11. Grade, interviews and video editing",

            // text: "Zitat",
        },
        {
            title: "Alexandra",
            imgUrl: `${alexandra}`,
            rolle: "11. Grade, interviews and video editing",

            // text: "Zitat",
        },
        {
            title: "Elitsa I.",
            imgUrl: `${eli2}`,
            rolle: "11. Grade, interviews and video editing",
            // text: "Zitat",
        },
        {
            title: "Eliza",
            imgUrl: `${eliza}`,
            rolle: "11. Grade, interviews and video editing",

            // text: "Zitat",
        },
        {
            title: "Ivana",
            imgUrl: `${ivana}`,
            rolle: "11. Grade, interviews and video editing",

            // text: "Zitat",
        },
        {
            title: "Yana",
            imgUrl: `${yana}`,
            rolle: "11. Grade, interviews and video editing",

            // text: "Zitat",
        },
        {
            title: "Yoana",
            imgUrl: `${yoana}`,
            rolle: "11. Grade, interviews and video editing",

            // text: "Zitat",
        },
        {
            title: "Venelin",
            imgUrl: `${venelin}`,
            rolle: "12. Grade, archive and video editing",

            // text: "Zitat",
        },
        {
            title: "Dina",
            imgUrl: `${dina}`,
            rolle: "12. Grade, interviews and video editing",
            // text: "Zitat",
        },
        {
            title: "Denitsa",
            imgUrl: `${deni}`,
            rolle: "11. Grade, interviews and website",
            // text: "Zitat",
        },
        {
            title: "Yossif",
            imgUrl: `${yossif}`,
            rolle: "11. Grade, interviews and video editing",
            // text: "Zitat",
        },
        {
            title: "Stefan",
            imgUrl: `${stefcho}`,
            rolle: "11. Grade, interviews and video editing",
            // text: "Zitat",
        },
        {
            title: "Darina",
            imgUrl: `${dari}`,
            rolle: "11. Grade, interviews and subtitles",
            // text: "Zitat",
        },
        {
            title: "Elitsa D.",
            imgUrl: `${eli}`,
            rolle: "11. Grade, interviews and website",
            // text: "Zitat",
        },
        {
            title: "Lyubomir",
            imgUrl: `${lubo}`,
            rolle: "11. Grade, website",
            // text: "Zitat",
        },
        {
            title: "Mihaela",
            imgUrl: `${mihaela}`,
            rolle: "12. Grade, interviews, archive and video editing",
            text:
                "I find the development of our high school during and after socialism particularly interesting, which is why I decided to participate in the project. Thanks to the nice work environment I not only learned a lot but also made new friends! Thank you for the wonderful experience!",
        },
        {
            title: "Irina",
            imgUrl: `${irina}`,
            rolle: "12. Grade, interviews and archive",
            text:
                "I am very fond of my school and its history and I showed an interest in the Cold War more than 5 years ago, so it is no wonder that I took part in the project.",
        },
        {
            title: "Alexander",
            imgUrl: `${sasho}`,
            rolle: "12. Grade, interviews and co-author",
            // text: "Zitat",
        },
        {
            title: "Neda",
            imgUrl: `${neda}`,
            rolle: "12. Grade, interviews",
            // text: "Zitat",
        },
        {
            title: "Mila",
            imgUrl: `${mila}`,
            rolle: "12. Grade, interviews and video editing",
            text:
                "I decided to take part in the history project because, on the one hand, I find history in general very exciting and, on the other hand, I wanted to investigate the history of my school since its beginnings. It was very interesting for me to interview contemporary witnesses and also to edit videos, although the second activity required a great amount of motivation and patience. In addition, I would like to show the younger students how extraordinary our school's past is.",
        },
        {
            title: "Boris",
            imgUrl: `${boris}`,
            rolle: "12. Grade, interviews and video editing",
            // text: "Zitat",
        },
        {
            title: "George-Felix",
            imgUrl: `${geo}`,
            rolle: "12. Grade, interviews and video editing",
            // text: "Zitat",
        },
        {
            title: "Dimitar",
            imgUrl: `${dimitar}`,
            rolle: "12. Grade, interviews",
            // text: "Zitat",
        },
        {
            title: "Duygu",
            imgUrl: `${duygu}`,
            rolle: "12. Grade, interviews",
            // text: "Zitat",
        },
        {
            title: "Petko",
            imgUrl: `${petko}`,
            rolle: "12. Grade, interviews",
            // text: "Zitat",
        },
        {
            title: "Radina",
            imgUrl: `${radina}`,
            rolle: "12. Grade, archive",
            // text: "Zitat",
        },
        {
            title: "Inna",
            imgUrl: `${inna}`,
            rolle: "12. Grade, interviews",
            // text: "Zitat",
        },
        {
            title: "Stefan",
            imgUrl: `${stefan}`,
            rolle: "12. Grade, interviews",
            // text: "Zitat",
        },
        {
            title: "Georgi",
            imgUrl: `${georgi}`,
            rolle: "12. Grade, archive, video editing and co-author",
            // text: "Zitat",
        },
        {
            title: "Victoria",
            imgUrl: `${viktoria}`,
            rolle: "12. Grade, archive",
            text:
                "The most difficult times are the most interesting for those who have not experienced them. In this respect, the school in socialism appears challenging for a modern student.",
        },
        {
            title: "Atanas",
            imgUrl: `${atanas}`,
            rolle: "12. Grade, interviews",
            text:
                "I took part in this project to get a better look at the past of our school and to get a new point of view on the role of socialism. Because in my opinion, the past is just as important as the present.",
        },
        {
            title: "Neda B.",
            imgUrl: `${nedaB}`,
            rolle: "12. Grade, interviews",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Victoria S.",
            imgUrl: `${viktoriaS}`,
            rolle: "12. Grade, interviews",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Christiana N.",
            imgUrl: `${kristiana}`,
            rolle: "12. Grade, interviews",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
    ],
    german: [
        {
            title: "Alexander S.",
            imgUrl: `${alex}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Alexandra",
            imgUrl: `${alexandra}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Elitsa I.",
            imgUrl: `${eli2}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Elisa",
            imgUrl: `${eliza}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Ivana",
            imgUrl: `${ivana}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Yana",
            imgUrl: `${yana}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Yoana",
            imgUrl: `${yoana}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Venelin",
            imgUrl: `${venelin}`,
            rolle: "12. Klasse, Archivteam und Videoschnitt",

            // text: "Zitat",
        },
        {
            title: "Dina",
            imgUrl: `${dina}`,
            rolle: "12. Klasse, Interviewteam und Videoschnitt",
            // text: "Zitat",
        },
        {
            title: "Denitsa",
            imgUrl: `${deni}`,
            rolle: "11. Klasse, Interviewteam und Homepagegestalltung",

            // text: "Zitat",
        },
        {
            title: "Yossif",
            imgUrl: `${yossif}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",
            // text: "Zitat",
        },
        {
            title: "Stefan",
            imgUrl: `${stefcho}`,
            rolle: "11. Klasse, Interviewteam und Videoschnitt",
            text:
                "Anderthalb Wochen lang hab' ich mich mit der Ausrüstung beschäftigt!!",
        },
        {
            title: "Darina",
            imgUrl: `${dari}`,
            rolle: "11. Klasse, Interviewteam und Untertitelbearbeitung",
            // text: "Zitat",
        },
        {
            title: "Elitsa D.",
            imgUrl: `${eli}`,
            rolle: "11. Klasse, Interviewteam und Homepage",
            // text: "Zitat",
        },
        {
            title: "Lyubomir",
            imgUrl: `${lubo}`,
            rolle: "11. Klasse, Homepage und Technik",
            // text: "Zitat",
        },
        {
            title: "Mihaela",
            imgUrl: `${mihaela}`,
            rolle: "12. Klasse, Interview-, Archivteam und Videoschnitt",
            text:
                "Die Entwicklung unseres Gymnasiums während und nach dem Sozialismus finde ich besonders interessant, deshalb habe ich mich für das Projekt entschieden. Dank der schöne Arbeitsatmosphäre habe ich nicht nur viel gelernt, sondern auch neue Freundschaften geschlossen! Danke für die wundervolle Erfahrung!",
        },
        {
            title: "Irina",
            imgUrl: `${irina}`,
            rolle: "12. Klasse, Interview- und Archivteam",
            text:
                "Meine Schule und ihre Geschichte habe ich sehr lieb und ein Interesse an dem Kalten Krieg zeigte ich schon vor über 5 Jahren, deshalb ist es kein Wunder, dass ich am Projekt teilnahm.",
        },
        {
            title: "Alexander G.",
            imgUrl: `${sasho}`,
            rolle: "12. Klasse, Interviewteam und Co-Autor",
            // text: "Zitat",
        },
        {
            title: "Neda",
            imgUrl: `${neda}`,
            rolle: "12. Klasse, Interviewteam",
            // text: "Zitat",
        },
        {
            title: "Mila",
            imgUrl: `${mila}`,
            rolle: "12. Klasse, Interviewteam und Videoschnitt",
            text:
                "Ich habe mich entschieden, an dem Geschichtsprojekt teilzunehmen, weil ich zum einen Geschichte allgemein sehr spannend finde und zum anderen die Geschichte meiner Schule seit ihren Anfängen untersuchen wollte. Es war sehr interessant für mich, Zeitzeugen zu befragen und auch Videos zu bearbeiten, obwohl die zweite Aktivität außerordentlich viel Motivation und Geduld erfordert hat. Darüber hinaus möchte ich auch den jüngeren Jahrgängen meiner Schule zeigen, wie außergewöhnlich die Vergangenheit unserer Schule ist.",
        },
        {
            title: "Boris",
            imgUrl: `${boris}`,
            rolle: "12. Klasse, Interviewteam und Videoschnitt",
            // text: "Zitat",
        },
        {
            title: "Georgy-Felix",
            imgUrl: `${geo}`,
            rolle: "12. Klasse, Interviewteam und Videoschnitt",
            text: "",
        },
        {
            title: "Dimitar",
            imgUrl: `${dimitar}`,
            rolle: "12. Klasse, Interviewteam",

            // text: "Zitat",
        },
        {
            title: "Duygu",
            imgUrl: `${duygu}`,
            rolle: "12. Klasse, Interviewteam",
            // text: "Zitat",
        },
        {
            title: "Petko",
            imgUrl: `${petko}`,
            rolle: "12. Klasse, Interviewteam",
            // text: "Zitat",
        },
        {
            title: "Radina",
            imgUrl: `${radina}`,
            rolle: "12. Klasse, Archivteam",
            // text:
            //     "Ich interessiere mich für Geschichte und deshalb bin ich in der Klasse mit intensivem Studium dieses Faches. Die Idee des Projekts hat mich sehr fasziniert und deswegen habe ich mich für eine Teilnahme entschieden. Ich habe Fähigkeiten erworben, die mir in Zukunft sicher von Nuten sein werden.",
        },
        {
            title: "Inna",
            imgUrl: `${inna}`,
            rolle: "12. Klasse, Interviewteam",
            // text: "Zitat",
        },
        {
            title: "Stefan",
            imgUrl: `${stefan}`,
            rolle: "12. Klasse, Interviewteam",
            // text: "Zitat",
        },
        {
            title: "Georgi",
            imgUrl: `${georgi}`,
            rolle: "12. Klasse, Archiv- und Videoteam, Co-Autor",
            text:
                "Die schwierigsten Zeiten sind am interessantesten für diejenigen, die sie nicht erlebt haben. Insofern erscheint die Schule im Sozialismus herausfordernd für einen modernen Schüler.",
        },
        {
            title: "Victoria",
            imgUrl: `${viktoria}`,
            rolle: "12. Klasse, Archivteam",
            text:
                "Ich hatte die Möglichkeit sowohl viel Neues zu lernen als auch neue Freundschaften zu schließen. Ich bin sehr dankbar für die tolle Atmosphäre und die schöne Teamarbeit!",
        },
        {
            title: "Atanas",
            imgUrl: `${atanas}`,
            rolle: "12. Klasse, Interviewteam",
            text:
                "Ich habe an diesen Projekt teilgenommen, um ein besseres Blick in die Vergangenheit von unserer Schule werfen zu können und um einen neuen Standpunkt über die Rolle der Sozialismus zu bekommen. Denn meiner Meinung nach ist die Vergangenheit genau so wichtig, wie die Gegenwart.",
        },
        {
            title: "Neda B.",
            imgUrl: `${nedaB}`,
            rolle: "12. Klasse, Interviewteam",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Victoria S.",
            imgUrl: `${viktoriaS}`,
            rolle: "12. Klasse, Interviewteam",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
        {
            title: "Christiana",
            imgUrl: `${kristiana}`,
            rolle: "12. Klasse, Interviewteam",
            // text:
            //     "Участвах в този проект, за да разгледам по-добре миналото на нашето училище и да придобия нова гледна точка за ролята на социализма. Защото според мен миналото е също толкова важно, колкото и настоящето.",
        },
    ],
};

export default secondaryCards;

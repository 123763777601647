import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import background_top_left_mobile from "../images/background_top_left_mobile.png";
import background_bottom from "../images/background_bottom.png";
import sizes from "../styles/sizes";
import background_top_left_clean from "../images/background_top_left_clean.png";
import Grid from "@material-ui/core/Grid";
import IndexListe from "./IndexListe";
import { useHistory, Link } from "react-router-dom";

import schulSystemContent from "../content/schulSystemContent";
import systemTablesContent from "../content/systemTablesContent";
import { LanguageContext } from "../contexts/LanguageContext";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import img_2a from "../images/history_photos/2a.JPG";
import img_2b from "../images/history_photos/2b.JPG";
import img_1 from "../images/history_photos/Bild_1.jpg";
import img_3 from "../images/history_photos/Bild_3.jpg";
import img_4 from "../images/history_photos/Bild_4.jpg";
import img_5 from "../images/history_photos/Bild_5.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#f5f1e9",
        // backgroundColor: "rgba(227, 225, 222, 0.7)",
        // backgroundColor: "rgba(181, 255, 182,0.3)",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "100%, 30%",
        [sizes.down("xl")]: {
            backgroundSize: "100%, 37.5%",
        },
        fontFamily: "'Commissioner', serif",

        backgroundPosition: "top, bottom right",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "100%, 75%",
            padding: "0",
            margin: "0",
        },
        paddingRight: "0",
        // border: "2px solid blue",
        // height: "100vh",
        // height: "100vh",

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        backgroundColor: "#f5f1e9",
        // backgroundColor: "rgba(227, 225, 222, 0.7)",
        // backgroundColor: "rgba(181, 255, 182,0.3)",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "100%, 30%",
        [sizes.down("xl")]: {
            backgroundSize: "100%, 37.5%",
        },
        backgroundPosition: "top, bottom right",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "100%, 75%",
            padding: "0",
            margin: "0",
        },
        paddingRight: "0",
        // border: "2px solid blue",
    },
    Grid: {
        // marginTop: "64px",
        padding: "1rem 1rem",
        paddingTop: "calc(64px + 1rem)",
        width: "100%",
        // border: "2px solid red",
        marginRight: "0",
        margin: "0",
        fontFamily: "'Commissioner', serif",

        // alignItems: "stretch",
    },
    table: {
        border: "2px solid #d3d3d3",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
    },
    tableHeader: {
        fontFamily: "'Commissioner', serif",
        fontSize: "1.25rem",
    },
    tableRow: {
        fontFamily: "'Commissioner', serif",
        fontSize: "1rem",
    },
    title: {
        maxWidth: "100%",
        borderRadius: "0",
        backgroundColor: "rgba(255,255,255,0.9)",
        fontSize: "3.5rem",
        padding: "1rem 0",
        boxShadow: "none",
        fontFamily: "'Commissioner', serif",

        // boxSizing: "border-box",
        // border: "2px solid black",
        // display: "flex",
        // justifyContent: "space-around",
        // // border: "1px solid black",
        // height: "auto",
        // [sizes.down("sm")]: {
        //     display: "flex",
        //     flexFlow: "column",
        //     alignItems: "center",
        //     justifyContent: "center",
        // },
        //has pasdding
        // padding: "0.5rem",
    },
    textBox: {
        padding: "1.5rem 3rem",
        fontFamily: "'Commissioner', serif",
        backgroundColor: "rgba(255,255,255,0.9)",
        fontSize: "1.25rem",
    },
    imageText: {
        fontSize: "1.5rem",
        padding: "0 3rem",
        fontFamily: "'Commissioner', serif",
    },
    Link: {
        fontFamily: "'Commissioner', serif",
        // color: 'black',
        color: "rgb(50,50,50)",
        // fontSize: "1rem",
        cursor: "pointer",
        textDecoration: "none",
        // fontFamily: "'Bebas Neue', cursive",
        borderBottom: "1px solid transparent",
        // "&:hover": {
        //     borderBottom: "1px solid rgba(0,0,0,0.75)",
        // },
        [sizes.down("sm")]: {
            // display: "block",
            // width: "100%",
            marginTop: "1rem",
        },
        // "&:after": {
        //     content:
        //         '""' /* This is necessary for the pseudo element to work. */,
        //     display:
        //         "inline" /* This will put the pseudo element on its own line. */,
        //     width:
        //         "0px" /* Change this to whatever width you want to have before hover. */,
        //     paddingTop:
        //         "1px" /* This creates some space between the element and the border. */,
        //     borderBottom:
        //         // CHANGE TO TRANSPARENT IF YOU NEED THE DELAY TO BE LONGER
        //         "1px solid transparent" /* This creates the border. Replace black with whatever color you want. */,
        //     transition:
        //         "0.2s  " /* This establishes the amount of time in seconds the animation should take from start to finish */,
        //     margin: "0 auto",
        // },
        "&": {
            width: "100%",
            // borderColor: "black",
            display: "inline",
            borderBottom: "1px solid black",
            margin: "0 auto",
        },
        "&:hover": {
            width: "100%",
            // borderColor: "black",
            transition: "0.2s",
            display: "inline",
            borderBottom: "1px solid rgb(123,123,123)",
            margin: "0 auto",
        },
    },
}));

function createData(grade, points) {
    return { grade, points };
}

function createDataSecondTable(ergebnisse, maxTest, maxYear, max) {
    return { ergebnisse, maxTest, maxYear, max };
}

function createDataThirdTable(bildung, dauer, schultyp) {
    return { bildung, dauer, schultyp };
}

export default function SchulSystemPage() {
    const classes = useStyles();
    const { language } = useContext(LanguageContext);

    const rows = [
        createData(
            systemTablesContent[language].first.firstCol[1],
            systemTablesContent[language].first.secondCol[1]
        ),
        createData(
            systemTablesContent[language].first.firstCol[2],
            systemTablesContent[language].first.secondCol[2]
        ),
        createData(
            systemTablesContent[language].first.firstCol[3],
            systemTablesContent[language].first.secondCol[3]
        ),
        createData(
            systemTablesContent[language].first.firstCol[4],
            systemTablesContent[language].first.secondCol[4]
        ),
        createData(
            systemTablesContent[language].first.firstCol[5],
            systemTablesContent[language].first.secondCol[5]
        ),
    ];

    const secondTableRows = [
        createDataSecondTable(
            systemTablesContent[language].second.firstCol[1],
            systemTablesContent[language].second.secondCol[1],
            systemTablesContent[language].second.thirdCol[1],
            systemTablesContent[language].second.fourthCol[1]
        ),
        createDataSecondTable(
            systemTablesContent[language].second.firstCol[2],
            systemTablesContent[language].second.secondCol[1],
            "",
            systemTablesContent[language].second.fourthCol[1]
        ),
        createDataSecondTable(
            systemTablesContent[language].second.firstCol[3],
            systemTablesContent[language].second.secondCol[1],
            "",
            systemTablesContent[language].second.fourthCol[1]
        ),
        createDataSecondTable(
            systemTablesContent[language].second.firstCol[4],
            systemTablesContent[language].second.secondCol[1],
            "",
            systemTablesContent[language].second.fourthCol[1]
        ),
    ];

    const thirdTableRows = [
        createDataThirdTable(
            systemTablesContent[language].third.firstCol[1],
            systemTablesContent[language].third.secondCol[1],
            systemTablesContent[language].third.thirdCol[1]
        ),
        createDataThirdTable(
            systemTablesContent[language].third.firstCol[2],
            systemTablesContent[language].third.secondCol[2],
            systemTablesContent[language].third.thirdCol[2]
        ),
        createDataThirdTable(
            systemTablesContent[language].third.firstCol[3],
            systemTablesContent[language].third.secondCol[3],
            systemTablesContent[language].third.thirdCol[3]
        ),
        createDataThirdTable(
            systemTablesContent[language].third.firstCol[4],
            systemTablesContent[language].third.secondCol[4],
            systemTablesContent[language].third.thirdCol[4]
        ),
    ];

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            <Grid
                className={classes.Grid}
                style={{
                    /*paddingTop: "calc(64px + 1rem)",*/ margin: "0 0 0 0",
                }}
                container
                // alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <div className={classes.title}>
                        {
                            schulSystemContent[language][
                                schulSystemContent[language].length - 1
                            ]
                        }
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][0]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][1]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][2]}

                        <p
                            style={{
                                columnCount: 1,
                                textAlign: "left",
                            }}
                        >
                            <ul>
                                <li>{schulSystemContent[language][3]}</li>
                                <li>{schulSystemContent[language][4]}</li>
                                <li>{schulSystemContent[language][5]}</li>
                                <li>{schulSystemContent[language][6]}</li>
                            </ul>
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][7]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][8]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][9]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][10]}
                        {/* REACT TABLE */}
                        <TableContainer /*component={Paper}*/>
                            <Table
                                className={classes.table}
                                style={{
                                    minWidth: 550,
                                    maxWidth: 650,
                                }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .first.firstCol[0]
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .first.secondCol[0]
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.grade}>
                                            <TableCell
                                                className={classes.tableRow}
                                                component="th"
                                                scope="row"
                                                align="center"
                                            >
                                                {row.grade}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableRow}
                                                align="center"
                                            >
                                                {row.points}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* REACT TABLE */}
                        {schulSystemContent[language][11]}
                    </div>
                </Grid>
                {/*  table  */}
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][12]}

                        <TableContainer /*component={Paper}*/>
                            <Table
                                className={classes.table}
                                style={{
                                    minWidth: 850,
                                    maxWidth: 900,
                                }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .second.firstCol[0]
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .second.secondCol[0]
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .second.thirdCol[0]
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .second.fourthCol[0]
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableRow}
                                            component="th"
                                            align="center"
                                            scope="row"
                                        >
                                            {secondTableRows[0].ergebnisse}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[0].maxTest}
                                        </TableCell>
                                        <TableCell
                                            rowSpan={4}
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[0].maxYear}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[0].max}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableRow}
                                            component="th"
                                            align="center"
                                            scope="row"
                                        >
                                            {secondTableRows[1].ergebnisse}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[1].maxTest}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[1].max}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableRow}
                                            component="th"
                                            align="center"
                                            scope="row"
                                        >
                                            {secondTableRows[2].ergebnisse}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[2].maxTest}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[2].max}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableRow}
                                            component="th"
                                            align="center"
                                            scope="row"
                                        >
                                            {secondTableRows[3].ergebnisse}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[3].maxTest}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableRow}
                                            align="center"
                                        >
                                            {secondTableRows[3].max}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][13]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][14]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][15]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][16]}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {schulSystemContent[language][17]}

                        <TableContainer /*component={Paper}*/>
                            <Table
                                className={classes.table}
                                style={{
                                    minWidth: 900,
                                    maxWidth: 1000,
                                }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .third.firstCol[0]
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .third.secondCol[0]
                                            }
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableHeader}
                                            align="center"
                                        >
                                            {
                                                systemTablesContent[language]
                                                    .third.thirdCol[0]
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {thirdTableRows.map((row) => (
                                        <TableRow key={row.ergebnisse}>
                                            <TableCell
                                                className={classes.tableRow}
                                                component="th"
                                                align="center"
                                                scope="row"
                                            >
                                                {row.bildung}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableRow}
                                                align="center"
                                            >
                                                {row.dauer}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableRow}
                                                align="center"
                                            >
                                                {row.schultyp}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        <p>
                            <a
                                className={classes.Link}
                                style={{
                                    display: "inline",
                                    textDecoration: "none",
                                    // borderBottom: "1px solid rgba(0,0,0,0.3)",
                                }}
                                target="_blank"
                                href="https://pzdnes.com/2018/05/21/%D0%B2%D0%B8%D0%B6%D1%82%D0%B5-%D0%BA%D0%B0%D0%BA-%D1%81%D0%B5-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D1%83%D0%B2%D0%B0-%D0%B1%D0%B0%D0%BB%D0%B0-%D0%B7%D0%B0-%D0%BF%D1%80%D0%B8%D0%B5%D0%BC-%D1%81%D0%BB%D0%B5/"
                            >
                                {schulSystemContent[language][18]}
                            </a>{" "}
                            {schulSystemContent[language][19]}
                        </p>
                        <p>
                            <a
                                className={classes.Link}
                                style={{
                                    textDecoration: "none",
                                    display: "inline",

                                    // borderBottom: "1px solid black",
                                }}
                                target="_blank"
                                href="https://bg.wikipedia.org/wiki/%D0%9E%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B2_%D0%91%D1%8A%D0%BB%D0%B3%D0%B0%D1%80%D0%B8%D1%8F"
                            >
                                {schulSystemContent[language][20]}
                            </a>{" "}
                            {schulSystemContent[language][21]}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <IndexListe></IndexListe>
                </Grid>
            </Grid>
        </div>
    );
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import sizes from "../styles/sizes";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "none",
        [sizes.down("fift")]: {
            display: "flex",
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            height: 48,
        },
    },
    tabs: {
        // borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function HorizontalVideoTabs({
    videos,
    setVideo,
    setTitle,
    setDescription,
    setTabTitle,
}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                {videos.map((v) => (
                    <Tab
                        style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "left",
                            fontSize: "0.9rem",
                            width: "auto",
                            maxWidth: "100%",
                            textAlign: "left",

                            // border: "1px solid red",
                        }}
                        label={v.tabTitle}
                        onClick={() => {
                            console.log("setting video to::::", v.url);
                            setVideo(v.url);
                            setTitle(v.title);
                            setDescription(v.description);
                            setTabTitle(v.tabTitle);
                            console.log(v);
                            // setVideoObj(v);
                        }}
                        {...a11yProps(v.index)}
                    ></Tab>
                ))}
            </Tabs>
        </div>
    );
}

import archiv_1 from "../images/gallery_photos/archiv/IMG_20191204_124402.jpg";
import archiv_2 from "../images/gallery_photos/archiv/IMG_20191204_125443.jpg";
import archiv_3 from "../images/gallery_photos/archiv/IMG_20191204_125503.jpg";
import archiv_4 from "../images/gallery_photos/archiv/IMG_20191204_125507.jpg";
import archiv_5 from "../images/gallery_photos/archiv/IMG_20191204_125722.jpg";
import archiv_6 from "../images/gallery_photos/archiv/IMG_20191204_130837.jpg";
import archiv_7 from "../images/gallery_photos/archiv/IMG_20191204_130843.jpg";

import red_flat_1 from "../images/gallery_photos/red_flat/IMG_9506.JPG";
import red_flat_2 from "../images/gallery_photos/red_flat/IMG_9507.JPG";
import red_flat_3 from "../images/gallery_photos/red_flat/IMG_9508.JPG";
import red_flat_4 from "../images/gallery_photos/red_flat/IMG_9509.JPG";
import red_flat_5 from "../images/gallery_photos/red_flat/IMG_9510.JPG";
import red_flat_6 from "../images/gallery_photos/red_flat/IMG_9511.JPG";
import red_flat_7 from "../images/gallery_photos/red_flat/IMG_9512.JPG";
import red_flat_8 from "../images/gallery_photos/red_flat/IMG_9514.JPG";
import red_flat_9 from "../images/gallery_photos/red_flat/IMG_9516.JPG";
import red_flat_10 from "../images/gallery_photos/red_flat/IMG_9517.JPG";
import red_flat_11 from "../images/gallery_photos/red_flat/IMG_9518.JPG";
import red_flat_12 from "../images/gallery_photos/red_flat/IMG_9519.JPG";
import red_flat_13 from "../images/gallery_photos/red_flat/IMG_9520.JPG";
import red_flat_14 from "../images/gallery_photos/red_flat/IMG_9521.JPG";
import red_flat_15 from "../images/gallery_photos/red_flat/IMG_9522.JPG";
import red_flat_16 from "../images/gallery_photos/red_flat/IMG_9523.JPG";
import red_flat_17 from "../images/gallery_photos/red_flat/IMG_20191128_143923.jpg";
import red_flat_19 from "../images/gallery_photos/red_flat/IMG_20191128_143939.jpg";
import red_flat_18 from "../images/gallery_photos/red_flat/IMG_20191128_144236.jpg";
import red_flat_20 from "../images/gallery_photos/red_flat/IMG_20191128_144334.jpg";
import red_flat_21 from "../images/gallery_photos/red_flat/IMG_20191128_144626.jpg";
import red_flat_22 from "../images/gallery_photos/red_flat/IMG_20191128_144701.jpg";
import red_flat_23 from "../images/gallery_photos/red_flat/IMG_20191128_144850.jpg";
import red_flat_24 from "../images/gallery_photos/red_flat/IMG_20191128_145233.jpg";
import red_flat_25 from "../images/gallery_photos/red_flat/IMG_20191128_145629.jpg";
import red_flat_26 from "../images/gallery_photos/red_flat/IMG_20191128_150350.jpg";

import tsaneva_1 from "../images/gallery_photos/tsaneva/IMG_9495.JPG";
import tsaneva_2 from "../images/gallery_photos/tsaneva/IMG_20191127_125128.jpg";
import tsaneva_3 from "../images/gallery_photos/tsaneva/IMG_20191127_125159.jpg";
import tsaneva_4 from "../images/gallery_photos/tsaneva/IMG_20191127_125806.jpg";
import tsaneva_5 from "../images/gallery_photos/tsaneva/IMG_20191127_125813.jpg";
import tsaneva_6 from "../images/gallery_photos/tsaneva/IMG_20191127_125838.jpg";
import tsaneva_7 from "../images/gallery_photos/tsaneva/IMG_20191127_125906.jpg";
import tsaneva_8 from "../images/gallery_photos/tsaneva/IMG_20191127_133828.jpg";
import tsaneva_9 from "../images/gallery_photos/tsaneva/IMG_20191127_133833.jpg";
import tsaneva_10 from "../images/gallery_photos/tsaneva/IMG_20191127_133843.jpg";
import tsaneva_11 from "../images/gallery_photos/tsaneva/IMG_20191127_133854.jpg";
import tsaneva_12 from "../images/gallery_photos/tsaneva/IMG_20191127_133907.jpg";
import tsaneva_13 from "../images/gallery_photos/tsaneva/IMG_20191127_134642.jpg";
import tsaneva_14 from "../images/gallery_photos/tsaneva/IMG_20191127_134644.jpg";

import vortrag_1 from "../images/gallery_photos/vortrag/IMG_20191204_133054.jpg";
import vortrag_2 from "../images/gallery_photos/vortrag/IMG_20191204_133104.jpg";
import vortrag_3 from "../images/gallery_photos/vortrag/IMG_20191204_133134.jpg";
import vortrag_4 from "../images/gallery_photos/vortrag/IMG_20191204_133143.jpg";
import vortrag_5 from "../images/gallery_photos/vortrag/IMG_20191204_133224.jpg";
import vortrag_6 from "../images/gallery_photos/vortrag/IMG_20191204_133526.jpg";
import vortrag_7 from "../images/gallery_photos/vortrag/IMG_20191204_134119.jpg";
import vortrag_8 from "../images/gallery_photos/vortrag/IMG_20191204_134127.jpg";

export const archiv = [
    archiv_1,
    archiv_2,
    archiv_3,
    archiv_4,
    archiv_5,
    archiv_6,
    archiv_7,
];

export const red_flat = [
    red_flat_1,
    // red_flat_2,
    red_flat_3,
    red_flat_4,
    red_flat_5,
    red_flat_6,
    red_flat_7,
    red_flat_8,
    red_flat_9,
    red_flat_10,
    red_flat_11,
    red_flat_12,
    // red_flat_13,
    // red_flat_14,
    red_flat_15,
    red_flat_16,
    red_flat_17,
    red_flat_19,
    // red_flat_18,
    red_flat_20,
    red_flat_21,
    red_flat_22,
    red_flat_23,
    red_flat_24,
    red_flat_25,
    red_flat_26,
];

export const tsaneva = [
    tsaneva_1,
    tsaneva_2,
    tsaneva_3,
    tsaneva_4,
    tsaneva_5,
    tsaneva_6,
    tsaneva_7,
    tsaneva_8,
    tsaneva_9,
    tsaneva_10,
    tsaneva_11,
    tsaneva_12,
    tsaneva_13,
    tsaneva_14,
];

export const vortrag = [
    vortrag_1,
    vortrag_2,
    vortrag_3,
    vortrag_4,
    vortrag_5,
    vortrag_6,
    vortrag_7,
    vortrag_8,
];

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import Grid from "@material-ui/core/Grid";
import Card from "./Card";
import faker from "faker";
import background_top_left_mobile from "../images/background_top_left_mobile.png";
import background_top_left_clean from "../images/background_top_left_clean.png";
import background_left from "../images/background_left.png";
import background_bottom from "../images/background_bottom.png";
import sizes from "../styles/sizes";
import { LanguageContext } from "../contexts/LanguageContext";
import IndexListe from "./IndexListe";

import mainCards from "../content/mainCards";
import secondaryCards from "../content/secondaryCards";
import aboutNames from "../content/aboutNamesContent";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#f5f1e9",
        // backgroundColor: "rgba(227, 225, 222, 0.7)",
        // backgroundColor: "rgba(181, 255, 182,0.3)",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "100%, 30%",
        [sizes.down("xl")]: {
            backgroundSize: "100%, 37.5%",
        },
        backgroundPosition: "top, bottom right",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "100%, 75%",
            padding: "0",
            margin: "0",
        },
        paddingRight: "0",
        // border: "2px solid blue",
    },
    Grid: {
        // marginTop: "64px",
        padding: "1rem 1rem",
        paddingTop: "calc(64px + 1rem)",
        width: "100%",
        // border: "2px solid red",
        marginRight: "0",
        margin: "0",
        // alignItems: "stretch",
    },
    paper: {
        height: "150px",
        borderRadius: "0",
        backgroundColor: "rgba(232,78,76, 0.4)",
    },
});

export default function AboutPage() {
    const classes = useStyles();
    const { language, changeLanguage } = useContext(LanguageContext);

    // make an array containing the content of the two first cards and then the rest so we can map over them

    //
    // const image = faker.image.imageUrl();
    // console.log(image);
    return (
        <div className={classes.root}>
            <Navbar style={{ display: "none" }}></Navbar>
            <Grid
                className={classes.Grid}
                style={{
                    /*paddingTop: "calc(64px + 1rem)",*/ margin: "0 0 0 0",
                }}
                container
                // alignItems="stretch"
                spacing={3}
            >
                {mainCards[language].map((c) => (
                    <Grid item xs={12} md={12} lg={6}>
                        <Card
                            // style={{ borderRadius: "50% 0 0 50%" }}
                            title={c.title}
                            imageUrl={c.imgUrl}
                            text={c.text}
                            size={"large"}
                            email={c.email}
                            rolle={c.rolle}
                        ></Card>
                    </Grid>
                ))}
                {secondaryCards[language].map((c) => (
                    <Grid
                        // style={{ border: "1px solid black" }}
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Card
                            title={c.title}
                            imageUrl={c.imgUrl}
                            text={c.text}
                            size={"med"}
                            rolle={c.rolle}
                        ></Card>
                    </Grid>
                ))}
                <Grid
                    // style={{ border: "1px solid black" }}
                    item
                    xs={12}
                    md={6}
                    lg={4}
                >
                    <Card
                        // title={c.title}
                        // imageUrl={c.imgUrl}
                        // text={c.text}
                        size={"med"}
                        rolle={aboutNames[language]}
                    ></Card>
                </Grid>
                <Grid item xs={12}>
                    <IndexListe></IndexListe>
                </Grid>
            </Grid>
        </div>
    );
}

import sizes from "../styles/sizes";
import { makeStyles } from "@material-ui/core/styles";
import { LanguageContext } from "../contexts/LanguageContext";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: "flex",
        // height: "200vh",
        boxSizing: "borderBox",
    },
    title: {
        justifySelf: "left",
        dispay: "inline",
    },

    navLink: {
        // transition:
        //     'theme.transitions.create(["border"], {duration: theme.transitions.duration.standard,})}',
        marginRight: "1rem",
        fontSize: "1.1rem",
        fontWeight: "light",
        fontFamily: "'Commissioner', serif",
        // color: 'black',
        color: "rgb(50,50,50)",

        // fontFamily: "'Bebas Neue', cursive",
        textTransform: "uppercase",

        fontWeight: "bold",
        [sizes.down("nav820")]: {
            display: "none",
        },
        "&:after": {
            content:
                '""' /* This is necessary for the pseudo element to work. */,
            display:
                "flex" /* This will put the pseudo element on its own line. */,
            width:
                "0px" /* Change this to whatever width you want to have before hover. */,
            paddingTop:
                "1px" /* This creates some space between the element and the border. */,
            borderBottom:
                // CHANGE TO TRANSPARENT IF YOU NEED THE DELAY TO BE LONGER
                "1px solid transparent" /* This creates the border. Replace black with whatever color you want. */,
            transition:
                ".15s  " /* This establishes the amount of time in seconds the animation should take from start to finish */,
        },
        "&:hover:after": {
            width: "100%",
            // borderColor: "black",
            display: "block",
            borderBottom: "1px solid black",
        },

        backgroundColor: "white",

        cursor: "pointer",
    },
    NavBar: {
        boxSizing: "borderBox",
        width: "100%",
        zIndex: 1400,
        display: "flex",
        height: "64px",
        // border: "1px solid red",
        backgroundColor: "white",
        boxSizing: "border-box",
        "& .MuiAppBar-root": {
            boxSizing: "border-box",
        },
        // fixes twitch on dropdown open
        padding: "0 !important",
    },
    navImg: {
        // height: "100%",
        height: "64px",
        [sizes.down("us")]: {
            // border: "1px solid black",
            // height: "90%",
            height: "57px",
        },
        [sizes.down("sm")]: {
            // marginLeft: "auto",
        },
        cursor: "pointer",
    },
    firstNavImg: {
        height: "64px",
        [sizes.down("us")]: {
            // border: "1px solid black",
            height: "57px",
        },
        [sizes.down("nav820")]: {
            // marginLeft: "auto",
        },
    },
    navLinks: {
        display: "flex",
        // border: "2px solid black",
        height: "100%",
        width: "100%",
        alignItems: "center",

        // IF WE WANT TO TARGET THE WHOLE HISTORY TO GIVE IT A BORDER

        // "& > span": {
        //     border: "2px solid red",
        // },
    },
    downIcon: {
        marginLeft: "",
        cursor: "pointer",
        [sizes.down("nav820")]: {
            display: "none",
        },
    },
    NavMenu: {
        display: "none",
        [sizes.down("nav820")]: {
            marginLeft: "0.25rem",
            display: "flex",
            // borderRadius: "1rem",
            // width: "10px",
            minWidth: "44px",
            height: "calc(100% - 20px)",
        },
    },
    Drawer: {
        border: "2px solid black",
    },
    languageSpan: {
        [sizes.down("nav820")]: {
            display: "none",
        },
    },
    LanguageMenu: {
        [sizes.down("mobilelg")]: {
            display: "none",
        },
    },
}));

export default useStyles;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navbar";
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "64px",
    },
}));
export default function NotReady() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            <h1 style={{ marginTop: "16rem" }}>
                Diese Webseite befindet sich derzeit in Entwicklung
            </h1>
        </div>
    );
}

import pdf_20_g_neg from "../documents/20_g_neg.pdf";
import pdf_intro from "../documents/Intro_zu_Archiven_HP.pdf";
import pdf_AE2 from "../documents/2.pdf";
import pdf_AE3 from "../documents/3.pdf";
import pdf_god_plan_1974 from "../documents/1974-75_HP.pdf";
import pdf_doklad_PPO_1962 from "../documents/1962-63.pdf";
import pdf_Nakazaniq from "../documents/nakazaniq.pdf";
import pdf_PPO_AE2 from "../documents/PPO-AE2.pdf";
import pdf_PPO from "../documents/ppo.pdf";
import pdf_Protocol5 from "../documents/5.pdf";
import pdf_Protocol8 from "../documents/8.pdf";
import pdf_Archivnotizen from "../documents/Archivnotizen.pdf";
// IMPORTING IMAGES
import img_20_g_neg from "../images/pdf_thumbnails/20_g_neg.png";
import img_ae3 from "../images/pdf_thumbnails/ae3.png";
import img_intro from "../images/pdf_thumbnails/intro.png";
import img_godishen_plan from "../images/pdf_thumbnails/godishen_plan.png";
import img_archivnotizen from "../images/pdf_thumbnails/archivnotizen.png";

const docs = {
    bulgarian: [
        {
            img: img_intro,
            title: "Интро към архива",
            description: " ",
            doc: pdf_intro,
        },
        {
            img: img_20_g_neg,
            title: "Фотоалбум",
            description:
                "Фотоалбумът на 91-ва немска гимназия по случай 20-годишнината. Съдържа историята на гимназията, информация за първите учители и снимки на представянето на учениците. ",
            doc: pdf_20_g_neg,
        },
        {
            img: img_godishen_plan,
            title: "Годишен план 1974/1975",
            description:
                "Описани са основните задачи за годината, провеждането на учебния процес, извънкласните занимания и професионалното ориентиране. Включени са и повишаването на квалификацията на учителите, подобряването на материалната база и работата с родители.",
            doc: pdf_god_plan_1974,
        },
        {
            img: img_archivnotizen,
            title: "Бележки към архива",
            description: " ",
            doc: pdf_Archivnotizen,
        },
    ],
    english: [
        {
            img: img_intro,
            title: "Intro to the archive",
            description: " ",
            doc: pdf_intro,
        },
        {
            img: img_20_g_neg,
            title: "Photo Album",
            description:
                "The photo album of the 91. German Gymnasium on the occasion of the 20. Anniversary. Includes history of the high school, information about the early teachers, and photographs of student achievements.",
            doc: pdf_20_g_neg,
        },
        {
            img: img_godishen_plan,
            title: "Curriculum 1974/1975",
            description:
                "The main tasks for the year, the conduct of the learning process, extracurricular activities. Also included are the improvement of the qualification of teachers, the improvement of the facilities and work with parents.",
            doc: pdf_god_plan_1974,
        },
        {
            img: img_archivnotizen,
            title: "Archive notes",
            description: " ",
            doc: pdf_Archivnotizen,
        },
        // {
        //     img: "",
        //     // title: "AE3",
        //     title: "",
        //     // description: `Разказани са истории и впечатления от младежката бригада край село Клисура от 1863 г.
        //     //                         След това се отговаря на въпросите "Как работихме за подобряване на учебно-възпитателната работа?" и "Как чрез масовите организации провеждахме партийните си решения?"`,
        //     descruotion: "",
        //     doc: pdf_AE3,
        //     doc: "",
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_doklad_PPO_1962,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_Nakazaniq,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_PPO_AE2,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_PPO,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_Protocol5,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_Protocol8,
        // },
    ],
    german: [
        {
            img: img_intro,
            title: "Intro zu Archiven",
            description: " ",
            doc: pdf_intro,
        },
        {
            img: img_20_g_neg,
            title: "Fotoalbum des 91. Deutschen Gymnasiums",
            description:
                "Das Fotoalbum des 91. Deutschen Gymnasiums anlässlich des 20. Jubiläums. Enthält die Geschichte des Gymnasiums, Information über die ersten Lehrer und Fotos der Schülerleistungen. ",
            // doc: pdf_20_g_neg,
            doc: "",
        },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_AE2,
        // },
        // {
        //     img: "",
        //     // title: "AE3",
        //     title: "",
        //     // description: `Разказани са истории и впечатления от младежката бригада край село Клисура от 1863 г.
        //     //                         След това се отговаря на въпросите "Как работихме за подобряване на учебно-възпитателната работа?" и "Как чрез масовите организации провеждахме партийните си решения?"`,
        //     descruotion: "",
        //     doc: pdf_AE3,
        //     doc: "",
        // },
        {
            img: img_godishen_plan,
            title: "Schulplan 1974/1975",
            // description:
            //     "The main tasks for the year, the conduct of the learning process, extracurricular activities. Also included are the improvement of the qualification of teachers, the improvement of the facilities and work with parents.",
            description: "Schulplan für den Jahren 1974/1975",
            doc: pdf_god_plan_1974,
        },
        {
            img: img_archivnotizen,
            title: "Archivnotizen",
            description: " ",
            doc: pdf_Archivnotizen,
        },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_doklad_PPO_1962,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_Nakazaniq,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_PPO_AE2,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_PPO,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_Protocol5,
        // },
        // {
        //     img: "",
        //     title: "",
        //     description: "",
        //     doc: pdf_Protocol8,
        // },
    ],
};

export default docs;

const schulSystemContent = {
    bulgarian: [
        "България е унитарна република, която е разделена на 28 различни области. За разлика от Германия, вътрешните закони и правила се прилагат във всички области. Това важи и за българските училища и образователна система. В следващия текст е представена и обяснена българската училищна система. ",
        "Училищното и висшето образование трябва да бъдат разграничени едно от друго: на първо място стои началното образованиет. Българските ученици трябва да ходят на училище до 16-годишна възраст, едва след това приключва задължителното обучение. По правило българските ученици учат в продължение на дванадесет години. Започват в първи клас на шест или седем години. Дванадесет години по-късно обикновено завършват училище на 18 или 19 години. Периодът на обучение е главно разделен на следните три нива: начално, основно и средно образование.",
        "Учениците започват с начално образование: ",
        "Завършват го в края на четвърти клас.",
        "Преди това се полагат два изпита по български език и математика.",
        "Получават сертификат за завършено основно образование, с който кандидатстват за основно училище.",
        "За първите четири години могат да посещават следните типове училища и да получавате основно обучение там:",
        "Начално училище: Този тип училище е много често срещан в малките градове и по-малките жилищни райони. В допълнение към този тип училище има само едно средно училище или гимназия. В началното училище се учи в продължение на четири години (от първи до четвърти клас). След завършване на началното училище децата се местят в основно училище.",
        "Основно училище: Този тип училище е по-често срещан от началното училище. В него се учи до седми клас. След това се преминава към средно училище или гимназия.",
        "Средно общообразователно училище / СОУ : Този тип обхваща целите дванадесет учебни години.",
        "Следва т. нар. основно образование, което започва след седми клас. В края на седми клас се полага изпит по всички предмети, като тези по математика и български език са най-важните. Оценките за учебната година и резултатите от тези изпити определят броя точки, с които кандидатствате за гимназия. Всяко училище има различни критерии за прием по отношение на броя точки. Следващата таблица обяснява как този брой точки се образува: ",
        "Максималният брой точки, който може да бъде получен от оценките на учебната година в седми клас по предметите математика и български език, е по 50 точки, тоест общо 100 точки.",
        "Втората таблица показва различните критерии за съставяне на общия брой точки: Различните училища например изискват по-добри познания по математика или български език, поради което този клас се претегля три пъти. В други училища и двата предмета са еквивалентни. В някои случаи като художествено или музикално училище, има допълнителне изпит, който също се добавя.",
        "Освен това се получава сертификат за завършено основно обучение.",
        "Последният етап от училищното образование е средното, което се завършва след дванадесети клас. Тогава се полагат два изпита (матури), единият по български и вторият - избираем. ",
        "През изминалата 2019г. българската училищна система беше реформирана: учениците полагат двете матури след десети клас и след това получават сертификат за завършено основно обучение. След това в единадесети и дванадесети клас избират конкретен профил, за да се специализират в определена област.",
        "Учниците могат да приключат обучението си след десети клас, но не могат да кандидатствате в университет. За сметка на това получават удостоверение за завършено основно образование. Средното образование се завършва в гимназия или техническо училище. И двата вида включват класовете от 8 до 12. Основната разлика между тях е следната: фокусът на гимназията е предимно изучаването на чужд език, докато при техническото училище той е върху науките. ",
        "След средното следва университетско образование. То е представено в следната таблица в общ вид:",
        "В този източник",
        "са първите две таблици, които обясняват съставянето на съответния брой точки.",
        "Тук",
        "можете да намерите оригиналната таблица за висшето образование в България. И трите таблици са преведени на немски от мен (Александър Георгиев).",
        "Българската училищна система",
    ],
    english: [
        // primary, secondary, high
        "Bulgaria is a unitary republic divided into 28 distinct territories. Unlike Germany, all domestic laws and rules apply in all territories, which is due to this uniformity. This also applies to the Bulgarian education system presented in the following text. ",
        "School and higher education are separated: firstly, primary education should be considered. Bulgarian students must be educated by 16. Only then does the compulsory schooling end. As a rule, the majority of Bulgarian students study for a period 12 years. You start at six or seven in first grade. The students usually leave school at the age of 18 or 19. Schooling is divided into three main stages: primary education, main education and higher education.",
        "Primary education (in Bulgarian “Начално образование”) is the first phase:",
        "It ends after fourth grade.",
        "Before completing the fourth grade, you have to take two examinations in the subjects Bulgarian and Mathematics.",
        "You get a certificate for the basic education that you have completed, with which you can apply for primary or secondary school.",
        "During the first four years of school, students can attend the following types of schools and receive basic education:",
        'Elementary school ("Начално училище"): This type of school is very often represented in small towns and smaller places of residence. In addition to this type of school, there is only a midlle and a high school. In primary school you learn for four years (grades 1 to 4). After graduating from primary school, students are transferred to secondary school.',
        'Secondary school ("Основно училище"): This type of school is more common than primary school. In secondary school you learn until the seventh grade. Then you have to go to either secondary or high school.',
        'High school ("Средно общобразователно училище"/"СОУ"): This type of school covers all twelve school years.',
        'This is followed by the so-called secondary education ("Основно образование"), which begins after the seventh grade. At the end of the seventh grade one takes an exam in all subjects, the most important being those in Mathematics and Bulgarian. The grades from these exams determine the score you get and with which you can apply for secondary school. Each one has different criteria for admission as far as scores are concerned. The following table explains how this score is calculated:',
        "As the first table illustrates, the maximum number of points that can be obtained from the school year grades in the seventh grade in Mathematics and Bulgarian is 50 points, for a total of 100 points.",
        "The second table shows the different criteria for the composition of the total score: different schools, for example, require better knowledge of Mathematics or Bulgarian, which is why this note is weighted three times. In other schools, both subjects are equivalent. In some cases, i. e. when applying for an art or music school, there is an additional exam which is crucial to the final grade.",
        "You also get a certificate for the completed level of education.",
        'The last stage of the school education is the secondary education ("Средно образование") This is completed after the twelfth grade. After the twelfth grade one takes two final exams, one in Bulgarian and one in a subject yoo choose on your own.',
        "In 2019, the Bulgarian school system was reformed: students take the two final exams after the tenth grade and then receive the certificate for the completed secondary education. After that, in the eleventh and twelfth grade, they choose a certain advanced course in order to prepare the specialization in a certain subject.",
        "You are allowed to leave school after the tenth grade, but then you cannot apply for a place at university. On the other hand you get your certificate for the completed education. It can be completed in a technical or language high school. You apply for the them after the seventh grade. They cover grades 8 through 12. The main difference between the two types of schools is the field of study. The main focus of a language school is usually the learning of a foreign language, while a technical school usually specialises in a natural science.",
        "After secondary school education there may be a subsequent university education. It is presented in a very general form in the following table:",
        "This source",
        "is for the first two tables, which explain the respective scores.",
        "Here",
        "you can find the original table about higher education in Bulgaria. All three tables were translated into German by me (Alexander Georgiev).",
        "The bulgarian school system",
    ],
    german: [
        "Bulgarien ist eine einheitliche Republik, die intern in 28 unterschiedliche Gebiete unterteilt ist. Im Unterschied zu Deutschland gelten alle innenpolitischen Gesetze und Regeln in allen Gebieten, was auf diese Einheitlichkeit zurückzuführen ist. Das betrifft auch das bulgarische Schul- und Ausbildungssystem. Im folgenden Text wird das bulgarische Schulsystem vorgestellt und erläutert werden.",
        "Dabei sollte man die schulische Ausbildung und die Hochausbildung voneinander trennen: Zunächst soll es hier um die schulische Ausbildung gehen. Bulgarische Schüler müssen bis zum 16. Lebensjahr in die Schule gehen, erst danach endet die staatliche Schulpflicht. In der Regel lernt die Mehrheit der bulgarischen Schüler zwölf Jahre lang. Man startet mit sechs bzw. sieben Jahren in der ersten Klasse. Zwölf Jahre später absolviert man diese Schule in der Regel mit 18 oder 19 Jahren. Die Schulzeit unterteilt sich hauptsächlich in die folgenden drei Stufen: Grundausbildung, Hauptausbildung und Mittelausbildung.",
        "Die Grundausbildung („auf Bulgarisch “Начално образование“) ist die Anfangsphase:",
        "Die Grundausbildung schließt man am Ende der vierten Klasse ab.",
        "Bevor man die vierte Schulklasse abschließt, muss man zwei Prüfungen in den Fächern Bulgarisch und Mathematik (auch „Mini-Abitur“ genannt) ablegen.",
        "Man bekommt ein Zeugnis für die abgeschlossene Grundausbildung, mit dem man sich für die Haupt- oder Sekundarschule bewirbt.",
        "Die ersten vier Schuljahre kann man die folgenden Schultypen besuchen und dort die Grundausbildung bekommen:",
        "Grundschule („Начално училище“): Dieser Schultyp ist sehr oft in Kleinstädten und kleineren Wohnorten vertreten. Grundsätzlich gibt es dann dort über diese Schulform hinaus lediglich eine Haupt- oder Realschule bzw. ein Gymnasium. In der Grundschule lernt man vier Jahre lang (Klasse 1 bis 4). Nach dem Abschluss der Grundschule wird man zu der Haupt- oder zu der Sekundarschule überwiesen.",
        "Hauptschule („Основно училище“): Dieser Schultyp ist häufiger als die Grundschule. In der Hauptschule lernt man bis zur siebten Klasse. Dann muss man entweder auf die Sekundarschule oder aufs Gymnasium.",
        "Allgemeinschule („Средно общообразователно училище“/“СОУ“): Dieser Schultyp umfasst alle zwölf Schuljahre.",
        "Im Anschluss folgt die sogenannte Hauptausbildung („Основно образование“), die nach der siebten Klasse beginnt. Am Ende der siebten Klasse legt man eine Prüfung in allen Fächern ab, wobei diejenigen in Mathematik und Bulgarisch am wichtigsten sind. Die Schuljahresnoten und die Ergebnisse aus diesen Prüfungen bestimmen die Punktzahl, die man bekommt und mit der man sich für eine Haupt- bzw. Sekundarschule oder für ein Gymnasium bewirbt. Jede Schule hat unterschiedliche Kriterien für die Aufnahme, was die Punktzahl betrifft. Die folgende Darstellung erklärt, wie diese Punktzahl zustande kommt:",
        "Wie die erste Tabelle veranschaulicht, beträgt die maximale Punktzahl, die man aus den Schuljahresnoten in der siebten Klasse in den Fächern Mathematik und Bulgarisch bekommen kann, 50 Punkte, also insgesamt 100 Punkte.",
        "Die zweite Tabelle zeigt die unterschiedlichen Kriterien der Zusammensetzung der Gesamtpunktzahl: Verschiedene Schulen verlangen zum Beispiel bessere Kenntnisse im Fach Mathematik bzw. Bulgarisch, weswegen diese Note dreifach gewichtet wird. Bei anderen Schulen sind beide Fächer gleichbedeutend. In einigen Fällen, also der Bewerbung für eine Kunst- oder Musikschule, zählt auch die sogenannte Fähigkeitsprüfung.",
        "Auch für die abgeschlossene Hauptausbildung bekommt man am Ende ein Zeugnis. Die Hauptausbildung bekommt man in der Haupt-, Sekundar- oder Allgemeinschule.",
        "Die letzte Stufe der schulischen Ausbildung ist die Mittelausbildung („Средно образование“) Diese schließt man nach der zwölften Klasse ab. Nach der zwölften Klasse legt man zwei Prüfungen (Abitur) ab, eine im Fach Bulgarisch, während man sich das Fach der zweiten Prüfung wählen darf.",
        "Letztes Jahr (2019) wurde das bulgarische Schulsystem reformiert: Die Schüler legen die beiden Abiturprüfungen nach der zehnten Klasse ab und bekommen dann das Zeugnis für die abgeschlossene Hauptausbildung. Danach, in der elften und der zwölften Klasse, wählt man einen bestimmten Leistungskurs aus, um damit die Spezialisierung auf ein bestimmtes Studium vorzubereiten.",
        "Man darf zwar schon nach der zehnten Klasse die Schule verlassen, kann sich dann allerdings nicht um einen Studienplatz bewerben. Man bekommt sein Zeugnis für die abgeschlossene schulische Ausbildung. Man schließt die Mittelausbildung auf der Allgemein-, Sekundar-, Fachschule oder auf dem Gymnasium ab. Um die Fachschule oder um das Gymnasium bewirbt sich man nach der siebten Klasse. Beide umfassen die Klassen 8 bis 12. Der Hauptunterschied zwischen beiden Schultypen ist die Fachrichtung. Schwerpunkt eines Gymnasiums ist meistens das Erlernen einer Fremdsprache, während die Fachschule sich meistens auf eine Naturwissenschaft spezialisiert.",
        "Nach der ausführlich dargestellten Schulausbildung folgt eventuell noch eine anschließende Hochschulausbildung. Die universitäre Ausbildung ist in der folgenden tabellarischen Übersicht in einer sehr allgemeinen Form dargestellt:",
        "Dieser Quelle",
        "sind die beiden ersten Tabellen, die das Zustandekommen der jeweiligen Punktzahl erläutern, entnommen.",
        "Hier",
        "finden Sie die Original-Tabelle über die Hochschulausbildung in Bulgarien. Alle drei Tabellen wurden von mir (Alexander Georgiev) ins Deutsche übersetzt.",
        "Das bulgarische Schulsystem",
    ],
};
export default schulSystemContent;

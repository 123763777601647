const archiveContent = {
    bulgarian: [
        "Работата по проекта „Erinnern für die Gegenwart“ се разви в няколко етапа. В началото посетихме Държавния архив, където разгледахме документи, отнасящи се до историята на нашата гимназия „Проф. Константин Гълъбов“ и обхващащи периода от основаването на нашето училище в началото на 60-те до края на социализма в края на 80-те години.",
        "Първата стъпка беше да изберем само онези документи, които биха могли да ни интересуват. Ние, малка група от четирима души, придружени от нашия учител по история, отидохме в читалнята на архива, където ни беше даден архивен указател на всички източници, касаещи нашата гимназия. Подбрахме най-интересните архивни документи, които да четем по-късно. Списъкът с документи не беше твърде разнообразен - състоеше се от доклади, учебни програми, образователни планове, статистически данни, инвентарни списъци и др. След като избрахме документите, трябваше да изчакаме няколко дни, за да пристигнат, тъй като те се съхраняват на различни места.",
        "Когато поръчаните документи бяха доставени, се заехме с работата. Членовете на малката ни група се събираха 5-6 пъти преди или след училище. Прекарвахме един до три часа в читалнята на Държавния архив. През нашите ръце са минали около 20 архивни записа.",
        "Съдържанието на документите не беше твърде различно от това, което човек би очаквал от училищните документация днес. Въпреки това имаше значителни особености - напр. когато изведнъж се споменава някъде за Българската комунистическа партия (БКП). Точно тези места, където влиянието на политиката е видимо върху училищния живот, сме и търсили. Освен това често се споменават някои типични социалистически организации - като Комсомол. По този начин човек се задълбочава в това време и започва да го разбира по-добре. Освен това има документирани инциденти и нарушения на правилата от учениците, които са изумителни, защото никога не би могло да се предположи, че някой ще посмее такова нещо по време на социалистически режим, камо ли някой ученик.",
        "В крайна сметка направихме няколко записа и това беше краят на нашата работа в държавните архиви. Периодът на проучване започва от декември 2019 г. и приключва през февруари 2020 г.",
        "Архив",
    ],
    english: [
        'The work on the project “Erinnern für die Gegenwart” was multi-layered for our team. First there was the visit to the State Archives, where we looked at documents related to our school "Prof. Konstantin Galabov", and the period from the founding of our school in the early 60s to the end of the socialist era in Bulgaria in the late 1980s.',
        "The first step was to select those documents that could be of interest to us. We, a small group of 4, accompanied by our history teacher, went to the reading room of the archives, where we were given an archival index of all the sources concerning our high school, trying to choose the most interesting archival documents to read later. The list of documents was not too diverse - it consisted of reports, curricula and educational plans, statistical data, inventory lists, etc. After we had picked out some documents, we had to wait a few days for the archival documents to arrive, because they were stored in different places.",
        "When the ordered documents were delivered, we got to work. The members of our small group met 5-6 times before or after school. We spent one to three hours in the State Archives reading room. At least a dozen and a half archival records have passed through our hands.",
        "As for the content of the documents, it was not too different from what one would expect of school papers today. Nevertheless, there were significant oddities – e. g. when suddenly the Bulgarian Communist Party (BCP) is mentioned somewhere. It is precisely these places where the influence of politics is visible on school life that we have been looking for. In addition, some typical socialist organisations are often mentioned – such as Komsomol. In this way one dives deeper into that time and begins to understand it better. Furthermore, there are documented incidents and violations of the rules by the students, which are astonishing because one could never have guessed that someone would dare such actions in times of a socialist regime, let alone that this someone would be a student.",
        "In the end we made some recordings and that was the end of our work in the state archives. The whole matter has stretched from December 2019 to February 2020.",
        "Archive",
    ],
    german: [
        "Die Arbeit am Projekt „Erinnern für die Gegenwart“ war für unser Team mehrschichtig. Eine der Schichten war der Besuch im Staatsarchiv, wo wir uns Dokumente angeschaut bzw. gelesen haben, die sich auf unser 91. Gymnasium „Prof. Konstantin Galabov“ beziehen und die Zeitspanne von der Gründung unserer Schule Anfang der 60er bis zum Ende des kommunistischen bzw. sozialistischen Regimes in Bulgarien Ende der 80er Jahre umfassen.",
        "Der erste Schritt war die Auswahl derjenigen Dokumente, die für uns Interesse darstellen konnten. Wir, eine kleine 4er Gruppe, sind in Begleitung unserer Geschichtslehrerin in den Lesesaal des Archivs gegangen, wo wir ein Archivalienverzeichnis aller Quellen, die unser Gymnasium betreffen, bekommen haben, wobei wir versucht haben, die interessantesten Archivalien auszusuchen, die wir später zu lesen bekommen sollten. Das Dokumentenverzeichnis war nicht allzu vielfältig – es bestand aus Berichten, Lehr- und Erziehungsplänen, statistischen Angaben, Inventarverzeichnissen u. Ä. Nachdem wir einige Dokumente herausgegriffen hatten, mussten wir ein paar Tage abwarten, bis die Archivalien ankommen, weil sie an unterschiedlichsten Orten gelagert waren.",
        "Als die bestellten Dokumente geliefert wurden, haben wir uns an die Arbeit gemacht. Mehrere Leute waren Mitglieder der Kleingruppe während der fünf oder sechs Male, wann wir vor bzw. nach der Schule eine bis drei Stunden im Lesesaal des Staatsarchivs verbracht haben. Mindestens eineinhalb Dutzend Archivalien sind durch unsere Hände gegangen.",
        "Was den Inhalt der Dokumente betrifft, war er nicht allzu unterschiedlich von dem, was man heutzutage von schulischen Papieren erwarten würde. Dennoch kamen wesentliche Seltsamkeiten vor – z. B. wenn plötzlich irgendwo die Bulgarische Kommunistische Partei (BKP) erwähnt wird. Nämlich diese Stellen, an denen der Abdruck der parteilichen Politik auf dem Schulleben sichtbar wird, haben wir erwartet und gesucht. Außerdem werden oft einige typisch sozialistische Organisationen erwähnt – wie beispielsweise Komsomol. Derart taucht man tiefer in jene Zeit ein und fängt an, sie besser zu begreifen. Zudem kommen manche dokumentierten Vorfälle und Verstöße der Regeln seitens der Schüler, die für Staunen sorgen, weil man nie hätte mutmaßen können, dass jemand dies oder das in Zeiten eines sozialistischen Regimes wagen würde, geschweige denn, dass dieser jemand ein Schüler wäre.",
        "Am Ende haben wir einige Aufnahmen gemacht und damit war unsere Arbeit im Staatsarchiv beendet. Die ganze Angelegenheit hat sich von Dezember 2019 bis Februar 2020 erstreckt.",
        "Archiv",
    ],
};
export default archiveContent;

const linksContent = {
    bulgarian: [
        {
            link: "https://da-galabov.eu/",
            text: "Немски отдел",
        },
        {
            link: "http://91neg.bg/",
            text: "91. НЕГ",
        },
        {
            link: "https://www.facebook.com/da.galabov/",
            text: "Facebook",
        },
    ],
    english: [
        {
            link: "https://da-galabov.eu/",
            text: "german department",
        },
        {
            link: "http://91neg.bg/",
            text: "91. german high school",
        },
        {
            link: "https://www.facebook.com/da.galabov/",
            text: "Facebook",
        },
    ],
    german: [
        {
            link: "https://da-galabov.eu/",
            text: "deutsche abteilung",
        },
        {
            link: "http://91neg.bg/",
            text: "91. deutsches gymnasium",
        },
        {
            link: "https://www.facebook.com/da.galabov/",
            text: "DA Facebook",
        },
    ],
};
export default linksContent;

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import { LanguageContext } from "../contexts/LanguageContext";
import galleryContent from "../content/galleryContent";
import "react-responsive-carousel/lib/styles/carousel.css";
// import "../styles/gallery-carousel.css";
import background_bottom from "../images/background_bottom.png";
import background_top_left_clean from "../images/background_top_left_clean.png";
import background_top_left_mobile from "../images/background_top_left_mobile.png";
import { Carousel } from "react-responsive-carousel";
// import carousel_images from "../content/carouselImages";
import sizes from "../styles/sizes";
import { archiv, red_flat, tsaneva, vortrag } from "../content/galleryPhotos";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#f5f1e9",
        minHeight: "100vh",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "100%, 30%",
        backgroundPosition: "top, bottom right",
        backgroundAttachment: "fixed, fixed",
        paddingBottom: "2rem",
        //"url(" + background_bottom + ")",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`,
            backgroundSize: "100%, 60%",
        },

        "@global": {
            "div.thumbs-wrapper": {
                display: "none",
                marginTop: "0",
            },
            // styling carousel
            "div.carousel-root, div.carousel.carousel-slider, div.slider-wrapper, ul.slider.animated, li.slide > div": {
                // [sizes.down("md")]: {
                // height: `calc(${"700"}px)`,
                height: "calc(100vh-64px)",
                width: "90vw",
                margin: "0 auto",

                [sizes.down("xl")]: {
                    height: "75vh",
                    width: "90vw",
                    margin: "0 auto",
                },
                [sizes.down("lg")]: {
                    height: "65vh",
                    width: "90vw",
                    margin: "0 auto",
                },
                [sizes.down("md")]: {
                    height: "55vh",
                    width: "90vw",
                    margin: "0 auto",
                },
                [sizes.down("nav820")]: {
                    height: "50vh",
                    width: "100%",
                },
                [sizes.down("xs")]: {
                    height: "60vw",
                    width: "100%",
                },
                [sizes.down("us")]: {
                    height: "60vw",
                    width: "100%",
                },
                // height: "500px",
                // },
            },
            "div.carousel-root > div.carousel.carousel-slider > ul.control-dots": {
                [sizes.down("sm")]: {
                    display: "none",
                },
            },
        },
    },
    titleSection: {
        marginTop: "64px",
    },
    title: {
        fontFamily: "'Commissioner', serif",
        fontSize: "7rem",
        marginBottom: "0.5rem",
        marginTop: "0rem",
        fontFamily: "'Commissioner', serif",

        // marginTop: "5rem",
    },
    textBox: {
        backgroundColor: "rgba(82, 235, 52, 0.3)",
        margin: "1.5rem 3rem",
        fontWeight: "light",
        fontFamily: "'Commissioner', serif",
        fontSize: "1.5rem",
        padding: "1.5rem",
    },
    card: {
        // maxWidth: 345,
        marginTop: "1rem",
        margin: "1rem 1rem",
        height: "24rem",
    },
    cardsSection: {
        // border: "2px solid red",
        margin: "0 2rem",
    },
    carouselSection: {
        // height: "60vh",
    },
    carouselImg: {
        height: "calc(100vh - 64px)",
        // padding: "1rem 0",
        [sizes.down("xl")]: {},
        [sizes.down("lg")]: {},
        [sizes.down("md")]: {},
        [sizes.down("sm")]: {},
        [sizes.down("xs")]: {},
    },
    carouselTitle: {
        // border: "2px solid red",
        padding: "4rem 0",
        width: "90vw",
        margin: "2rem auto",
        backgroundColor: "rgba(255,255,255,0.9)",
        fontFamily: "'Commissioner', serif",
        // textTransform: "uppercase",

        fontSize: "3rem",
        fontWeight: "300",
        [sizes.down("lg")]: {
            padding: "3.5rem 0",
        },
        [sizes.down("md")]: {
            padding: "3rem 0",
        },
        [sizes.down("nav820")]: {
            padding: "2rem 0",
            width: "100%",
        },
        [sizes.down("xs")]: {
            width: "100%",
            fontSize: "2.5rem",
            margin: "1.5rem 0",
            padding: "1.5rem 1rem",
        },
        [sizes.down("us")]: {
            width: "100%",
            fontSize: "2rem",
            margin: "1rem 0",
            padding: "1rem 1rem",
        },
    },
    separatorDiv: {
        paddingTop: "calc(64px + 1rem)",
        [sizes.down("xs")]: {
            paddingTop: "calc(64px + 0.5rem)",
        },
        [sizes.down("us")]: {
            paddingTop: "64px",
        },
        // paddingTop: "5rem",
    },
}));
export default function ArchivePage() {
    const classes = useStyles();
    const { language } = useContext(LanguageContext);

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            <div className={classes.separatorDiv}></div>
            <h1 className={classes.carouselTitle} style={{ marginTop: "1rem" }}>
                {galleryContent[language][0]}
            </h1>
            <Carousel
                style={{
                    border: "5px solid red",
                    marginTop: "64px",
                    marginBottom: "0",
                }}
            >
                {archiv.map((i) => (
                    <div
                        className={classes.carouselImg}
                        style={{
                            // border: "10px solid red",

                            backgroundImage: `url(${i})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: "0",
                        }}
                    >
                        {/* <img src={i}></img> */}
                    </div>
                ))}
            </Carousel>
            <h1 className={classes.carouselTitle}>
                {galleryContent[language][1]}
            </h1>
            <Carousel
                style={{
                    // border: "5px solid red",
                    marginTop: "64px",
                    marginBottom: "0",
                }}
            >
                {red_flat.map((i) => (
                    <div
                        className={classes.carouselImg}
                        style={{
                            // border: "10px solid red",

                            backgroundImage: `url(${i})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: "0",
                        }}
                    >
                        {/* <img src={i}></img> */}
                    </div>
                ))}
            </Carousel>
            <h1 className={classes.carouselTitle}>
                {" "}
                {galleryContent[language][2]}
            </h1>
            <Carousel
                style={{
                    border: "5px solid red",
                    marginTop: "64px",
                    marginBottom: "0",
                }}
            >
                {tsaneva.map((i) => (
                    <div
                        className={classes.carouselImg}
                        style={{
                            // border: "10px solid red",

                            backgroundImage: `url(${i})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: "0",
                        }}
                    >
                        {/* <img src={i}></img> */}
                    </div>
                ))}
            </Carousel>
            <h1 className={classes.carouselTitle}>
                {" "}
                {galleryContent[language][3]}
            </h1>
            <Carousel
                style={{
                    border: "5px solid red",
                    marginTop: "64px",
                    // marginBottom: "2rem",
                }}
            >
                {vortrag.map((i) => (
                    <div
                        className={classes.carouselImg}
                        style={{
                            // border: "10px solid red",

                            backgroundImage: `url(${i})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: "0",
                        }}
                    >
                        {/* <img src={i}></img> */}
                    </div>
                ))}
            </Carousel>
        </div>
    );
}

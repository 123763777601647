import PJansche_Lehrer from "../images/school_photos/PJansche_Lehrer.JPG";
import RKrause_Lehrer from "../images/school_photos/RKrause_Lehrer.JPG";

const mainCards = {
    bulgarian: [
        {
            title: "Филип Янше",
            imgUrl: `${PJansche_Lehrer}`,
            rolle: "Учител по математика и история, организация и комуникация",
            text:
                // EMAIL
                'Предаването на история чрез общуване със съвременни свидетели е едновременно интересно и полезно. Тук, в 91-ва Немска гимназия "проф. Константин Гълъбов", това все още е възможно за разглеждания период и мисля, че е важно да бъде включено в обучението.',
            email: "Контакт: pjansche@yahoo.de",
        },

        {
            title: "Робърт Краузе",
            imgUrl: `${RKrause_Lehrer}`,
            rolle: "Учител по немски и история, организация и комуникация",
            text:
                // EMAIL
                "Мотивирането на учениците да се ангажират с интензивно разглеждане на тази историческа тема извън задължителния материал е възхитителна задача. Намирам за особено впечатляващо как всички участници допринесоха към този проект с индивидуалните си способности и по този начин се превърнаха в наистина добър екип.",
            email: "Контакт: robertkrause@web.de",
        },
    ],
    english: [
        {
            title: "Philipp Jansche",
            imgUrl: `${PJansche_Lehrer}`,
            rolle:
                "Mathematics and history teacher, organisation und Communication",
            text:
                "Teaching history through engagement with contemporary witnesses is both interesting and meaningful. Here at Galabov, this is still possible for the period under study, so I think it is important to implement this.",
            email: "Contact: pjansche@yahoo.de",
        },
        {
            title: "Robert Krause",
            imgUrl: `${RKrause_Lehrer}`,
            rolle: "German and history teacher, organisation und Communication",
            text:
                "Motivating students to engage in such an intensive examination of this historical topic beyond the course of history lessons is a delightful task. I find it particularly impressive how independently all of them contributed to this project with their individual abilities and thus became a really good team.",
            email: "Contact: robertkrause@web.de",
        },
    ],
    german: [
        {
            title: "Philipp Jansche",
            imgUrl: `${PJansche_Lehrer}`,
            rolle:
                "Lehrer für Mathematik und Geschichte, Organisation und Technik",
            text:
                "Geschichte über die Beschäftigung mit Zeitzeugen zu vermitteln, ist sowohl interessant als auch sinnvoll. Hier am Galabov ist das für den untersuchten Zeitraum noch möglich und daher halte ich es für wichtig, dies auch umzusetzen.",
            email: "Kontakt: pjansche@yahoo.de",
        },
        {
            title: "Robert Krause",
            imgUrl: `${RKrause_Lehrer}`,
            rolle:
                "Lehrer für Deutsch und Geschichte, Organisation und Kommunikation",
            text:
                "Schülerinnen und Schüler über den Geschichtsunterricht hinaus für eine solch intensive Auseinandersetzung mit diesem historischen Thema zu motivieren, ist eine reizvolle Aufgabe. Besonders beeindruckend finde ich, wie selbstständig sich alle in diesem Projekt mit ihren individuellen Fähigkeiten eingebracht haben und damit zu einem richtig guten Team geworden sind.",
            email: "Kontakt: robertkrause@web.de",

            // "Der Erfolg des Projekts besteht vor allem in der Aufarbeitung der Schulgeschichte durch die Schüler.",
        },
    ],
};

export default mainCards;

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link } from "react-router-dom";
import sizes from "../styles/sizes";
import { LanguageContext } from "../contexts/LanguageContext";

import linksArray from "../content/linksContent";

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        borderRadius: "0",
        backgroundColor: "rgba(255,255,255,0.9)",

        boxShadow: "none",
        // padding: "16px 20px 16px 20px",
        // boxSizing: "content-box",
    },
    indexContent: {
        // boxSizing: "border-box",
        // border: "2px solid black",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        // border: "1px solid black",
        height: "auto",
        [sizes.down("sm")]: {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        //has pasdding
        // padding: "0.5rem",
    },
    indexLink: {
        fontFamily: "'Commissioner', serif",
        // color: 'black',
        color: "rgb(50,50,50)",
        fontSize: "1rem",
        cursor: "pointer",
        textDecoration: "none",
        // fontFamily: "'Bebas Neue', cursive",
        textTransform: "uppercase",
        borderBottom: "1px solid transparent",
        // "&:hover": {
        //     borderBottom: "1px solid rgba(0,0,0,0.75)",
        // },
        [sizes.down("sm")]: {
            // display: "block",
            // width: "100%",
            marginTop: "1rem",
        },
        "&:after": {
            content:
                '""' /* This is necessary for the pseudo element to work. */,
            display:
                "flex" /* This will put the pseudo element on its own line. */,
            width:
                "0px" /* Change this to whatever width you want to have before hover. */,
            paddingTop:
                "1px" /* This creates some space between the element and the border. */,
            borderBottom:
                // CHANGE TO TRANSPARENT IF YOU NEED THE DELAY TO BE LONGER
                "1px solid transparent" /* This creates the border. Replace black with whatever color you want. */,
            transition:
                ".2s  " /* This establishes the amount of time in seconds the animation should take from start to finish */,
            margin: "0 auto",
        },
        "&:hover:after": {
            width: "100%",
            // borderColor: "black",
            display: "block",
            borderBottom: "1px solid black",
            margin: "0 auto",
        },
    },
});

// nila neues photo

// hp na da
// hp na nemskata
// facebookseite na da
// link fur kontakt

export default function IndexListe() {
    const classes = useStyles();
    const { language } = useContext(LanguageContext);

    // const linksArray = [
    //     {
    //         link: "https://da-galabov.eu/",
    //         text: "Deutsche abteilung",
    //     },
    //     {
    //         link: "http://91neg.bg/",
    //         text: "91. Deutsches Gymnasium",
    //     },
    //     {
    //         link: "https://www.facebook.com/da.galabov/",
    //         text: "DA FACEBOOK",
    //     },

    // here the email of jansche
    // add contact????
    return (
        <Card className={classes.root}>
            {/* <CardActionArea> */}
            <CardContent
                className={classes.cardContent}
                style={{ paddingTop: "0.9rem", paddingBottom: "1.15rem" }}
            >
                {/* <Typography gutterBottom variant="h5" component="h2">
                    Lizard
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                </Typography> */}
                <div className={classes.indexContent}>
                    {linksArray[language].map((link) => [
                        <Link
                            to={`${link.link}`}
                            target="_blank"
                            // onClick={() => {
                            //     window.location.assign("http://github.com");
                            // }}
                            className={classes.indexLink}
                            onClick={(event) => {
                                event.preventDefault();
                                window.open(`${link.link}`);
                            }}
                        >
                            {link.text}
                        </Link>,
                    ])}
                    {/* 
                    
                    <Link
                        to="http://91neg.bg/"
                        target="_blank"
                        // onClick={() => {
                        //     window.location.assign("http://github.com");
                        // }}
                        className={classes.indexLink}
                        onClick={(event) => {
                            event.preventDefault();
                            window.open("http://91neg.bg/");
                        }}
                    >
                        Deutsche Abteilung
                    </Link>
                    <Link
                        to="http://91neg.bg/"
                        target="_blank"
                        // onClick={() => {
                        //     window.location.assign("http://github.com");
                        // }}
                        className={classes.indexLink}
                        onClick={(event) => {
                            event.preventDefault();
                            window.open("http://91neg.bg/");
                        }}
                    >
                        Deutsche Abteilung
                    </Link>
                    <Link
                        to="http://91neg.bg/"
                        target="_blank"
                        // onClick={() => {
                        //     window.location.assign("http://github.com");
                        // }}
                        className={classes.indexLink}
                        onClick={(event) => {
                            event.preventDefault();
                            window.open("http://91neg.bg/");
                        }}
                    >
                        Deutsche Abteilung
                    </Link> */}
                    {/* <span className={classes.indexLink}>
                        Deutsches Gymnasium
                    </span>
                    <span className={classes.indexLink}>DA Facebook</span>
                    <span className={classes.indexLink}>Kontakt</span> */}
                </div>
            </CardContent>
            {/* </CardActionArea> */}
        </Card>
    );
}

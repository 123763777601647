import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import sizes from "../styles/sizes";
import background_top_left_clean from "../images/background_top_left_clean.png";
import background_bottom from "../images/background_bottom.png";
import group_photo from "../images/group_photo.jpg";
import final from "../images/school_photos/final.JPG";
import finaledit from "../images/school_photos_compressed/finaledita.jpg";
import finaleditmobile from "../images/school_photos_compressed/finaleditmobile.jpg";
import IndexListe from "./IndexListe";
import { LanguageContext } from "../contexts/LanguageContext";
import homeContent from "../content/homeContent";

// maybe whole photo as wallpaper in the back
// ? remove margin right and left on phones so it fills up the whole screen

// make image break earlier so it fills the whole screena and does not expose its background
const useStyles = makeStyles({
    root: {
        // paddingRight: "17px !important",
        // border: "5px dotted red",
        // height: "100%",
        // imageSize: "cover",
        // backgroundImage: "url(" + pattern + ")",
        // zIndex: "10000",
        // fontFamily: "'Commissioner', serif",
        backgroundColor: "#f5f1e9",
        // backgroundColor: "rgba(227, 225, 222, 0.7)",
        // backgroundColor: "rgba(181, 255, 182,0.3)",

        // ==============================

        // backgroundImage: `url(${background_top_left_clean}), url(${background_bottom}), url(https://lh3.googleusercontent.com/proxy/t7bh24IbyKZS2sLjaINKKBqDk-K3L0ijfEtgzb-GZOIcqGBHdi0kiqLn5BWhzY92Z469uWSsQ77-bGn0AhUh0X-By5otXXm9l3xPg5KGPu3xvXOfT1UaKF7X7Qyfog)`, //"url(" + background_bottom + ")",
        // backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        // backgroundSize: "75%, 30%, 100%",
        // backgroundPosition: "top left, bottom right, center center",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            // backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`,
            // either stretch or cut(like now)
            // backgroundSize: "100%, 50vh, 35vw",
        },
        position: "absolute",

        //         background: 'rgb(123, 218, 84)',
        // background: 'linear-gradient(90deg, rgba(80,253,10,1) 17%, rgb(63, 182, 166) 92%)',
        // background: 'rgb(123,218,84)',
        // background: 'linear-gradient(180deg, rgba(123,218,84,1) 30%, rgba(63,182,166,1) 69%)',

        // width: "100%",

        // backgroundImage: `url(${pattern})`,
    },
    // NavBar: {
    //     position: "absolute",
    // },
    Grid: {
        fontFamily: "'Commissioner', serif",
        // border: '2px solid red',
        // marginTop: "calc(64px + 2rem)",
        width: "100%",
        padding: "0 2rem",
        [sizes.down("sm")]: {
            padding: "0",
            width: "100%",
            margin: 0,
            // border: "2px solid green",
        },
    },
    paper: {
        // border: "1px solid black",
        borderRadius: "0",
        padding: "2rem",
        height: "100%",
        // backgroundColor: "white",
        backgroundColor: "rgba(255,255,255,0.7)",
        [sizes.down("sm")]: {},
    },
    mainText: {
        fontSize: "2.5rem",
    },
    secondaryText: {
        fontSize: "1.25rem",
        padding: "3rem",
        [sizes.down("sm")]: {
            padding: "0rem",
        },
    },
    mainImg: {
        imageSize: "cover",
        width: "100%",
    },
    imgPaper: {
        // border: "1px solid black",
        borderRadius: "0",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    gridPaper: {
        // backgroud: "#eeffee",
        padding: "2rem",
        // border: "2px solid lightgreen",
        [sizes.down("sm")]: {
            padding: "2rem",
        },
    },
    gridItem: {
        marginBottom: "3rem",
        // borderBottom: "1px dashed black",
        [sizes.down("sm")]: {
            // padding: "0",
            // width: "100%",
            // border: "2px solid red",
            // margin: "0",
            // width: "100vw",
            // paddingTop: 0,
            // marginBottom: "3rem",
        },
    },
    pattern: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
        zIndex: -1000,
    },
    landing: {
        backgroundColor: "rgb(230,255,210)",
        height: "100vh",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom}), url(${finaledit})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "62.5%, 30%, cover",
        backgroundPosition: "top left, bottom right, center center",
        // 5064 x 2977

        height: "calc(100vw*2997/5064)",
        maxHeight: "100vh",
        [sizes.down("md")]: {},
        [sizes.down("sm")]: {
            marginTop: "64px",
        },
        [sizes.down("mobilelg")]: {
            backgroundImage: `url(${background_top_left_clean}), url(${background_bottom}), url(${finaleditmobile})`, //"url(" + background_bottom + ")",
        },
    },
    title: {
        backgroundColor: "rgba(255,255,255,0.8)",
        fontSize: "3.25rem",
        alignSelf: "flex-end",
        padding: "1rem",
        boxShadow: "17px -5px 18px -5px rgba(0,0,0,0.5)",
        boxShadow: "-1px -12px 18px -10px rgba(0,0,0,0.5)",
        [sizes.down("lg")]: {
            fontSize: "2.65rem",
        },
        [sizes.down("md")]: {
            display: "none",
        },
    },
    MDtitle: {
        display: "none",
        backgroundColor: "rgba(255,255,255,0.8)",
        fontSize: "3.25rem",
        fontFamily: "'Commissioner', serif",

        // alignSelf: "flex-end",
        padding: "1rem",
        // boxShadow: "17px -5px 18px -5px rgba(0,0,0,0.5)",
        // boxShadow: "-1px -12px 18px -10px rgba(0,0,0,0.5)",

        [sizes.down("md")]: {
            display: "block",
            paddingBottom: "1.5rem",
        },
        [sizes.down("sm")]: {
            marginTop: "0",
            paddingTop: "1rem",
            fontSize: "2.25rem",
            // fontWeight: "300",
        },
        [sizes.down("xs")]: {
            fontSize: "1.75rem",
            // fontWeight: "300",
        },
    },
});

// use order to make things reponsive on the other pages

export default function HomePage() {
    const classes = useStyles();

    const { language } = useContext(LanguageContext);

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            <section
                id="landingImage"
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    fontWeight: "light",
                    fontFamily: "'Commissioner', serif",
                    // color: 'black',
                    color: "rgb(50,50,50)",

                    // fontFamily: "'Bebas Neue', cursive",
                    // textTransform: "uppercase",
                }}
                // Landing page background image
                className={classes.landing}
            >
                <div
                    style={{
                        // border: "2px solid red",
                        display: "flex",
                        alignItems: "flex-end",
                    }}
                >
                    <span className={classes.title}>
                        {`${homeContent[language].title1} — ${homeContent[language].title2}`}
                    </span>
                </div>
            </section>
            <span className={classes.MDtitle}>
                <p style={{ margin: "0rem 0rem 0rem 0rem" }}>
                    {homeContent[language].title1}
                </p>{" "}
                <span>{homeContent[language].title2}</span>
            </span>
            <Grid
                style={{ border: "", marginTop: "3rem", marginBottom: 0 }}
                className={classes.Grid}
                container
                spacing={0}
            >
                <Grid className={classes.gridItem} item xs={12}>
                    <Paper
                        elevation={0}
                        // style={}
                        className={clsx(classes.paper, classes.gridPaper)}
                        style={{ padding: 0 }}
                    >
                        {/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! change to p and fix spacing  */}
                        <p
                            className={classes.secondaryText}
                            style={{
                                marginBottom: 0,
                                paddingBottom: "2.5rem",

                                marginTop: 0,
                            }}
                        >
                            {homeContent[language].message}

                            {/* <p style={{ marginBottom: "0rem" }}>
                                Eine Aufstellung eines Teils unserer
                                Erkenntnisse erfolgt hier:
                            </p> */}
                        </p>
                    </Paper>
                </Grid>

                {/* to be added */}
                {/* <Grid className={classes.gridItem} item xs={12}>
                    <Paper
                        elevation={0}
                        className={clsx(classes.paper, classes.gridPaper)}
                    >
                        <Typography
                            style={{
                                fontFamily: "'Commissioner', serif",
                            }}
                            variant="h4"
                            gutterBottom
                        >
                            Erste Erkenntnis
                        </Typography>
                    </Paper>
                </Grid>
                <Grid className={classes.gridItem} item xs={12}>
                    <Paper
                        elevation={0}
                        className={clsx(classes.paper, classes.gridPaper)}
                    >
                        <Typography
                            style={{
                                fontFamily: "'Commissioner', serif",
                            }}
                            variant="h4"
                            gutterBottom
                        >
                            Zweite Erkenntnis
                        </Typography>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} style={{ paddingBottom: "2rem" }}>
                    <IndexListe></IndexListe>
                </Grid>
            </Grid>
        </div>
    );
}

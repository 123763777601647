import React, { useContext, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";

import { LanguageContext } from "../contexts/LanguageContext";
import sizes from "../styles/sizes";

const StyledMenu = withStyles({
    paper: {
        borderRadius: "0",
        border: "1px solid #d3d4d5",
        // display: "flex",
        [sizes.down("sm")]: {
            // display: "flex",
            border: "0px",
            // border: "1px solid #ff0000",
            width: "150px",
        },
        // padding: 0,
    },
})((props) => (
    <Menu
        style={{ display: "flex" }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: `${props.mobile ? "center" : "bottom"}`,
            horizontal: `${props.mobile ? "right" : "center"}`,
        }}
        transformOrigin={{
            vertical: `${props.mobile ? "right" : "top"}`,
            horizontal: `${props.mobile ? "left" : "center"}`,
        }}
        {...props}
    />
));

// MAYBE STOP PROPAGATION SOMEWHERE

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.secondary,
            // !1! Maybe use this for the thing where the image nudges no right when dropdown is open
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
        [sizes.down("sm")]: {
            // border: "2px solid blue",
            // padding: "3px",
            float: "left",
            padding: "0rem !important",
        },
    },
}))(MenuItem);

const content = {
    bulgarian: {
        img:
            "https://da-galabov.eu/wp-content/themes/dabteilung0010/img/da-bg-flag.gif",
    },
    english: {
        img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_the_United_Kingdom_%282-3%29.svg/1280px-Flag_of_the_United_Kingdom_%282-3%29.svg.png",
    },
    german: {
        img:
            "https://da-galabov.eu/wp-content/themes/dabteilung0010/img/da-de-flag.gif",
    },
};

const styles = {
    img: {
        height: "28px",
        [sizes.down("sm")]: {
            margin: 0,
            padding: 0,
        },
    },
    styledMenuItem: {
        [sizes.down("sm")]: {
            marginLeft: "0.5rem",
        },
    },
};

function CustomizedMenus(props) {
    const { language, changeLanguage } = useContext(LanguageContext);
    const languageEl = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { classes, mobile } = props;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget);
        console.log(anchorEl);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const { img } = content[language];

    return (
        <div
            style={{
                // border: "2px solid blue",
                display: "flex",
            }}
        >
            <span
                style={{
                    // border: "2px solid red",
                    height: "100%",
                }}
                onClick={handleClick}
            >
                {!mobile && (
                    <ExpandMoreSharpIcon
                        ref={languageEl}
                        className={classes.downIcon}
                        fontSize="medium"
                    ></ExpandMoreSharpIcon>
                )}
                <img className={classes.img} src={img} alt="" />
            </span>
            {/* </div> */}
            <StyledMenu
                //passing mobile prop from the componenet props to this component, which accepts it when declared and therefore mobile here is true when it is also true when the mobile passed down as a prop to the languagemenut itself is true
                mobile={mobile}
                style={{ zIndex: 1600 }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.keys(content).map(
                    (lang) =>
                        lang !== language && (
                            // make it conditional to margintop: 0.5rem when on pc
                            <StyledMenuItem className={classes.styledMenuItem}>
                                <span
                                    onClick={() => {
                                        changeLanguage(lang);
                                        handleClose();
                                    }}
                                >
                                    <img
                                        className={classes.img}
                                        src={content[lang].img}
                                        alt=""
                                    />
                                </span>
                            </StyledMenuItem>
                        )
                )}
            </StyledMenu>
        </div>
    );
}
export default withStyles(styles)(CustomizedMenus);

const dropdownContent = {
    bulgarian: {
        first: "Българска образователна система",
        second: "Галерия",
        third: "Архив",
        fourth: "История на проекта",
    },
    english: {
        first: "Bulgarian school system",
        second: "Gallery",
        third: "Archive",
        fourth: "History of the project",
    },
    german: {
        first: "Bulgarisches Schulsystem",
        second: "Bildergalerie",
        third: "Archiv Dokumente",
        fourth: "Geschichte des Projekts",
    },
};
export default dropdownContent;

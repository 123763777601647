import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import background_top_left_mobile from "../images/background_top_left_mobile.png";
import background_bottom from "../images/background_bottom.png";
import background_top_left_clean from "../images/background_top_left_clean.png";
import sizes from "../styles/sizes";
import Grid from "@material-ui/core/Grid";
import IndexListe from "./IndexListe";
import img_2a from "../images/history_photos/2a.JPG";
import img_2b from "../images/history_photos/2b.JPG";
import img_1 from "../images/history_photos/Bild_1.jpg";
import img_3 from "../images/history_photos/Bild_3.jpg";
import img_4 from "../images/history_photos/Bild_4.jpg";
import img_5 from "../images/history_photos/Bild_5.jpg";
import { LanguageContext } from "../contexts/LanguageContext";
import historyContent from "../content/historyContent";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#f5f1e9",
        // backgroundColor: "rgba(227, 225, 222, 0.7)",
        // backgroundColor: "rgba(181, 255, 182,0.3)",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "100%, 30%",
        [sizes.down("xl")]: {
            backgroundSize: "100%, 37.5%",
        },
        fontFamily: "'Commissioner', serif",

        backgroundPosition: "top, bottom right",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "100%, 75%",
            padding: "0",
            margin: "0",
        },
        paddingRight: "0",
        // border: "2px solid blue",
        // height: "100vh",
    },
    Grid: {
        // marginTop: "64px",
        padding: "1rem 1rem",
        paddingTop: "calc(64px + 1rem)",
        width: "100%",
        // border: "2px solid red",
        marginRight: "0",
        margin: "0",
        fontFamily: "'Commissioner', serif",

        // alignItems: "stretch",
    },

    title: {
        maxWidth: "100%",
        borderRadius: "0",
        backgroundColor: "rgba(255,255,255,0.9)",
        fontSize: "3.5rem",
        padding: "1rem 0",
        boxShadow: "none",
        fontFamily: "'Commissioner', serif",
        [sizes.down("lg")]: {
            fontSize: "2.5rem",
        },
        [sizes.down("sm")]: {
            fontSize: "1.75rem",
        },
        [sizes.down("xs")]: {
            fontSize: "1.5rem",
            padding: "0.5rem 0.5rem",
            // width: "100%",
        },

        // boxSizing: "border-box",
        // border: "2px solid black",
        // display: "flex",
        // justifyContent: "space-around",
        // // border: "1px solid black",
        // height: "auto",
        // [sizes.down("sm")]: {
        //     display: "flex",
        //     flexFlow: "column",
        //     alignItems: "center",
        //     justifyContent: "center",
        // },
        //has pasdding
        // padding: "0.5rem",
    },
    textBox: {
        padding: "1.5rem 3rem",
        fontFamily: "'Commissioner', serif",
        backgroundColor: "rgba(255,255,255,0.9)",
        fontSize: "1.25rem",

        display: "inline-block",
    },
    imageText: {
        fontSize: "1.5rem",
        padding: "0 3rem",
        fontFamily: "'Commissioner', serif",
        [sizes.down("sm")]: {
            fontSize: "1.25rem",
        },
    },
    imageContainer: {
        // border: "2px solid red",
        // border: "10px solid blue",
        // display: "none",
        display: "flex",
        [sizes.down("lg")]: {
            flexFlow: "column",
        },
    },
    image: {
        width: "60%",
        [sizes.down("lg")]: {
            width: "100%",
        },
    },
    image2: {
        width: "50%",
        [sizes.down("lg")]: {
            width: "100%",
        },
    },
    doubleImageDiv: {
        [sizes.down("lg")]: {
            display: "flex",
            flexFlow: "column",
        },
    },
    // GridEl: {
    //     [sizes.down("xs")]: {
    //         width: "100%",
    //     },
    // },
}));
export default function HistoryPage() {
    const classes = useStyles();

    const { language } = useContext(LanguageContext);

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            <Grid
                className={classes.Grid}
                style={{
                    /*paddingTop: "calc(64px + 1rem)",*/ margin: "0 0 0 0",
                }}
                container
                // alignItems="stretch"
                spacing={3}
            >
                <Grid className={classes.GridEl} item xs={12}>
                    <div className={classes.title}>
                        {historyContent[language][16].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        // className={classes.imageContainer}
                        style={{
                            display: "flex",
                            // flexFlow: "column",
                            // border: "2px solid red",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0",
                            // width: "90vw",
                            width: "100%",
                            // paddingRight: "4rem",
                        }}
                        className={`${classes.textBox} ${classes.imageContainer}`}
                    >
                        <img className={classes.image} src={img_1} alt="" />
                        <div className={classes.imageText}>
                            <p style={{ marginBottom: "2rem" }}>
                                {historyContent[language][17].text}
                            </p>
                            <p>{historyContent[language][18].text}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][0].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][1].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][2].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][3].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][4].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][5].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][6].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][7].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        className={classes.textBox}
                        // className={`${classes.textBox} ${classes.imageContainer}`}
                        style={{
                            display: "flex",
                            flexFlow: "column",
                            padding: 0,
                            paddingBottom: "0.5rem",
                            // paddingTop: "0",
                        }}
                    >
                        <div
                            className={classes.doubleImageDiv}
                            style={{
                                display: "flex",
                                width: "100%",
                                // border: "2px solid blue",
                                paddingBottom: "0.75rem",
                            }}
                        >
                            <img
                                className={classes.image2}
                                src={img_2a}
                                alt=""
                            />
                            <img
                                className={classes.image2}
                                src={img_2b}
                                alt=""
                            />
                        </div>
                        <p
                            className={classes.imageText}
                            style={{ padding: "0.5rem", paddingTop: "0.2rem" }}
                        >
                            {historyContent[language][19].text}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][8].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][9].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                        // className={classes.textBox}
                        className={`${classes.textBox} ${classes.imageContainer}`}
                    >
                        <p
                            className={classes.imageText}
                            style={{ padding: "0 3rem" }}
                        >
                            {historyContent[language][20].text}
                        </p>
                        <img className={classes.image} src={img_3} alt="" />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox} style={{ columnCount: 1 }}>
                        {historyContent[language][15].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            display: "flex",
                            padding: "0",
                            alignItems: "center",
                        }}
                        // className={classes.textBox}
                        className={`${classes.textBox} ${classes.imageContainer}`}
                    >
                        <img className={classes.image} src={img_4} alt="" />
                        <p
                            style={{
                                padding: 0,
                                padding: "0 3rem",
                                // fontSize: "1.5rem",
                            }}
                            className={classes.imageText}
                        >
                            {historyContent[language][21].text}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][10].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {/* <div
                        style={{
                            display: "flex",
                            padding: "0",
                            alignItems: "center",
                        }}
                        // className={classes.textBox}
                        className={`${classes.textBox} ${classes.imageContainer}`}
                    > */}
                    <div
                        style={{
                            display: "flex",
                            padding: "0",

                            alignItems: "center",
                            backgroundColor: "rgba(255,255,255,0.9)",
                        }}
                        // className={classes.textBox}
                        className={`${classes.textBox} ${classes.imageContainer}`}
                    >
                        <p
                            style={{
                                padding: 0,
                                // padding: "3rem",
                                padding: "0 3rem",
                                // fontSize: "1.5rem",
                                // backgroundColor: "rgba(255,255,255,0.9)",
                            }}
                            className={classes.imageText}
                            // className={classes.title}
                        >
                            {historyContent[language][22].text}
                        </p>
                        <img className={classes.image} src={img_5} alt="" />
                    </div>
                    {/* </div> */}
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][11].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][12].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][13].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textBox}>
                        {historyContent[language][14].text}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <IndexListe></IndexListe>
                </Grid>
            </Grid>
        </div>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import VideoPlayer from "./VideoPlayer";
import sizes from "../styles/sizes";
// import { Player } from "video-react";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "64px",
        // marginTop: "64px",
        backgroundColor: "#f5f6ef",
        // overflow: "scroll",
        minHeight: "100vh",
        // height: "auto",
        // [sizes.down("fift")]: {
        //     paddingTop: "calc(64px + 2rem)",
        // },
        // [sizes.down("lg")]: {
        //     paddingTop: "calc(64px + 1rem)",
        // },
        // [sizes.down("md")]: {
        //     paddingTop: "64px",
        // },
    },
}));

export default function InterviewsPage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            {/* <NavbarInterviews></NavbarInterviews> */}
            <VideoPlayer></VideoPlayer>
        </div>
    );
}

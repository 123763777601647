import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import nineone from "../images/nineone.png";
import erinnern from "../images/erinnern.png";
import DropDown from "./DropDown";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Drawer from "./Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import LanguageMenu from "./LanguageMenu";
import { LanguageContext } from "../contexts/LanguageContext";
import useStyles from "../styles/NavbarStyles";
import navbarContent from "../content/navbarContent";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

//

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
            get: function () {
                supportsPassive = true;
            },
        })
    );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
function disableScroll() {
    window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
    window.removeEventListener("DOMMouseScroll", preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener("touchmove", preventDefault, wheelOpt);
    window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}

const disableScrolling = () => {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
        window.scrollTo(x, y);
    };
};

const enableScrolling = () => {
    window.onscroll = function () {};
};

export default function Navbar(props) {
    const classes = useStyles();
    const { language } = useContext(LanguageContext);

    const { home, history, interviews, about, contact } = navbarContent[
        language
    ];

    const [anchorEl, setAnchorEl] = React.useState(null);
    // Anchor element of the language menu
    const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        // console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        // enableScrolling();
        enableScroll();
    };
    const handleLanguageClick = (event) => {
        console.log(anchorEl);
        console.log(event.currentTarget);
        setLanguageAnchorEl(event.currentTarget);
        console.log("from handleLanguageClick in navbar");
    };

    const handleLanguageClose = () => {
        setLanguageAnchorEl(null);
        console.log("from handleLanguageClose in navbar");
    };

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (diretion, boolean) => (event) => {
        console.log("state:::::::::::", state.left);
        // state.left = false ? disableScroll() : enableScroll();
        // if (state.left) {
        //     enableScroll();
        // } else {
        //     disableScroll();
        // }
        state.left ? enableScroll() : disableScroll();
        // console.log("boolean::::::::::::::", boolean);
        if (event) {
            event.stopPropagation();
        }
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, left: !state.left });
    };

    const trigger = useScrollTrigger({
        target: window ? window : undefined,
    });
    // console.log(trigger);

    // get current width of window
    var w = window.innerWidth;
    // console.log(w);

    const History = useHistory();

    return (
        <>
            <CssBaseline />
            {/* Navbar is constantly in when width is over 766.98px; when lower: it hides on scroll */}
            <Slide in={w > 766.98 ? true : !trigger}>
                <AppBar
                    style={{ boxSizing: "border-box" }}
                    className={`${classes.NavBar} mui-fixed`}
                    color="transparent"
                    elevation={4}
                    position="fixed"
                >
                    <Toolbar style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <div className={classes.navLinks}>
                            <Button
                                size="large"
                                className={classes.NavMenu}
                                onClick={toggleDrawer("left", true)}
                            >
                                <MenuIcon></MenuIcon>
                            </Button>
                            <Drawer
                                className={classes.Drawer}
                                state={state}
                                toggleDrawer={toggleDrawer}
                                setState={setState}
                                elevation={0}
                            ></Drawer>
                            <Link
                                style={{ marginLeft: "1rem" }}
                                className={classes.navLink}
                                href="/"
                                underline="none"
                            >
                                {home}
                            </Link>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(e);
                                    // disableScrolling();
                                    disableScroll();
                                }}
                            >
                                <ExpandMoreSharpIcon
                                    className={classes.downIcon}
                                    fontSize="medium"
                                ></ExpandMoreSharpIcon>
                                <Link
                                    className={classes.navLink}
                                    href=""
                                    underline="none"
                                >
                                    {history}
                                </Link>
                            </span>

                            <Link
                                className={classes.navLink}
                                // href="/Interviews"
                                onClick={() => {
                                    History.push("/Interviews");
                                }}
                                underline="none"
                            >
                                {interviews}
                            </Link>
                            <Link
                                className={classes.navLink}
                                // href="/about"
                                onClick={() => {
                                    History.push("/about");
                                }}
                                underline="none"
                            >
                                {about}
                            </Link>
                            <Link
                                className={classes.navLink}
                                href="/contact"
                                underline="none"
                                onClick={() => {
                                    History.push("/contact");
                                }}
                            >
                                {contact}
                            </Link>

                            <DropDown
                                handleClick={handleClick}
                                handleClose={handleClose}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            ></DropDown>
                            {/* <div style={{ marginLeft: "1rem" }}>language</div> */}
                            <span
                                className={classes.languageSpan}
                                style={{
                                    // border: "3px solid black",
                                    marginLeft: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                {/* <ExpandMoreSharpIcon
                                    className={classes.downIcon}
                                    fontSize="medium"
                                ></ExpandMoreSharpIcon> */}
                                <LanguageMenu
                                    className={classes.LanguageMenu}
                                    handleClick={handleLanguageClick}
                                    handleClose={handleLanguageClose}
                                    anchorEl={languageAnchorEl}
                                    setAnchorEl={setLanguageAnchorEl}
                                ></LanguageMenu>
                            </span>

                            <img
                                onClick={() => {
                                    History.push("/");
                                }}
                                className={`${classes.navImg} ${classes.firstNavImg}`}
                                src={nineone}
                                alt=""
                            />
                            <img
                                onClick={() => {
                                    History.push("/");
                                }}
                                className={classes.navImg}
                                src={erinnern}
                                alt=""
                            />
                        </div>
                    </Toolbar>
                </AppBar>
            </Slide>
        </>
    );
}

//  A helper method to easily create meida queries so we don't have to repeat ourselves every time in the jss styles
export default {
    down(size) {
        const sizes = {
            us: "359.98px",
            mobilelg: "425.98px",
            xs: "575.98px",
            sm: "767.98px",
            nav820: "969.98px",
            md: "991.98px",
            lg: "1199.98px",
            lger: "1249.98px",
            fift: "1499.98px",
            xl: "1599.98px",
        };
        return `@media (max-width:${sizes[size]})`;
    },
};

import React, { createContext } from "react";
import useLocalStorageState from "../hooks/useLocalStorageState";

export const LanguageContext = createContext();

export function LanguageProvider(props) {
    const [language, setLanguage] = useLocalStorageState("language", "german");

    const changeLanguage = (lang) => {
        setLanguage(lang);
    };
    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {props.children}
        </LanguageContext.Provider>
    );
}

//     // defining a higher order method to inject a prop with the value of the value returned from the languageContext
//     export const withLanguageContext = (Component) => (props) => (
//         // returns the same component but with the prop(with the value of the languageContext) injected
//         <LanguageContext.Consumer>
//             {/* returns the component with the original props, but also with the languageContext prop,
//             which has the value of the context */}
//             {(value) => <Component languageContext={value} {...props}></Component>}
//         </LanguageContext.Consumer>
//     );

import React, { useState, useContext } from "react";

import ReactPlayer from "react-player";
import { withStyles } from "@material-ui/core/styles";
import "../styles/player.css";
import VideoTabs from "./VideoTabs";
import allVids from "../content/allVids.js";
import HorizontalVideoTabs from "./HorizontalVideoTabs";
import sizes from "../styles/sizes";
import background_top_left_clean from "../images/background_top_left_clean.png";
import background_bottom from "../images/background_bottom.png";

import { LanguageContext } from "../contexts/LanguageContext";

const styles = {
    root: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        border: "0px dashed red",
        flexFlow: "column",
        // height: "100vh",
        [sizes.down("fift")]: {
            // paddingTop: "calc(64px)",
        },
        position: "absolute",
    },
    horizontalTabs: {},
};

function VideoPlayer(props) {
    // the user should be able to set this through some UI
    // const [video, setVideo] = useState(videos[0].url);
    const { classes } = props;

    // get language from context
    const { language } = useContext(LanguageContext);

    const [video, setVideo] = useState(allVids[language][0].url);
    const [description, setDescription] = useState(
        allVids[language][0].description
    );
    const [title, setTitle] = useState(allVids[language][0].title);
    const [tabTitle, setTabTitle] = useState(allVids[language][0].tabTitle);

    const width = 1920 * 0.65;
    const height = (width * 9) / 16;

    const playerWidth = window.innerWidth < 1500 ? window.innerWidth : width;
    const playerHeight =
        window.innerWidth < 1500 ? window.innerWidth * (9 / 16) : height;

    // try useeffect to change to set description and title on language change

    return (
        <div className={classes.root}>
            <div style={{ width: "100%", display: "flex" }}>
                <ReactPlayer
                    // height={height}
                    // width={width}
                    height={playerHeight}
                    width={playerWidth}
                    controls
                    url={video}
                ></ReactPlayer>
                <VideoTabs
                    className={classes.videoTabs}
                    setVideo={setVideo}
                    setTitle={setTitle}
                    setDescription={setDescription}
                    videos={allVids[language]}
                    // height={height}
                    height={playerHeight}
                    // width={width}
                ></VideoTabs>
            </div>
            <HorizontalVideoTabs
                className={classes.horizontalTabs}
                setVideo={setVideo}
                setTitle={setTitle}
                setDescription={setDescription}
                setTabTitle={setTabTitle}
                videos={allVids[language]}
                // height={height}
                height={playerHeight}
                // width={width}
            ></HorizontalVideoTabs>
            <section
                style={{
                    paddingTop: "1rem",
                    display: "flex",
                    // justifyContent: "left",
                    alignItems: "start",
                    paddingLeft: "3rem",
                    flexFlow: "column",
                    paddingRight: "3rem",
                    paddingBottom: "2rem",
                    // border: "2px solid red",
                    margin: "0",
                    // minHeight: "12rem",
                    height: "100%",
                    flexGrow: "1",
                    backgroundColor: "#f5f6ef",
                }}
            >
                <h1
                    style={{
                        margin: "0.5rem 0",
                        fontFamily: "'Commissioner', serif",
                    }}
                >
                    {title}
                </h1>
                <p
                    style={{
                        margin: "0.5rem 0",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontFamily: "'Commissioner', serif",
                    }}
                >
                    {description}
                </p>
            </section>
        </div>
    );
}

export default withStyles(styles)(VideoPlayer);

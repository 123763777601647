const homeContent = {
    bulgarian: {
        title1: "Училището през социализма",
        title2: "свидетелите си спомнят",
        message:
            'Добре дошли! Ние сме клубът по история на 91-ва Немска езикова гимназия "проф. Константин Гълъбов" София и на тази страница сме събрали резултатите от нашата работа. Основната ни цел беше да разберем повече за миналото на училището и страната ни. Проектът е част от конкурса „Erinnern für die Gegenwart“. Свидетелите, които интевюирахме, и всички, които ни съдействаха, улесниха работата, като ни подкрепяха през целия път. Благодарим ви много! Натрупания опит ще използваме не само за да разберем по-добре миналото, но и да отразим наученото в настоящето.',
    },
    english: {
        title1: "School under Socialism ",
        title2: "Contemporary Witnesses Remember",
        message:
            "Welcome! We are the History-AG of the Galabov high school Sofia and on this page we would like to introduce you to our project and present the results of our work. Our goal was to find a way to find out more about our school's and our country's past as part of the \"Erinnern für die Gegenwart\" contest. Our research and interview partners made this project easy for us because so many people willingly supported us. Many thanks for that! We want to use the experiences we have gathered not only to become more aware of the past, but also to be able to better reflect on our present.",
    },
    german: {
        title1: "Schule im Sozialismus",
        title2: "Zeitzeugen erinnern sich",
        message:
            'Herzlich willkommen! Wir sind die Geschichts-AG des Galabov Gymnasiums Sofia und wollen euch auf dieser Seite unser Projekt vorstellen und die Ergebnisse unserer Arbeit präsentieren. Unser Ziel war es, im Rahmen des Projekte-Wettbewerbs "Erinnern für die Gegenwart" eine Möglichkeit zu finden, mehr über die Vergangenheit unserer Schule und unseres Landes herauszufinden. Unsere Recherchen und Interviewpartener haben uns dieses Vorhaben leicht gemacht, da uns so viele Menschen bereitwillig unterstützten. Vielen Dank dafür! Die gesammelten Erfahrungen wollen wir nicht nur nutzen, um uns der Vergangenheit bewusster zu werden, sondern auch unsere Gegenwart besser reflektieren zu können.',
    },
};

export default homeContent;

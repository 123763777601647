import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import sizes from "../styles/sizes";
import { useHistory } from "react-router-dom";
import NestedList from "./NestedList";
import LanguageMenu from "./LanguageMenu";
import { LanguageContext } from "../contexts/LanguageContext";
import navbarContent from "../content/navbarContent";

const useStyles = makeStyles({
    list: {
        width: 250,
        marginTop: "64px",
        // border: "2px solid green",
        fontFamily: "'Commissioner', serif",
    },
    fullList: {
        width: "auto",
        fontFamily: "'Commissioner', serif",
    },
    navLink: {
        marginRight: "1rem",
        fontWeight: "bold",
        [sizes.down("sm")]: {
            display: "none",
        },
        fontFamily: "'Commissioner', serif",
    },
    drawerLink: {
        fontSize: "1rem",
    },
});

// maybe u can import that from the dropdown content later
const geschichteContent = {
    bulgarian: {
        first: "[BG]Bulgarische Schulsystem",
        second: "[BG]KL Schulsystem",
        third: "[BG]Archiv Dokumente",
    },
    english: {
        first: "[ENG]Bulgarische Schulsystem",
        second: "[ENG]KL Schulsystem",
        third: "[ENG]Archiv Dokumente",
    },
    german: {
        first: "[DE]Bulgarische Schulsystem",
        second: "[DE]KL Schulsystem",
        third: "[DE]Archiv Dokumente",
    },
};

// var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

// function preventDefault(e) {
//     e.preventDefault();
// }

// function preventDefaultForScrollKeys(e) {
//     if (keys[e.keyCode]) {
//         preventDefault(e);
//         return false;
//     }
// }

// // modern Chrome requires { passive: false } when adding event
// var supportsPassive = false;
// try {
//     window.addEventListener(
//         "test",
//         null,
//         Object.defineProperty({}, "passive", {
//             get: function () {
//                 supportsPassive = true;
//             },
//         })
//     );
// } catch (e) {}

// var wheelOpt = supportsPassive ? { passive: false } : false;
// var wheelEvent =
//     "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// // call this to Disable
// function disableScroll() {
//     window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
//     window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
//     window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
//     window.addEventListener("keydown", preventDefaultForScrollKeys, false);
// }

// // call this to Enable
// function enableScroll() {
//     window.removeEventListener("DOMMouseScroll", preventDefault, false);
//     window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
//     window.removeEventListener("touchmove", preventDefault, wheelOpt);
//     window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
// }

// FIX HISTORY

export default function SwipeableTemporaryDrawer({
    state,
    //setState,
    toggleDrawer,
}) {
    const classes = useStyles();
    const History = useHistory();

    // add either a function or a state piece to determine whether the user has clicked ont he geschichte button
    // and from there decide if the drawer should close
    const { language /* changeLanguage */ } = useContext(LanguageContext);
    // const { /*home, History,*/ interviews, about } = navbarContent[language];

    const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);

    const { home, history, interviews, about, contact } = navbarContent[
        language
    ];

    const handleLanguageClick = (event) => {
        console.log(event.currentTarget);
        setLanguageAnchorEl(event.currentTarget);
    };

    const handleLanguageClose = () => {
        setLanguageAnchorEl(null);
    };

    const list = (anchor) => (
        <div
            style={{ /*border: "2px solid green",*/ height: "100%" }}
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            // alter this so it closes on everything but the geschichte
            onClick={toggleDrawer("left", false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List
                style={{
                    // border: "2px dashed red",
                    height: "100%",
                    display: "flex",
                    flexFlow: "column",
                    fontFamily: "'Commissioner', serif",
                    alignItems: "space-between",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        /*border: "2px dashed blue",*/ height: "auto",
                        fontFamily: "'Commissioner', serif",
                    }}
                >
                    <ListItem
                        button
                        // key={text}
                        onClick={() => History.push("/")}
                    >
                        {/* <ListItemText
                            style={{
                                fontFamily: "'Commissioner', serif",
                            }}
                            primary={"Home"}
                        /> */}
                        <span className={classes.drawerLink}>{home}</span>
                    </ListItem>
                    <NestedList
                        // historyy={`${History}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log("clicked");
                        }}
                    ></NestedList>

                    <ListItem
                        button
                        // key={text}
                        // onClick={() => redirect(index)}
                        onClick={() => History.push("/Interviews")}
                    >
                        {/* <ListItemText primary={`${interviews}`} /> */}
                        <span className={classes.drawerLink}>{interviews}</span>
                    </ListItem>
                    <ListItem
                        button
                        style={{ paddingTop: "1rem" }}
                        // key={text}
                        onClick={() => History.push("/about")}
                    >
                        {/* <ListItemText primary={`${about}`} s/> */}
                        <span className={classes.drawerLink}>{about}</span>
                    </ListItem>
                    <ListItem
                        button
                        style={{ paddingTop: "1rem" }}
                        // key={text}
                        onClick={() => History.push("/contact")}
                    >
                        {/* <ListItemText primary={`${about}`} s/> */}
                        <span className={classes.drawerLink}>{contact}</span>
                    </ListItem>
                </div>
                <div style={{ marginBottom: "0.25rem" }}>
                    <ListItem
                        // style={{ marginTop: "auto" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log("clicked");
                        }}
                    >
                        <LanguageMenu
                            mobile={true}
                            onCLick={(e) => e.stopPropagation()}
                            handleClick={handleLanguageClick}
                            handleClose={handleLanguageClose}
                            anchorEl={languageAnchorEl}
                            setAnchorEl={setLanguageAnchorEl}
                        ></LanguageMenu>
                    </ListItem>
                </div>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <React.Fragment key={"left"}>
                <SwipeableDrawer
                    anchor={"left"}
                    open={state["left"]}
                    onClose={
                        // add logic to check if the user clicked the GEschichte button
                        // console.log("closing...")
                        toggleDrawer("left", false)
                    }
                    onOpen={toggleDrawer("left", true)}
                >
                    {list("left")}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}

const galleryContent = {
    bulgarian: [
        "Обучение за архивите при г-жа Велинова",
        'Клубът по история посещават "Червеният апартамент" 2019',
        "Свидетелката Анна Цанева",
        "Ученическа лекция в клуба по история",
    ],
    english: [
        "Briefing about the archive by Mrs. Velinova",
        'The history club visits the "Red Flat" 2019',
        "Witness Anna Tsaneva",
        "History Club Student Lecture",
    ],
    german: [
        "Archiv-Einweisung Frau Velinova",
        "Red Flat Geschichts-AG 2019",
        "Zeitzeugin Anna Tsaneva",
        "AG Schülervortrag",
    ],
};

export default galleryContent;

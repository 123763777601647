import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import sizes from "../styles/sizes";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { isWidthUp } from "@material-ui/core";
import { LanguageContext } from "../contexts/LanguageContext";
import videoTabsContent from "../content/videoTabsContent";

// context

// if you have to instead of setting desc and setting title set the current video index from here so
// we have access to it in the VideoPlayer component and we can therefrom derive what video we want to show LIVE(on language change) -- make the description there depend on the language and the index of the video that we set from here

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const { language } = useContext(LanguageContext);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        // border: "2px solid blue",
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",

        height: 224,
        [sizes.down("fift")]: {
            display: "none",
        },
        "@global": {
            "span.MuiTab-wrapper": {
                //   WebkitFontSmoothing: 'auto',
                textAlign: "center",
            },
        },
        // border: '2px solid red'
    },
    tabs: {
        // flexGrow: 4,
        // border: "2px solid red",
        width: "100%",
        borderRight: `1px solid ${theme.palette.divider}`,
        fontFamily: "'Commissioner', serif",
    },
    tabVidTitle: {
        color: "rgb(23,23,23)",
        fontSize: "1rem",
        // border: "2px solid red",
        margin: "0rem 0",
        fontFamily: "'Commissioner', serif",
        textTransform: "uppercase",
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: "0.75rem 0",
        // color: "red",
    },
}));

export default function VerticalTabs({
    // width,
    height,
    videos,
    setVideo,
    setTitle,
    setDescription,
}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { language } = useContext(LanguageContext);

    const {
        bulgarischeVideos,
        bulgarischeVideosWithSub,
        deutscheVideos,
    } = videoTabsContent[language];

    // size of player depending on screen

    return (
        <div
            className={classes.root}
            style={{
                height: height,
            }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                // style={{ width: "100%" }}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
                // style={{ width: "100%", border: "2px solid blue" }}
            >
                <h2 className={classes.tabVidTitle}>{bulgarischeVideos}</h2>
                {videos
                    .filter((v) => v.language == "BG")
                    .map((v) => (
                        <Tab
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                fontSize: "0.9rem",
                                width: "100%",
                                maxWidth: "100%",
                                textAlign: "center",

                                // border: "1px SOLID red",
                            }}
                            label={v.title}
                            onClick={() => {
                                console.log("setting video to::::", v.url);
                                setVideo(v.url);
                                setTitle(v.title);
                                setDescription(v.description);
                                console.log(v);
                                // setVideoObj(v);
                            }}
                            {...a11yProps(v.index)}
                        ></Tab>
                    ))}
                <h2 className={classes.tabVidTitle}>
                    {bulgarischeVideosWithSub}
                </h2>
                {videos
                    .filter((v) => v.language == "BG-SUB")
                    .map((v) => (
                        <Tab
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                fontSize: "0.9rem",
                                width: "100%",
                                maxWidth: "100%",
                                textAlign: "center",

                                // border: "1px SOLID red",
                            }}
                            label={v.title}
                            onClick={() => {
                                console.log("setting video to::::", v.url);
                                setVideo(v.url);
                                setTitle(v.title);
                                setDescription(v.description);
                                console.log(v);
                                // setVideoObj(v);
                            }}
                            {...a11yProps(v.index)}
                        ></Tab>
                    ))}
                <h2 className={classes.tabVidTitle}>{deutscheVideos}</h2>
                {videos
                    .filter((v) => v.language == "DE")
                    .map((v) => (
                        <Tab
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "left",
                                fontSize: "0.9rem",
                                width: "100%",
                                maxWidth: "100%",
                                textAlign: "left",
                                fontFamily: "'Commissioner', serif",

                                // border: "1px solid red",
                            }}
                            label={v.title}
                            onClick={() => {
                                console.log("setting video to::::", v.url);
                                setVideo(v.url);
                                setTitle(v.title);
                                setDescription(v.description);
                                console.log(v);
                                // setVideoObj(v);
                            }}
                            {...a11yProps(v.index)}
                        ></Tab>
                    ))}
            </Tabs>
            {/* {videos.map((v) => (
                <TabPanel
                    style={{ flexGrow: "5" }}
                    value={value}
                    index={v.index}
                >
                    {v.description}
                </TabPanel>
            ))} */}
            {/* <TabPanel value={value} index={0}>
                Item One
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel> */}
        </div>
    );
}

// {videos.map((v) => (
//     <Tab
//         style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//         }}
//         label={v.title}
//         onClick={() => setVideo(v.url)}
//     ></Tab>
// ))}

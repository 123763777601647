const navbarContent = {
    bulgarian: {
        home: "Начало",
        history: "История",
        interviews: "Интервюта",
        about: "За нас",
        contact: "Контакти",
    },
    english: {
        home: "Home",
        history: "History",
        interviews: "Interviews",
        about: "About us",
        contact: "Contact us",
    },
    german: {
        home: "Home",
        history: "Geschichte",
        interviews: "Interviews",
        about: "Über uns",
        contact: "Kontakt",
    },
};
export default navbarContent;

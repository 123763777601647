import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import docs from "../content/pdfDocs";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { LanguageContext } from "../contexts/LanguageContext";

import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import img_20_g_neg from "../images/pdf_thumbnails/20_g_neg.png";
import img_ae3 from "../images/pdf_thumbnails/ae3.png";
import img_intro from "../images/pdf_thumbnails/intro.png";

import carousel_images from "../content/carouselImages";
import sizes from "../styles/sizes";
import IndexListe from "./IndexListe";

import archiveContent from "../content/archiveContent";

import background_top_left_mobile from "../images/background_top_left_mobile.png";
import background_top_left_clean from "../images/background_top_left_clean.png";
import background_left from "../images/background_left.png";
import background_bottom from "../images/background_bottom.png";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "100vw",
        backgroundColor: "#f5f1e9",
        // minHeight: "100vh",
        "@global": {
            "div.thumbs-wrapper": {
                display: "none",
                marginTop: "0",
            },
            // html: {
            //     width: "100%",
            // },
            // styling carousel
            "div.carousel-root, div.carousel.carousel-slider, div.slider-wrapper, ul.slider.animated, li.slide > div": {
                // [sizes.down("md")]: {
                // height: `calc(${"700"}px)`,
                height: "calc(100vh-64px)",
                maxWidth: "100vw",
                // border: "1px solid red",
                [sizes.down("xl")]: {
                    height: "85vh",
                },
                [sizes.down("lg")]: {
                    height: "75vh",
                },
                [sizes.down("md")]: {
                    height: "65vh",
                },
                [sizes.down("sm")]: {
                    height: "50vh",
                },
                [sizes.down("xs")]: {
                    height: "37.5vh",
                },
                [sizes.down("us")]: {
                    height: "30vh",
                },
                // height: "500px",
                // },
            },
            "div.carousel-root > div.carousel.carousel-slider > ul.control-dots": {
                [sizes.down("sm")]: {
                    display: "none",
                },
            },
        },
        // new
        backgroundColor: "#f5f1e9",
        // backgroundColor: "rgba(227, 225, 222, 0.7)",
        // backgroundColor: "rgba(181, 255, 182,0.3)",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "100%, 30%",
        [sizes.down("xl")]: {
            backgroundSize: "100%, 37.5%",
        },
        backgroundPosition: "top, bottom right",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "100%, 75%",
            padding: "0",
            margin: "0",
        },
        paddingRight: "0",
        // height: "50vh",
    },
    titleSection: {
        marginTop: "0.5rem",
    },
    title: {
        fontFamily: "'Commissioner', serif",
        fontSize: "7rem",
        fontFamily: "'Commissioner', serif",
        marginBottom: "0.5rem",
        marginTop: "0rem",
        [sizes.down("xs")]: {
            fontSize: "4rem",
        },
        // marginTop: "5rem",
        backgroundColor: "rgba(255,255,255,0.9)",
        margin: "1.5rem 3rem",
        marginTop: "2rem",
        padding: "1.5rem",
        paddingTop: "0.5rem",
    },
    textBox: {
        backgroundColor: "rgba(255,255,255,0.9)",
        margin: "1.5rem 3rem",
        fontWeight: "light",
        fontFamily: "'Commissioner', serif",
        fontSize: "1.5rem",
        padding: "1.5rem",
        [sizes.down("xs")]: {
            width: "100%",
            margin: "0",
            marginBottom: "3.5rem",
        },
    },
    card: {
        // maxWidth: 345,
        marginTop: "1rem",
        margin: "1rem 1rem",
        height: "24rem",
    },
    cardsSection: {
        // border: "2px solid red",
        margin: "0 2rem",
    },
    carouselSection: {
        // height: "60vh",
    },
    carouselImg: {
        height: "calc(100vh - 64px)",
        [sizes.down("xl")]: {},
        [sizes.down("lg")]: {},
        [sizes.down("md")]: {},
        [sizes.down("sm")]: {},
        [sizes.down("xs")]: {},
    },
}));
export default function ArchivePage() {
    const classes = useStyles();
    const { language } = useContext(LanguageContext);

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            {/* <section className={classes.carouselSection}> */}
            <div style={{ marginTop: "64px" }}></div>
            <Carousel
                style={{
                    border: "5px solid red",
                    marginTop: "64px",
                    marginBottom: "0",
                }}
            >
                {carousel_images.map((i) => (
                    <div
                        className={classes.carouselImg}
                        style={{
                            // border: "10px solid red",

                            backgroundImage: `url(${i})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: "0",
                        }}
                    >
                        {/* <img src={i}></img> */}
                    </div>
                ))}
            </Carousel>
            <section className={classes.titleSection}>
                <p className={classes.title}>
                    {/* last element */}
                    {
                        archiveContent[language][
                            archiveContent[language].length - 1
                        ]
                    }
                </p>
            </section>
            <section>
                <div className={classes.textBox}>
                    {archiveContent[language][0]}
                </div>
                <div className={classes.textBox}>
                    {archiveContent[language][1]}
                </div>
                <div className={classes.textBox}>
                    {archiveContent[language][2]}
                </div>
                <div className={classes.textBox}>
                    {archiveContent[language][3]}
                </div>
                <div className={classes.textBox}>
                    {archiveContent[language][4]}
                </div>
            </section>
            <section className={classes.cardsSection}>
                <Grid
                    style={{
                        width: "100%",
                        // border: "2px solid blue",
                        padding: 0,
                        margin: 0,
                    }}
                    container
                    alignItems="space-between"
                    alignContent="space-between"
                    // spacing={3}
                >
                    {docs[language].map((d) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                            <Link
                                to={d.doc == "" ? "#" : d.doc}
                                target={d.doc == "" ? "" : "_blank"}
                                style={{ textDecoration: "none" }}
                            >
                                <Card className={classes.card}>
                                    <CardActionArea
                                        style={{
                                            height: "100%",
                                            // border: "2px solid black",
                                            display: "flex",
                                            margin: "0",
                                            padding: "0",
                                            flexFlow: "column",
                                        }}
                                    >
                                        <CardMedia
                                            style={{ flexGrow: "1" }}
                                            component="img"
                                            alt="Image"
                                            height="140"
                                            image={`${d.img}`}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            {/* Equalize how much space of the card each description takes  
                                        style={{ height: "40%" }}> */}

                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {d.title || "Title"}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                {d.description ||
                                                    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste tenetur id incidunt qui ex excepturi commodi?"}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                    <Grid style={{ margin: "1rem 1rem 1rem" }} item xs={12}>
                        <IndexListe></IndexListe>
                    </Grid>
                </Grid>
            </section>
        </div>
    );
}

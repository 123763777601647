import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import AboutPage from "./components/AboutPage";
import HomePage from "./components/HomePage";
import InterviewsPage from "./components/InterviewsPage";
import ArchivePage from "./components/ArchivePage";
import HistoryPage from "./components/HistoryPage";
import { LanguageProvider } from "./contexts/LanguageContext";
import SchulSystemPage from "./components/SchulSystemPage";
import GalleryPage from "./components/GalleryPage";
import ContactPage from "./components/ContactPage";
import NotReady from "./components/NotReady";

function App() {
    return (
        <LanguageProvider>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={HomePage}></Route>
                    <Route exact path="/about" component={AboutPage}></Route>
                    <Route
                        exact
                        path="/Interviews"
                        component={InterviewsPage}
                    ></Route>
                    <Route
                        exact
                        path="/archive"
                        component={ArchivePage}
                    ></Route>
                    <Route
                        exact
                        path="/history"
                        component={HistoryPage}
                    ></Route>
                    <Route
                        exact
                        path="/bgschulsystem"
                        component={SchulSystemPage}
                    ></Route>
                    <Route
                        exact
                        path="/gallery"
                        component={GalleryPage}
                    ></Route>
                    <Route
                        exact
                        path="/contact"
                        component={ContactPage}
                    ></Route>
                    <Route exact path=""></Route>
                </Switch>
            </div>
        </LanguageProvider>
    );
}

export default App;

const historyContent = {
    german: [
        {
            text:
                "Die Schulzeit prägt uns und die gesammelten Erfahrungen begleiten uns auch durch das spätere Leben. Das ist zeitlos so, aber die Form des Schulalltags ändert sich mit der Zeit. Gerade an unserer Schule gibt es da einen entscheidenden Umbruch in der eigenen Geschichte, denn das heutige Konstantin-Galabov-Gymnasium hieß vor dem Umbruch im Jahre 1989 „Karl-Liebknecht-Schule“: Auch im sozialistischen Bulgarien war das Profil der Schule bereits, wie heute, dadurch geprägt, dass es hier muttersprachliche Lehrkräfte aus Deutschland gab – allerdings im Gegensatz zu heute ausschließlich aus der ehemaligen DDR. Die Zeit, in der Deutschland und ganz Europa durch den sogenannten „Eisernen Vorhang“ getrennt waren, spiegelte sich auch im Schulalltag und im Unterricht wider. Aber inwiefern eigentlich? Das ist die grundlegende Frage, die hinter unserem Projekt steht.",
        },
        {
            text:
                "Obwohl der Sozialismus Bulgarien über 40 Jahre lang prägte, ist diese Zeit für viele heutige Jugendliche ein unbeschriebenes Blatt. Durch unsere Erfahrungen als Geschichtslehrer wussten wir, Philipp Jansche und Robert Krause, dass viele Schülerinnen und Schüler gerne mehr über die Zeit wissen möchten. Denn was sie von Menschen mitbekommen, die diese Zeit erlebt haben, ist oft durchaus widersprüchlich. Während einige darüber berichten, dass man in seinen Freiheiten eingeschränkt wurde und bei nicht systemkonformem Verhalten Repressalien fürchten musste, sprechen andere auch von „der guten alten Zeit“, in der vieles besser gewesen sein soll. Wie war es nun wirklich?",
        },
        {
            text:
                "Das Problem ist, dass Erinnerungen immer subjektiv sind, und die Aufgabe des Historikers ist es, solche Berichte von Zeitzeugen nicht nur zu sammeln, sondern im historischen Zusammenhang einzuordnen und dabei auch kritisch zu reflektieren. Genau das war und ist das Ziel unseres Projektes „Schule im Sozialismus – Zeitzeugen erinnern sich“. Die Idee, ein solches Projekt anzugehen und umzusetzen, entstand durch einen Wettbewerb des Auswärtigen Amtes Deutschlands. Auf Initiative von Bundesaußenminister Heiko Maas wurde 2019 der Förderwettbewerb „Erinnern für die Gegenwart“ für Deutsche Auslandsschulen und Deutsch-Profil-Schulen ausgeschrieben. Das Auswärtige Amt hat gemeinsam mit der Zentralstelle für das Auslandsschulwesen die Deutschen Auslandsschulen und Deutsch-Profil-Schulen aufgerufen, für das Schuljahr 2019/2020 Projekte zur Erinnerungskultur zu entwickeln. Der Wettbewerb findet in Kooperation mit der Bundeszentrale für politische Bildung und der Stiftung Erinnerung, Verantwortung und Zukunft statt.",
        },
        {
            text:
                "Auch unsere Schule bewarb sich und hatte damit Erfolg: Unser Projekt gehört zu einem von insgesamt fast 60 Projekten, die weltweit am Wettbewerb teilnehmen und somit auch gefördert werden. Dazu gehört neben der finanziellen Unterstützung, dass die Projektverantwortlichen zu einem mehrtägigen Seminar nach Berlin eingeladen wurden. Dort gab es eine Menge Inputs: Wie plant man ein solches Projekt? Welche rechtli-chen Aspekte sind zu bedenken? Wie finde ich die passende Präsentationsform und wie lässt sich das Projekt medial begleiten, um es einer größeren Öffentlichkeit vorzustellen?",
        },
        {
            text:
                "Und es ging auch um ganz praktische Tipps: Was gilt es konkret zu bedenken, wenn man mit einem Zeitzeugen spricht? Was genau frage ich und wie stelle ich diese Fragen am besten? Welche technische Ausrüstung brauche ich für ein solches Interview? Die Projektidee sollte mittels einer Arbeitsgemeinschaft umgesetzt werden und dafür brauchten wir zunächst motivierte und interessierte Schülerinnen und Schüler. So stellten wir das Projekt in allen Klassen des zehnten und elften Jahrgangs vor, also sowohl in den Abteilungs- als auch in den DSD-Klassen. Die Nachfrage war enorm, etwa 40 Schülerinnen und Schüler wollten mitmachen, obwohl von Anfang an klar war, dass dieses Projekt auch sehr viel Engagement und Einsatz von allen Beteiligten verlangt.",
        },
        {
            text:
                "So sammelten wir zunächst Ideen, wohin die Reise eigentlich gehen soll. Alle durften ihre Wünsche, Anregungen und Vorstellungen mit einbringen und nach vielen Diskussionen stand fest: Wir planen eine Ausstellung, in der interessierte Besucher sich klassisch anhand von Text- und Fotodokumentation über unser Projekt und die Schulgeschichte informieren können. Darüber hinaus sollte die Ausstellung aber multimedial gestaltet sein und gesammelte Utensilien aus dem sozialistischen Schulalltag wie alte Schülerdokumente, Lehrbücher und Uniformen präsentieren, um ein anschauliches Bild zu vermitteln.",
        },
        {
            text:
                "Und das Kernstück unseres Projektes – die Videos mit den Interviews der Zeitzeugen – sollten ausschnittsweise auf Leinwänden projiziert werden und zum individuellen Auswählen und Betrachten auf mehreren Notebooks zur Verfügung stehen. Eine Gruppe von Schülern plante darüber hinaus noch, eine Unterrichtssequenz filmisch nachzustellen. Und bereits in dieser Planungsphase war man sich einig, dass es schade wäre, wenn die Ergebnisse mit dem Ende der Ausstellung der interessierten Öffentlichkeit nicht mehr zur Verfügung stünden. Als geeignete Lösung für dieses Problem erschien uns die Möglichkeit, eine eigene Homepage zu gestalten, die zeitlich unbegrenzt unsere Arbeit dokumentieren würde. Und diese wäre zugleich eine ideale Basis, um das Projekt gegebenenfalls weiterführen zu können.",
        },
        {
            text:
                "Doch bevor es dann an die eigentliche Arbeit ging, wurde es theoretisch. Da die meisten Schülerinnen und Schüler wenig über die kommunistische Zeit ihres Landes wissen und auch keine konkreten Vorstellungen haben, was die ideologischen Grundlagen dafür waren, gab es eine paar Stunden Geschichtsunterricht.    Wir thematisierten, in welchem historischen Umfeld die Ideen von Marx entstanden, diskutierten das damit verbundene Menschenbild und untersuchten, wie diese Ideen dann im real existierenden Sozialismus des 20. Jahrhunderts umgesetzt wurden.",
        },
        {
            text:
                "Damit die Schülerinnen und Schüler ein noch konkreteres Bild vom Leben im sozialistischen Bulgarien gewinnen konnten, besuchten wir noch die „Red Flat“-Ausstellung in der Innenstadt Sofias. Dort kann man sich in einer Wohnung, die wie in den 80er-Jahren mit Original-Inventar eingerichtet ist, mittels Audio-Dateien informieren, wie der kommunistische Alltag aus verschiedenen Perspektiven von authentischen Zeitzeugen wahrgenommen wurde. Das interessant aufbereitete Material bot jede Menge Inspiration für die eigene Projektarbeit.",
        },
        {
            text:
                "Nach durchaus auch kontroversen, aber fruchtbaren Diskussionen über die sozialistische Vergangenheit ging es an die Vorbereitung der Zeitzeugen-Interviews. Um die Teilnehmenden dafür zu sensibilisieren, wie man die richtigen Fragen formuliert, eine angenehme Interview-Atmosphäre schafft und zugleich das Gespräch zielgerichtet und aspektorientiert führt, trainierten wir dies gemeinsam: zunächst mit zwei „Fake“-Zeitzeugen, also Interviews mit fiktiven Gesprächspartnern, die wir Lehrer simulierten. So bekamen die angehenden Historikerinnen und Historiker einen ersten Eindruck davon, dass solche Interviews oft nicht so ablaufen, wie man sich das vorher vorgestellt hat. Nach der eingehenden Analyse der Probleme, die auftreten können, interviewten wir gemeinsam die erste reale Zeitzeugin: Kollegin Anni Tsaneva, die bereits Schülerin der „Karl-Liebknecht-Schule“ war und bereitwillig und geduldig alle Fragen beantwortete.",
        },
        {
            text:
                "Parallel erfuhren die teilnehmenden Schülerinnen und Schüler, wie man im bulgarischen Staatsarchiv an die gesuchten Informationen gelangt. Die bulgarische Geschichtskollegin Zornitsa Velinova erklärte ihnen, was man bei einer Recherche dort alles zu berücksichtigen hat, und begleitete die Schülergruppe auch bei deren mehrmaligen Besuchen im Archiv, das uns freundlicherweise bei unserem Projekt unterstützte.",
        },
        {
            text:
                "Über eine digitale Lernplattform (Google-Classroom) organisierten wir die Einteilung der Kleingruppen, die zusammen die Interviews führen wollten, und die Informationen über die gewonnenen Zeitzeugen. So war es endlich so weit, dass die Schülerinnen und Schüler in kleinen Teams anfingen, die Interviews zu führen. Erstaunlich war, wie viele Gespräche einige Teams innerhalb kürzester Zeit neben ihren schulischen Aufgaben schafften.",
        },
        {
            text:
                "Doch diese enthusiastische Arbeitsmotivation erhielt dann einen gehörigen Dämpfer: Die Maßnahmen der Corona-Pandemie führten dazu, dass nicht nur die Schule geschlossen wurde und der Unterricht nur noch online stattfand, sondern auch Kontakte untersagt waren. Somit konnten weder weitere Interviews aufgenommen werden noch Schülergruppen sich treffen, um das bereits gesammelte Material gemeinsam zu bearbeiten. Und als diese Maßnahmen dann nach fast drei Monaten wieder langsam gelockert wurden, war das Schuljahr bereits beendet und die fast dreimonatigen Sommerferien begannen.",
        },
        {
            text:
                "So war es zunächst nicht so einfach, nach einer solch langen Zwangspause wieder als Gruppe zusammenzufinden und die Motivation wieder aufleben zu lassen, um das Projekt trotz der widrigen Umstände noch weiterzuführen. Dennoch war ein Großteil der bisherigen Gruppe bereit, es dennoch zu versuchen. Man vereinbarte, dass man sich die ursprünglich geplante Form einer Präsenz-Ausstellung als Option offenhalten wollte. Als realistischen Zeitraum ist der Anfang des nächsten Jahres angedacht. Da aber auch dies unter den derzeitigen Rahmenbedingungen nicht mit Sicherheit zu realisieren ist, beschlossen wir, uns zunächst auf die anfangs als „Nebenprodukt“ geplante eigene Homepage zu konzentrieren.",
        },
        {
            text:
                "Seit dem Schulstart Mitte September arbeiten die Teilnehmenden daran, die bereits gefilmten Interviews zu schneiden und die überwiegend auf Bulgarisch geführten Gespräche mit deutschen Untertiteln zu versehen. Zudem schaffte es ein kleines Team von Experten unter Hochdruck, binnen weniger Wochen eine eigene Homepage ins Leben zu rufen. Diese stellt vorerst nur einen Teil der bisherigen Ergebnisse vor und geplant ist, sie in der Zukunft um weiter entstehende Arbeitsergebnisse zu erweitern. Wir sind froh und auch ein wenig stolz, dass wir das trotz der Corona-Pandemie geschafft haben. Und wir sind zuversichtlich, dass dies nicht das Ende unseres Projektes, sondern lediglich ein weiterer Schritt sein wird. Und dennoch ziehen wir schon jetzt ein erstes Fazit: Es war nicht nur eine Menge Arbeit, sondern auch eine motivierende Herausforderung, bei der wir alle viel lernten, eine Menge neuer sowie interessanter Erfahrungen machten und viel Freude im Team hatten.",
        },
        {
            text:
                "Nachdem auch dieses Gespräch ausgewertet wurde, ging es an die technische Umsetzung des Vorhabens. Technikaffine Schüler übernahmen diese Aufgabe und erklärten den restlichen Teilnehmerinnen und Teilnehmern praxisnah, was bei den Aufnahmen zu bedenken ist, damit Bild und Ton stimmen.",
        },
        // shorter texts
        {
            text: "Ein Blick zurück: Von der Idee zur eigenen Homepage",
        },
        {
            text:
                "Seit über einem Jahr engagieren sich SchülerInnen für ein außergewöhnliches Projekt",
        },
        {
            text:
                "Die Kamera läuft: Nun gilt es, die richtigen Fragen an den Zeitzeugen zu stellen. (Fotos: Krause)",
        },
        {
            text:
                "Inspiration für eigene Ideen: Schüler informierten sich in der „Red Flat“-Ausstellung.",
        },
        {
            text:
                "Unser erstes Interview mit der Zeitzeugin und Kollegin Frau Tsaneva führten wir gemeinsam.",
        },
        {
            text:
                "Schüler als Lehrer: Das notwendige Experten-Wissen vermittelte man sich gegenseitig.",
        },
        {
            text:
                "Wie recherchiere ich im Archiv? Kollegin Zornitsa Velinova klärt die Nachwuchs-Historiker auf.",
        },
    ],
    english: [
        {
            text:
                'Our school years shape us and the experiences we gain accompany us throughout our later lives. This is timeless, but the form of everyday school life changes over time. Especially at our school, there is a decisive change in our own history, because today\'s Konstantin-Galabov-Gymnasium was called "Karl-Liebknecht-Schule" (Karl Liebknecht School) before the transition in 1989: Even in socialist Bulgaria, the profile of the school was already characterised, as it is today, by the fact that there were native teachers from Germany - but in contrast to today, exclusively from the former GDR. The time when Germany and the whole of Europe were separated by the so-called "Iron Curtain" was also reflected in everyday school life and teaching. But to what extent, actually? That is the fundamental question behind our project',
        },
        {
            text:
                "Although socialism shaped Bulgaria for over 40 years, this period is a blank slate for many of today's young people. Through our experiences as history teachers, we, Philipp Jansche and Robert Krause, knew that many pupils would like to know more about the time. Because what they hear from people who experienced that time is often quite contradictory. While some report that one's freedoms were restricted and that one had to fear reprisals if one did not conform to the system, others also speak of \"the good old days\" when many things were supposed to have been better. What was it really like?",
        },
        {
            text:
                'The problem is that memories are always subjective, and the historian\'s task is not only to collect such reports from contemporary witnesses, but also to classify them in their historical context and, in doing so, to reflect on them critically. This was and is precisely the goal of our project "School under Socialism - Contemporary Witnesses Remember". The idea to start and implement such a project came about through a competition organised by the German Foreign Office. On the initiative of Federal Foreign Minister Heiko Maas, the funding competition "Erinnern für die Gegenwert" for German schools abroad and German profile schools was announced in 2019. Together with the Central Agency for Schools Abroad, the Federal Foreign Office has called on German schools abroad and German-Profile schools to develop projects on the culture of remembrance for the school year 2019/2020. The competition is being held in cooperation with the Federal Agency for Civic Education and the Foundation Remembrance, Responsibility and Future.',
        },
        {
            text:
                "Our school also applied and was successful: our project is one of a total of almost 60 projects worldwide that are taking part in the competition and are thus also being supported. In addition to financial support, the project leaders were invited to a seminar in Berlin that lasted several days. There was a lot of input there: How do you plan such a project? What legal aspects need to be considered? How do I find the right form of presentation and how can the project be accompanied by the media in order to present it to a wider public?",
        },
        {
            text:
                "And it was also about very practical tips: What do you have to consider when talking to a contemporary witness? What exactly do I ask and how do I best ask these questions? What technical equipment do I need for such an interview? The project idea was to be implemented by means of a study group and for this we first needed motivated and interested pupils. So we presented the project in all classes of the tenth and eleventh year, i.e. in the department classes as well as in the DSD classes. The demand was enormous, about 40 students wanted to participate, although it was clear from the beginning that this project also required a lot of commitment and dedication from everyone involved.",
        },
        {
            text:
                "So first we collected ideas about where the journey should actually go. Everyone was allowed to contribute their wishes, suggestions and ideas and after many discussions it was decided: We are planning an exhibition in which interested visitors can inform themselves about our project and the school's history in the classic way with text and photo documentation. In addition, however, the exhibition should be multimedia-based and present collected paraphernalia from everyday socialist school life, such as old student documents, textbooks and uniforms, in order to convey a vivid picture.",
        },
        {
            text:
                "And the core of our project - the videos with the interviews of the eyewitnesses - were to be projected in excerpts on screens and made available for individual selection and viewing on several notebooks. A group of pupils also planned to re-enact a teaching sequence on film. And already in this planning phase, it was agreed that it would be a pity if the results were no longer available to the interested public at the end of the exhibition. A suitable solution to this problem seemed to us to be the possibility of creating our own homepage, which would document our work for an unlimited period of time. And this would also be an ideal basis for continuing the project if necessary.",
        },
        {
            text:
                "But before they got down to the actual work, it became theoretical. Since most of the students know little about the communist period in their country and also have no concrete ideas about what the ideological basis for it was, there were a few hours of history lessons.    We addressed the historical environment in which Marx's ideas arose, discussed the image of man associated with them and examined how these ideas were then implemented in the real existing socialism of the 20th century.",
        },
        {
            text:
                'To give the pupils an even more concrete picture of life in socialist Bulgaria, we also visited the "Red Flat" exhibition in the centre of Sofia. There, in a flat furnished with original inventory as in the 80s, one can find out by means of audio files how communist everyday life was perceived from different perspectives by authentic contemporary witnesses. The interestingly prepared material offered plenty of inspiration for the participants\' own project work.',
        },
        {
            text:
                'After some controversial but fruitful discussions about the socialist past, we started preparing the interviews with contemporary witnesses. In order to sensitise the participants to how to formulate the right questions, create a pleasant interview atmosphere and at the same time conduct the interview in a targeted and aspect-oriented manner, we trained this together: first with two "fake" contemporary witnesses, i.e. interviews with fictitious interlocutors that we teachers simulated. In this way, the budding historians got a first impression of the fact that such interviews often do not go as one had imagined beforehand. After the in-depth analysis of the problems that can occur, we interviewed the first real contemporary witness together: colleague Anni Tsaneva, who was already a pupil at the "Karl Liebknecht School" and willingly and patiently answered all the questions.',
        },
        {
            text:
                "At the same time, the participating pupils learned how to get the information they were looking for in the Bulgarian State Archives. The Bulgarian history colleague Zornitsa Velinova explained to them what they had to take into account when doing research there and also accompanied the group of pupils on their several visits to the archives, which kindly supported us in our project.",
        },
        {
            text:
                "Via a digital learning platform (Google Classroom), we organised the division of the small groups that wanted to conduct the interviews together and the information about the eyewitnesses they had gained. So it was finally time for the students to start conducting the interviews in small teams. It was astonishing how many interviews some teams managed to conduct within a very short time, besides their school work.",
        },
        {
            text:
                "However, this enthusiastic motivation to work was then dampened considerably: the measures of the Corona pandemic not only led to the school being closed and classes being held only online, but also to contacts being prohibited. This meant that no further interviews could be recorded, nor could groups of students meet to work together on the material they had already collected. And when these measures were slowly relaxed again after almost three months, the school year was already over and the almost three-month summer holidays began.",
        },
        {
            text:
                'So it was not so easy at first to come together again as a group after such a long forced break and to revive the motivation to continue the project despite the adverse circumstances. Nevertheless, a large part of the previous group was willing to give it a try. It was agreed that the originally planned form of a presence exhibition would be kept open as an option. A realistic time frame is the beginning of next year. However, since even this cannot be realised with certainty under the current conditions, we decided to concentrate first on our own homepage, which was initially planned as a "by-product".',
        },
        {
            text:
                "Since the start of school in mid-September, the participants have been working on editing the already filmed interviews and adding German subtitles to the conversations, which were mostly conducted in Bulgarian. In addition, a small team of experts worked under high pressure to create their own homepage within a few weeks. For the time being, this only presents a part of the results so far and it is planned to expand it in the future with further results of the work. We are happy and also a little proud that we have managed this despite the Corona pandemic. And we are confident that this will not be the end of our project, but just another step. And yet, we are already drawing a first conclusion: It was not only a lot of work, but also a motivating challenge in which we all learned a lot, made a lot of new as well as interesting experiences and had a lot of fun in the team.",
        },
        {
            text:
                "After this discussion was also evaluated, the technical implementation of the project began. Students with an affinity for technology took on this task and explained to the other participants in a practical way what they needed to consider when recording so that the picture and sound were right.",
        },
        {
            text: "A look back: From the idea to our own homepage",
        },
        {
            text:
                "For over a year, pupils have been involved in an extraordinary project",
        },
        {
            text:
                "The camera is running: Now it's a matter of asking the right questions to the contemporary witness. (Photos: Krause)",
        },
        {
            text:
                'Inspiration for their own ideas: Pupils informed themselves in the "Red Flat" exhibition',
        },
        {
            text:
                "We conducted our first interview with the witness and colleague Ms Tsaneva together",
        },
        {
            text:
                "Students as teachers: The necessary expert knowledge was passed on to each other",
        },
        {
            text:
                "How do I do research in the archive? Colleague Zornitsa Velinova enlightens the young historians",
        },
    ],
    bulgarian: [
        {
            //done
            text:
                'Времето в училище ни оформя като личности и натрупаният опит ни съпътства през целия живот. Той завинаги остава с нас, но с времето ежедневният училищен живот се променя. Нашето училище претърпява решаваща промяна в собствената си история, тъй като днешната Немската гимназия „Проф. Константин-Гълъбов“ преди промените през 1989 г. се е казвала гимназия „Карл-Либкнехт“. Още през социализма основната идея на училището е била да има учители от бившата ГДР, чийто майчин език е немски. Времето, когато Германия и цяла Европа бяха разделени от така наречената "желязна завеса", също се отрази на училищното ежедневие и на уроците. Но до каква степен? Това е основният въпрос зад нашия проект.',
        },
        {
            //done
            text:
                "Въпреки че социализмът оформя България в продължение на повече от 40 години, този период е непознат за много млади хора днес. Чрез опита си като учители по история ние, Филип Янше и Робърт Краузе, знаехме, че много ученици биха искали да разберат повече за времето тогава. Защото това, което научават от хора, живели в тези времена, често е доста противоречиво. Докато някои разказват, че човек е бил ограничен в свободите си и е трябвало да се страхува от наказания при неприемливо за системата поведение, други описват „добрите стари времена“, в които много неща са били по-добри. Как е било всъщност?",
        },
        {
            //done
            text:
                "Проблемът е, че спомените винаги са субективни и задачата на историка е не само да събира подобни доклади от съвременни свидетели, но и да ги класифицира в исторически контекст и да ги представя критично. Точно това е и целта на нашия проект „Училището в социализма - свидетелите помнят (Schule im Sozialismus - Zeitzeugen erinnern sich)“. Идеята за реализиране на такъв проект възниква в резултат на конкурс, организиран от Германското външно министерство. По инициатива на федералния външен министър Хайко Маас през 2019 г. беше обявен конкурса „Спомняме си за настоящето (Erinnern für die Gegenwart)“ за немски училища в чужбина (Deutsche Auslandsschulen) и немски профилни училища (Deutsch-Profil-Schulen). Федералното министерство на външните работи и Централният офис на училищата в чужбина (ZfA) призоваха немските училища в чужбина и немските профилни училища да разработят културно-исторически проекти за учебната 2019/2020 година. Състезанието се провежда в сътрудничество с Федералната агенция за гражданско образование (Bundeszentrale für politische Bildung) и Фондация „Възпоменание, отговорност и бъдеще“ (Stiftung Erinnerung, Verantwortung und Zukunft)",
        },
        {
            //доне
            text:
                "Нашето училище също кандидатства и беше прието: Нашият проект е един от почти 60 проекта, които участват в конкурса в световен мащаб и биват финансирани. В допълнение към финансовата подкрепа, отговорните за проекта бяха поканени на многодневен семинар в Берлин. Там получиха отговор на въпросите: Как се планира такъв проект? Кои правни аспекти трябва да се вземат предвид? Как да се намери правилната форма на презентация и как проектът може да бъде придружен от медии, за да бъде представен на по-широка публика?",
        },
        {
            //доне
            text:
                "Стана дума и за много практични съвети: Какво точно трябва да се има предвид, когато се говори със свидетел? Какво точно се пита и как най-добре се задават тези въпроси? Какво техническо оборудване е нужно за подобно интервю? Идеята на проекта трябваше да бъде реализирана чрез работна група(AG) и затова първо се нуждаехме от мотивирани и заинтересовани ученици. Представихме проекта във всички десети и единадесети класове, тоест както в паралелките от Немския отдел, така и в тези от DSD. Интересът беше огромен, около 40 ученици искаха да вземат участие, въпреки че още в самото начало беше ясно, че този проект изисква много ангажираност и участие от всички.",
        },
        {
            //доне
            text:
                "Първо събрахме идеи за това в каква посока всъщност трябва да насочим проекта. На всички беше позволено да споделят своите желания, предложения и представи. След много дискусии стана ясно: планираме изложба, в която заинтересованите посетители да научат повече за нашия проект и историята на училището, като използват текстов и снимков материал. Освен това изложбата трябва да има мултимедиен дизайн и да представя събрани предмети от ученическото ежедневие през социализма като стари ученически документи, учебници и униформи, за да представи ясна картина за тези времена.",
        },
        {
            //done
            text:
                "Интервютата със свидетели - ядрото на нашия проект - трябваше да бъдат проектирани на екрани и да бъдат достъпни за гледане на таблети. Група ученици също планираха да създадат образователен материал във формата на филм. Още в тази фаза на планиране бяхме единодушни, че би било жалко, ако резултатите не са достъпни за интересуващата се публика в края на нашия проект. Като подходящо решение на този проблем решихме да изградим уебсайт, който ще съхрани нашия труд. Това също би ни дало идеалната основа за продължаване на проекта.",
        },
        {
            //done
            text:
                "Но преди действителната работа да започне, трябваше да се подготвим теоретично. Тъй като повечето ученици знаят малко за комунистическото време в страната си и не разбират конкретните идеологии и основи на периода, имаше уроци по история. Освен историческия контекст около възникването на идеите на Маркс, обсъдихме и тогавашната представа за обществото и нейното приложение в реалния социализъм на 20 век.",
        },
        {
            //done
            text:
                "За да могат учениците да получат още по-конкретна картина за живота в социалистическа България, посетихме изложбата „Червеният апартамент” (Red Flat) в центъра на София. Там чрез аудио файлове се пренесохме в атмосферата на 80те години, като същевременно разгледахме апартамента, обзаведен с автентичен инвентар. По този начин с помощта на свидетели от онова време се докоснахме до комунистическото ежедневие от различни гледни точки. Интересно подготвеният материал предложи много вдъхновение за собствената ни работа по проекта.",
        },
        {
            //доне
            text:
                "След доста противоречиви, но ползотворни дискусии за социалистическото минало, беше време да подготвим интервютата на свидетелите. За да информираме участниците как да формулират правилните въпроси, да създадат приятна атмосфера на интервюто и в същото време да провеждат разговора по ориентиран към целта и темата начин, ние симулирахме това първо с двама „фалшиви“ свидетели, т.е. интервюта с фиктивни партньори, като приехме ролята на учители. Това направи на бъдещите историци първото впечатление, че подобни интервюта често не протичат така, както са си представяли преди. След подробен анализ на проблемите, които биха могли да възникнат, интервюирахме първия истински свидетел: колегата Ани Цанева, която е била ученичка в училище „Карл Либкнехт“ и отговори на всички въпроси с желание и търпение.",
        },
        {
            //доне
            text:
                "В същото време учениците научиха как да се сдобият с информация от Българския държавен архив. Колегата по история Зорница Велинова им обясни всичко, което трябва да се вземе предвид при проучване, а също така придружи групата ученици при многократните им посещения в архива, чиито служители любезно ни съдействаха с нашия проект.",
        },
        {
            //доне
            text:
                "Използвайки дигиталната платформа за обучение Google Classroom, организирахме разделянето на малките групи, които искаха да проведат интервютата заедно, и получената информация за свидетелите. Най-накрая беше време учениците да започнат с интервютата в малки екипи. Изумително беше колко много разговори някои от тях успяха да проведат за кратко време паралелно с училищните си задачи.",
        },
        {
            //доне
            text:
                "Но тази ентусиазирана мотивация за работа бе повлияна негативно от противоепидемичните мерки. Те доведоха не само до затварянето на училището и началото на онлайн обучението, но и до забрана на всякакви контакти. Това означаваше, че нито можеха да се записват допълнителни интервюта, нито учениците можеха да се срещат, за да работят по материалите, които вече бяха събрали. И когато тези мерки бяха разхлабени бавно след почти три месеца, учебната година вече беше приключила и почти тримесечната лятна ваканция започна.",
        },
        {
            //доне
            text:
                "Първоначално не беше толкова лесно да се съберем заедно след толкова дълга принудителна почивка и да продължим проекта мотивирано въпреки неблагоприятните обстоятелства. Голяма част от групата обаче така или иначе беше готова да се върне на работа. Беше договорено първоначално планираната форма на изложение лице в лице да остане отворена като опция. Като реалистичен краен срок се предвижда началото на следващата година. Но тъй като това не може да бъде реализирано със сигурност при настоящите условия, решихме да се концентрираме върху уебсайта, който първоначално беше планиран като страничен проект.",
        },
        {
            //доне
            text:
                "Откакто училището започна в средата на септември, участниците работят върху обработката на вече заснетите интервюта и добавянето на немски субтитри. Малък екип от експерти успя под напрежение да създаде уебсайт в рамките на няколко седмици. За момента това представя само част от постигнатото досега и се планира да се разшири в бъдеще, за да се включат допълнителни резултати от работата. Щастливи и горди сме, че успяхме да направим това въпреки пандемията. Също така сме и уверени, че това няма да е краят на нашия проект, а просто поредната стъпка. И все пак вече правим първото си заключение: това беше не само много работа, но и мотивиращо предизвикателство, изпълнено с много нови знания, интересни преживявания и забавления.",
        },
        {
            //доне
            text:
                "След като този разговор също беше проведен, започна техническата подготовка по проекта. Умелите в сферата на технологиите ученици се заеха със задачата и обясниха на останалите участници какво трябва да се вземе предвид по време на записите, така че картината и звукът да са в съответствие.",
        },
        {
            text: "Поглед към миналото: пътят от идеята до уебсайта",
        },
        {
            text:
                "Вече повече от година учениците се работят над един необичаен проект",
        },
        {
            text:
                "Пред обектива: сега трябва да зададем правилните въпроси на свидетелите (Снимки: Краузе)",
        },
        {
            text:
                'Вдъхновение за собствени идеи: ученици се информират на изложбата "Червеният апартамент"',
        },
        {
            text:
                "Проведохме нашето първо интервю със свидетелката и колега госпожа Цанева заедно",
        },
        {
            text:
                "Ученици като учители: взаимно обучение и предаване на нужните знания",
        },
        {
            text:
                "Как проучвам в архива? Колегата Зорница Велинова разяснява особеностите на младите историци",
        },
    ],
};

export default historyContent;

/*kадe e wie recherchiere ich?????????????????????? loser
maleeeeeeeeeeee i schüler als lehrer go nqma
ne te e sram
KUDE E VSICHKO????
ti samo purvite tri li zachitash
ste si napravq izvodite*/

const videoTabsContent = {
    bulgarian: {
        bulgarischeVideos: "Интервюта на български",
        bulgarischeVideosWithSub: "Интервюта на български със субтитри",
        deutscheVideos: "Интервюта на немски",
    },
    english: {
        bulgarischeVideos: "Interviews in bulgarian",
        bulgarischeVideosWithSub: "Interviews in bulgarian with subtitles",
        deutscheVideos: "Interviews in german",
    },
    german: {
        bulgarischeVideos: "Interviews auf Bulgarisch",
        bulgarischeVideosWithSub: "Interviews auf Bulgarisch mit Untertiteln",
        deutscheVideos: "Interviews auf Deutsch",
    },
};

export default videoTabsContent;

const allVids = {
    bulgarian: [
        {
            language: "BG-SUB",
            tabTitle: "Silvia Barabareva und Nikolay Voychev",
            title: "Силвия Барабарева (1985) и Николай Войчев (1991)",
            url: "https://youtu.be/D0iLsI5SR7g",
            description:
                "Силвия Барабарева и Николай Войчев разказват за различията между учителите, както и липсващите политическите разговори. Споделят и за предпочитанията и пренебрежението през времето в училище. Комунистическите манифестации също са тема.",

            index: 0,
        },
        {
            language: "BG-SUB",
            tabTitle: "Михаил Боянов",
            title: "Михаил Боянов, 1979-1984",
            url: "https://www.youtube.com/watch?v=Bikjz5d9tH4&feature=youtu.be",
            description:
                "Михаил Боянов, днес лекар в София, описва своите ученически години. Споделя за разликите между българските и немските учители, разговорите за политика и за съучениците с привилегии. Разказва за забранените книги, социалистическите фестивали и прави сравнение между живота в момента и тогава.",

            index: 0,
        },
        {
            language: "DE",
            tabTitle: "Надежда и Даниела",
            title: "Надежда и Даниела, 1986/87-1991",
            url: "https://youtu.be/F6mUTu9qVE8",
            description:
                'Във видеото Надежда Стоилова и Даниела Златева отговарят на въпроси за своите ученически дни. Двете посещават гимназията "Карл Либкнехт" от 1986/87 до 1991 г. като един от последните випуски преди прехода. Споделят, че времето, прекарано в гимназията, ги е оформило като личности. Не задължително заради ученето, а заради приятелствата, които в по-голямата си част съществуват и до днес.',
            index: 1,
        },
        {
            language: "DE",
            tabTitle: "Нина Пеевска",
            title: "Нина Пеевска, 1960-1962",
            url: "https://youtu.be/qpEdso-LcEY",
            description:
                "Нина Пеевска е една от първите учителки в Немската гимназия. Тя преподава в продължение на 2 години (1960 до 1962 г.) и ни разказва важна информация за създаването на училището, уроците и учениците от гледната точка на учител. Някои от въпросите се отнасят до ежедневния училищен живот, а други до политическата ситуация и ежедневието.",
            index: 2,
        },
        {
            language: "DE",
            tabTitle: "Преслава Владимирова",
            title: "Преслава Владимирова, 1970-1975",
            url: "https://youtu.be/ctg8DIKCIbM",
            description:
                "Преслава Владимирова е била ученичка в училището „Карл Либкнехт“ от 1970 г. до 1975 г.. Тя разказва за трудните приемни изпити и специалния F клас. Също споделя подробности за гимназията.",
            index: 3,
        },
        {
            language: "DE",
            tabTitle: "Анна Цанева",
            title: "Анна Цанева, 1969-1974",
            url: "https://youtu.be/xn7EVxBJthI",
            description:
                "Анна Цанева описва училищните си преживявания и строгите и преувеличени правила. Разказва за училищните предмети и последиците от неправомерното поведение върху оценките.",
            index: 4,
        },
        {
            language: "DE",
            tabTitle: "Петър Стоянович",
            title: "Петър Стоянович, 1981-1986",
            url: "https://youtu.be/bARax8cKp54",
            description:
                'Проф. д-р. Петър Стоянович е български дипломат, журналист и бивш министър на културата на България. Роден е в София през 1967 г. и е завършил 91-ва гимназия "Карл Либкнехт" през 1986 г. След това е учил история във Виенския университет, където през 1998 г. е получил и докторска степен по философия. Днес е професор в Българската академия на науките.',
            index: 5,
        },
        {
            language: "DE",
            tabTitle: "Мариана Цакова",
            title: "Мариана Цакова, 1980-1985",
            url: "https://youtu.be/NS2gcFKjSjU",
            description:
                'Днес г-жа Цакова е учителка в Немската гимназия. Още преди промените е била ученичка в гимназия "Карл Либкнехт". Разказва за училищния живот, за наказанията и ограниченията, но също така и социалните предимства на социализма.',
            index: 6,
        },
        {
            language: "DE",
            tabTitle: "Георги Нончев",
            title: "Георги Нончев, 1984-1989",
            url: "https://youtu.be/RGqpIO8MxiU",
            description:
                "Георги е бил ученик от 1984 г. до 1989 г. и разказва за своите преживявания в Немската гимназия.",
            index: 6,
        },
        {
            language: "BG-SUB",
            tabTitle: "Геновева Теохарова",
            title: "Геновева Теохарова, 1980-1985",
            url: "https://youtu.be/E0HWNKtzKE0",
            description:
                "Геновева Теохарова е била ученичка в гимназия „Проф. Константин Гълъбов“ преди промените и след това учителка в същата гимназия през 1990/1991 г.",
            index: 7,
        },
        {
            language: "BG",
            tabTitle: "Николай Чернокожев",
            title: "Николай Чернокожев, края на 70те - началото на 80те",
            url: "https://youtu.be/dT7h43juLvw",
            description:
                'Д-р Фил. Николай Чернокожев е бил ученик в гимназия "Карл Либкнехт" в средата на социализма. След дипломирането си работи по превода на немски специализирани книги и като автор. В наши дни е професор по българска филология в Софийския университет.',
            index: 8,
        },
        {
            language: "BG",
            tabTitle: "Бойка Къдрева",
            title: "Бойка Къдрева, 1978-1983",
            url: "https://youtu.be/rvKOrqeRSsY",
            description:
                "Бойка Къдрева разказва за ученическите си години преди прехода. Тя описва разликите между немските и българските учители. Други теми включват музика, забранени книги и ролята на медиите. Г-жа Къдрева разказва за специфичните предмети по идеология, степента на поведение и бригадите на учениците.",
            index: 9,
        },
        {
            language: "DE",
            tabTitle: "Карин Стефанова",
            title: "Карин Стефанова, зам. Директор, 1965-1999",
            url: "https://youtu.be/FAI-DoWyrkk",
            description:
                "Г-жа Стефанова говори за опита си като директор, различния живот и по-строгото обучение през социализма. Друга тема е разликата между немските и българските учители, както и промяната в преподаването след промените.",
            index: 10,
        },
        {
            language: "DE",
            tabTitle: "Николай Петров",
            title: "Николай Петров, 1975-1980",
            url: "https://youtu.be/4ai_AA1QE_o",
            description:
                "Николай Петров, който сега работи в австрийска компания, разказва за отношенията между ученици и учители и дали е имало ограничения като табу теми. Описва партийната привилегия и нейната пропорционалност. Други теми са наказанията и степента на поведение. Честванията на социалистическата система се разглеждат към края.",
            index: 11,
        },
        {
            language: "BG",
            tabTitle: "Йордан Дочев",
            title: "Йордан Дочев, 1987-1992",
            url: "https://youtu.be/XBzkXOSKnwc",
            description:
                "Йордан Дочев споделя своя опит в училище и разказва за дисциплината и влиянието на политиката върху училищния живот. Други теми са забранената музика от Запада, достъпът до информация и пробуждането на учениците. Интервюто разглежда и отношенията между учители и ученици. Г-н Дочев споделя мнението си за пътуванията и възможностите след училище.",
            index: 12,
        },
        {
            language: "DE",
            tabTitle: "Цветанка Нейкова",
            title: "Цветанка Нейкова, 1973-1978",
            url: "https://youtu.be/A10LCO_Gw-A",
            description:
                "Г-жа Цветанка Нейкова завършва гимназия „Карл Либкнехт“ през 1978г. След това учи електроника в Техническия университет в София.",
            index: 13,
        },
    ],
    english: [
        {
            language: "BG-SUB",
            tabTitle: "Silvia Barabareva and Nikolay Voychev",
            title: "Silvia Barabareva (1985) and Nikolay Voychev (1991)",
            url: "https://youtu.be/D0iLsI5SR7g",
            description:
                "Silvia Barabareva and Nikolay Voychev talk about the differences between the teachers and the political discussions that did not take place in the school at the time. There is also talk about disadvantage and preference throughout the whole school time. The communist manifestations are also discussed.",
            index: 0,
        },
        {
            language: "BG-SUB",
            tabTitle: "Mihail Boyanov",
            title: "Mihail Boyanov, 1979-1984",
            url: "https://www.youtube.com/watch?v=Bikjz5d9tH4&feature=youtu.be",
            description:
                "Mihail Boyanov, who is now a doctor in Sofia, talks about his school days. He describes the differences between German and Bulgarian teachers, conversations about politics inside and outside school, and classmates with privileges. He also talks about his time as a Komsomol secretary and the limitations of life, felt or not. Banned books and socialist manifestations are also discussed and a comparison of the times is made.",

            index: 0,
        },
        {
            language: "DE",
            tabTitle: "Nadezhda und Daniella",
            title: "Nadezhda and Daniella, 1986/87-1991",
            url: "https://youtu.be/F6mUTu9qVE8",
            description:
                "In this video Nadezhda Stoilova and Daniella Zlateva answer questions about their school days. Both attended the Karl Liebknecht high school at the same time from 1986/87 to 1991 as one of the last classes to be enrolled in the socialist system before the transition. They tell us that the time they spent in high school shaped them as individuals. This was not necessarily due to the studying, but moreso the friendships, most of which still exist today.",
            index: 1,
        },
        {
            language: "DE",
            tabTitle: "Nina Peevska",
            title: "Nina Peevska, 1960-1962",
            url: "https://youtu.be/qpEdso-LcEY",
            description:
                "Nina Peevska was one of the first teachers at the Karl Liebknecht high school. She taught there for 2 years (1960 to 1962) and tells us important information about the school's early years, the lessons and the students from a teacher’s point of view. Some of the questions deal with everyday school life and a few touch on the political situation and living conditions.",
            index: 2,
        },
        {
            language: "DE",
            tabTitle: "Preslava Vladimirova",
            title: "Preslava Vladimirova, 1970-1975",
            url: "https://youtu.be/ctg8DIKCIbM",
            description:
                "Preslava Vladimirova was a pupil for the first time in 1975 at the Karl Liebknecht School and thus had her schooling in the middle of socialism. She reports on tough entrance exams and the special F class. She also addresses some of the peculiarities of the German high school.",
            index: 3,
        },
        {
            language: "DE",
            tabTitle: "Anna Tsaneva",
            title: "Anna Tsaneva, 1969-1974",
            url: "https://youtu.be/xn7EVxBJthI",
            description:
                "Mrs. Tsaneva describes her experiences at school and shares her opinion on the strict and exaggerated rules. She talks about the original school subjects and the effects of misconduct on the assessment particularly in connection to the certificate.",
            index: 4,
        },
        {
            language: "DE",
            tabTitle: "Peter Stojanowitsch",
            title: "Peter Stojanowitsch, 1981-1986",
            url: "https://youtu.be/bARax8cKp54",
            description:
                "University Professor Peter Stoyanovich is a Bulgarian diplomat, journalist and former Minister of Culture of Bulgaria. He was born in 1967 in Sofia and graduated from the “Karl Liebknecht” high school in 1986. He then studied history at the University of Vienna, where he also received his doctorate in philosophy in 1998. Today he is a professor at the Bulgarian Academy of Sciences.",
            index: 5,
        },
        {
            language: "DE",
            tabTitle: "Mariana Tsakova",
            title: "Mariana Tsakova, 1980-1985",
            url: "https://youtu.be/NS2gcFKjSjU",
            description:
                'Today, Mrs. Tsakova is a teacher at the 91st German high school and attended the "Karl Liebknecht" high school as a pupil before the transition. She talks about school life at that time, penalties and restrictions, but also the benefits of socialism.',
            index: 6,
        },
        {
            language: "DE",
            tabTitle: "Georgi Nonchev",
            title: "Georgi Nonchev, 1984-1989",
            url: "https://youtu.be/RGqpIO8MxiU",
            description:
                "Georgi was a pupil from 1984 and describes his experiences in the german high school.",
            index: 6,
        },
        {
            language: "BG-SUB",
            tabTitle: "Mrs. Teoharova",
            title: "Genoveva Teoharova, 1980-1985",
            url: "https://youtu.be/E0HWNKtzKE0",
            description:
                "Genoveva Teoharova was a teacher at the Prof. Konstantin Galabov high school in 1990/1991 and was previously a student there before the transition.",
            index: 7,
        },
        {
            language: "BG",
            tabTitle: "Nikolay Chernokozhev",
            title: "Nikolay Chernokozhev, End of 70'-Start of 80'",
            url: "https://youtu.be/dT7h43juLvw",
            description:
                "Dr. phil. Nikolay Chernokozhev was a student at the Karl Liebknecht high school in the middle years of socialism. After his graduation, he worked on translating German textbooks and as an author of such himself. Nowadays he is a professor of Bulgarian philology at Sofia University.",
            index: 8,
        },
        {
            language: "BG",
            tabTitle: "Boyka Kydrewa",
            title: "Boyka Kydrewa, 1978-1983",
            url: "https://youtu.be/rvKOrqeRSsY",
            description:
                "Boyka Kydrewa talks about her school days and the development before the transition. She mentions the differences between German and Bulgarian teachers. Other topics include music, banned books and the influence of the media. Ms. Kydrewa talks about the specific ideology subjects, the behavior grade, and student brigades.",
            index: 9,
        },
        {
            language: "DE",
            tabTitle: "Karin Stefanova",
            title: "Karin Stefanova, vice-principal 1965-1999",
            url: "https://youtu.be/FAI-DoWyrkk",
            description:
                "Mrs. Stefanova talks about her experiences, the different life and the stricter school time under socialism. Other topics include the differences between German and Bulgarian teachers as well as the change in teaching methods after the transition.",
            index: 10,
        },
        {
            language: "DE",
            tabTitle: "Nikolai Petrov",
            title: "Nikolai Petrov, 1975-1980",
            url: "https://youtu.be/4ai_AA1QE_o",
            description:
                "Nikolai Petrov, now working for an Austrian company, talks about the relationship between students and teachers, whether there were restrictions or taboo topics. He mentions the privileges of party members and their proportionality. Penalty and behavioral grades are also discussed. Another topic are the celebrations of the socialist system.",
            index: 11,
        },
        {
            language: "BG",
            tabTitle: "Iordan Dotchev",
            title: "Iordan Dotchev, 1987-1992",
            url: "https://youtu.be/XBzkXOSKnwc",
            description:
                "Iordan Dotschev graduated from the German high school in 1992. He shares his experiences at school. He then talks about discipline and the influence of politics in school life. Other topics include banned music from the West, access to information, and the change in students' mindset. The interview also discusses the relationship between students and teachers. Mr. Dotschev shares his opinion on travelling and opportunities after school.",
            index: 12,
        },
        {
            language: "DE",
            tabTitle: "Tsvetanka Neikova",
            title: "Tsvetanka Neikova, 1973-1978",
            url: "https://youtu.be/A10LCO_Gw-A",
            description:
                "Ms Tsvetanka Neikova graduated from the Karl Liebknecht high school in 1978. She then studied electronics at the Technical University in Sofia.",
            index: 13,
        },
    ],
    german: [
        {
            language: "BG-SUB",
            tabTitle: "Silvia Barabareva und Nikolay Voychev",
            title: "Silvia Barabareva (1985) und Nikolay Voychev (1991)",
            url: "https://youtu.be/D0iLsI5SR7g",
            description:
                "Silvia Barabareva und Nikolay Voychev sprechen über die Unterschiede zwischen den Lehrern und die politische Diskussion, die damals in der Schule nicht stattfand. Auch wird über Benachteiligung und Bevorzugung und die ganze Schulzeit gesprochen. Die kommunistischen Feste (Manifestationen) sind ebenfalls Thema.",

            index: 0,
        },
        {
            language: "BG-SUB",
            tabTitle: "Mihail Boyanov",
            title: "Mihail Boyanov, Jahre nach 1984",
            url: "https://www.youtube.com/watch?v=Bikjz5d9tH4&feature=youtu.be",
            description:
                "Mihail Boyanov, der heute Arzt in Sofia ist, erzählt von seiner Schulzeit (ab 1984). Er schildert die Unterschieden deutscher und bulgarischer Lehrer, von Gesprächen über Politik innerhalb und außerhalb der Schule und den Mitschülern mit Privilegien. Des Weiteren berichtet er über die Zeit als Komsomol-sekretär und die gefühlten oder nicht gefühlten Beschränkungen des Lebens. Auch über verbotene Bücher, sozialistische Feste und einen Vergleich der Zeiten wird gesprochen.",

            index: 0,
        },
        {
            language: "DE",
            tabTitle: "Nadezhda und Daniella",
            title: "Nadezhda und Daniella, Jahre nach 1986/87",
            url: "https://youtu.be/F6mUTu9qVE8",
            description:
                "In diesem Video beantworten Nadezhda Stoilova und Daniella Zlateva Fragen zu ihrer Schulzeit. Beide besuchten zur gleichen Zeit das Karl Liebknecht Gymnasium in den Jahren 1986/87 bis 1991 als einer der letzten Jahrgänge, die vor der bulgarischen Öffnung noch im sozialistischen System eingeschult wurden. Sie berichten uns, dass sie die Zeit, die sie im Gymnasium verbracht haben sie als Individuen geformt hat. Dies geschah nicht unbedingt wegen des Lernens, sondern wegen der Freundschaften, die größtenteils heute noch bestehen.",
            index: 1,
        },
        {
            language: "DE",
            tabTitle: "Nina Peevska",
            title: "Nina Peevska, Jahre 1960 bis 1962",
            url: "https://youtu.be/qpEdso-LcEY",
            description:
                "Nina Peevska war eine der ersten Lehrerinnen am deutschen Gymnasium. Sie unterrichtete 2 Jahre dort (1960 bis 1962) und erzählt uns wichtige Informationen über den Anfang der Schule, den Unterricht und die Schüler aus Lehrersicht. Einige der Fragen beschäftigen sich mit dem Schulalltag und ein paar auch mit der politischen Situation und den Lebensumständen.",
            index: 2,
        },
        {
            language: "DE",
            tabTitle: "Preslava Vladimirova",
            title: "Preslava Vladimirova, Jahre nach 1975",
            url: "https://youtu.be/ctg8DIKCIbM",
            description:
                "Preslava Vladimirova war zum ersten mal 1975 in der Karl Liebknecht Schule Schülerin und hatte somit mitten im Sozialismus ihre Schulzeit. Sie berichtet über schwere Aufnahmeprüfungen und die spezielle F Klasse. Auch geht sie auf einige Besonderheiten des deutschen Gymnasiums ein.",
            index: 3,
        },
        {
            language: "DE",
            tabTitle: "Anna Tsaneva",
            title: "Anna Tsaneva, heute Lehrerin, Schülerin ab 1969",
            url: "https://youtu.be/xn7EVxBJthI",
            description:
                "Frau Tsaneva schildert hier, wie sie ihre Schulzeit erlebt und die Regeln als streng und übertrieben ansah. Sie berichtet von früheren Schulfächern und den Auswirkungen von Fehlverhalten auf die Beurteilung im und neben dem Zeugnis.",
            index: 4,
        },
        {
            language: "DE",
            tabTitle: "Peter Stojanowitsch",
            title: "Peter Stojanowitsch, Schüler ab 1981",
            url: "https://youtu.be/bARax8cKp54",
            description:
                'O. Univ.-Prof. Dr. habil. Peter Stojanowitsch ist ein bulgarischer Diplomat, Journalist und ehemaliger Kulturminister Bulgariens. Er ist 1967 in Sofia geboren und absolviert das 91.Gymnasium "Karl Liebknecht" im Jahr 1986. Danach studiert er Geschichte an der Universität Wien, wo er 1998 auch zum Doktor der Philosophie promoviert. Heute ist er Professor an der Bulgarischen Akademie der Wissenschaften.',
            index: 5,
        },
        {
            language: "DE",
            tabTitle: "Mariana Tsakova",
            title: "Mariana Tsakova, Schülerin ab 1980",
            url: "https://youtu.be/NS2gcFKjSjU",
            description:
                'Frau Tsakova ist heute selbst Lehrerin am 91.Deutschen Gymnasium und besuchte noch vor der Öffnung als Schülerin das "Karl Liebknecht Gymnasium". Sie spricht hier über das damalige Schulleben, Strafen und Einschränkungen, aber auch gesellschaftlich, gefühlte Vorteile des Sozialismus.',
            index: 6,
        },
        {
            language: "DE",
            tabTitle: "Georgi Nonchev",
            title: "Georgi Nonchev, Schüler ab 1984",
            url: "https://youtu.be/RGqpIO8MxiU",
            description:
                "Georgi war Schüler ab 1984 und berichtet über sehr viele Dinge um und in der früheren Schule.",
            index: 6,
        },
        {
            language: "BG-SUB",
            tabTitle: "Frau Teoharova",
            title: "Genoveva Teoharova, Schülerin und Lehrerin, 1985",
            url: "https://youtu.be/E0HWNKtzKE0",
            description:
                "Frau Teoharova war in den Jahren 1990/1991 Lehrerin am Prof. Konstantin Galabov Gymnasium und war früher selbst Schülerin des 91. Gymnasiums, vor der Öffnung Bulgariens.",
            index: 7,
        },
        {
            language: "BG",
            tabTitle: "Nikolay Chernokozhev",
            title: "Nikolay Chernokozhev, Schüler Ende '70 Anfang '80",
            url: "https://youtu.be/dT7h43juLvw",
            description:
                "Dr. phil. Nikolay Chernokozhev war in den Mittleren Jahren des Sozialismus am Karl Liebknecht Gymnasium Schüler. Nach seinem Abschluss hat er sich mit Übersetzung deutscher Fachbücher und selber als Autor solcher beschäftigt. Heutzutage ist er ein Professor für bulgarische Philologie an der Sofioten Universität.",
            index: 8,
        },
        {
            language: "BG",
            tabTitle: "Boyka Kydrewa",
            title: "Boyka Kydrewa, Schülerin, 1983",
            url: "https://youtu.be/rvKOrqeRSsY",
            description:
                "Boyka Kydrewa spricht über die Schulzeit und die Entwicklung vor der Wende. Sie berichtet über die Unterschiede zwischen den deutschen und bulagrischen Lehrern. Weitere Themen sind Musik, verbotene Bücher und die Funktion der Medien. Frau Kydrewa erzählt über die spezifische Ideologiefächer, die Verhaltensnote und Schülerbrigaden.",
            index: 9,
        },
        {
            language: "DE",
            tabTitle: "Karin Stefanova",
            title: "Karin Stefanova, Lehrerin, stellv. Dir. 1965-1999",
            url: "https://youtu.be/FAI-DoWyrkk",
            description:
                "Frau Stefanova spricht über Erfahrungen, ein anderes Leben und eine strengere Schulzeit im Sozialismus. Die Unterschiede zwischen deutschen und bulgarischen Lehrern wie auch der Wechsel des Unterrichts nach der Wende sind ein Thema.",
            index: 10,
        },
        {
            language: "DE",
            tabTitle: "Nikolai Petrov",
            title: "Nikolai Petrov, Schüler ab 1975",
            url: "https://youtu.be/4ai_AA1QE_o",
            description:
                "Nikolai Petrov, heute für ein österreichisches Unternehmen tätig, spricht über das Verhältnis von Schülern zu Lehrern, ob es Beschränkungen oder Tabuthemen gab. Er spricht über Privilegien der Parteianhänger und deren Verhältnismäßigkeit. Auch Strafen und die Verhaltensnote sind Thema. Um Feierlichkeiten des sozialistischen Systems geht es gegen Ende.",
            index: 11,
        },
        {
            language: "BG",
            tabTitle: "Iordan Dotschev",
            title: "Iordan Dotschev, Schüler ab 1987",
            url: "https://youtu.be/XBzkXOSKnwc",
            description:
                "Iordan Dotschev erzählt über seine Erfahrungen in der Schule. Er berichtet über die Disziplin und den Einfluss der Politik im Schulleben. Andere Themen sind die verbotene Musik aus dem Westen, den Zugriff auf Information und das Aufwachen der Schüler. Das Interview behandelt auch die Beziehungen zwischen Lehrer und Schüler. Herr Dotschev teilt seine Meinung über das Reisen und die Möglichkeiten nach der Schule.",
            index: 12,
        },
        {
            language: "DE",
            tabTitle: "Tsvetanka Neikova",
            title: "Tsvetanka Neikova, Schülerin ab 1973",
            url: "https://youtu.be/A10LCO_Gw-A",
            description:
                "Frau Tsvetanka Neikova hat 1978 das Karl Liebknecht Gymnasium abgeschlossen. Sie studierte danach Elektronik an der Technische Universität in Sofia.",
            index: 13,
        },
    ],
};
export default allVids;

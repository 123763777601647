import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { LanguageContext } from "../contexts/LanguageContext";
import navbarContent from "../content/navbarContent";
import dropdownContent from "../content/dropdownContent";
import { useHistory } from "react-router-dom";

// remove padding from expandable list

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        // paddingBottom: "0",
        // paddingTop: "0",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    drawerLink: {
        fontFamily: "'Commissioner', serif",
        fontSize: "1rem",
    },
}));

export default function NestedList() {
    const classes = useStyles();
    const [open, setOpen] = React.useState();

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const { language /* changeLanguage */ } = useContext(LanguageContext);
    const { first, second, third, fourth } = dropdownContent[language];

    const { history } = navbarContent[language];
    const History = useHistory();

    return (
        <List component="nav" className={classes.root}>
            <ListItem button onClick={handleClick}>
                {/* <ListItemText primary="Geschichte" /> */}
                <span className={classes.drawerLink}>{history}</span>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        {/* <ListItemText primary={`${first}`} /> */}
                        <span
                            onClick={() => {
                                History.push("/bgschulsystem");
                            }}
                            className={classes.drawerLink}
                        >
                            {first}
                        </span>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        {/* <ListItemText primary={`${second}`} /> */}
                        <span
                            onClick={() => {
                                History.push("/gallery");
                            }}
                            className={classes.drawerLink}
                        >
                            {second}
                        </span>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        {/* <ListItemText primary={`${third}`} /> */}
                        <span
                            onClick={() => {
                                History.push("/archive");
                            }}
                            className={classes.drawerLink}
                        >
                            {third}
                        </span>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                        {/* <ListItemText primary={`${fourth}`} /> */}
                        <span
                            onClick={() => {
                                History.push("/history");
                            }}
                            className={classes.drawerLink}
                        >
                            {fourth}
                        </span>
                    </ListItem>
                </List>
            </Collapse>
        </List>
    );
}

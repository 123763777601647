import React, { useEffect, useState } from "react";
import FilledInput from "@material-ui/core/FilledInput";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Outlinedinput from "@material-ui/core/Outlinedinput";
import Navbar from "./Navbar";
import TextField from "@material-ui/core/TextField";
import emailjs from "emailjs-com";
import background_top_left_mobile from "../images/background_top_left_mobile.png";
import background_top_left_clean from "../images/background_top_left_clean.png";
import background_left from "../images/background_left.png";
import background_bottom from "../images/background_bottom.png";
import sizes from "../styles/sizes";
import SendIcon from "@material-ui/icons/Send";
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { green } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import useDidMountEffect from "../hooks/useDidMountEffect";

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3ca729",
        },
        "&:hover .MuiInputBase-input": {
            borderBottomColor: "#3ca729",
        },
        "&:hover .MuiInput-underline": {
            borderBottomColor: "#3ca729",
        },
        "&:hover .MuiInput-underline:before": {
            borderBottomColor: "#3ca729",
            // borderBottom: "1px solid #3ca729",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3ca729",
        },
        "&:hover .MuiInputBase-input.Mui-error": {
            borderBottomColor: "#3ca729",
        },
        "&:hover .MuiInput-underline.Mui-error": {
            borderBottomColor: "#3ca729",
        },
        "&:hover .MuiInput-underline.Mui-error:before": {
            borderBottomColor: "#3ca729",
            // borderBottom: "1px solid red",
        },
        "& .MuiInput-underline.Mui-error:after": {
            borderBottomColor: "red",
        },
        "& .MuiInput-underline.Mui-error.Mui-focused:after": {
            borderBottomColor: "#3ca729",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "darkgrey",
            },
            "&:hover fieldset": {
                borderColor: "#3ca729",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3ca729",
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        "@global": {
            "input.MuiInputBase-input.MuiInput-input:hover": {
                //   WebkitFontSmoothing: 'auto',
                borderColor: "limegreen",
                // border: "2px solid red",
            },
            "p.MuiFormHelperText-root": {
                // borderBottom: "1px solid red",
                height: "0",
                margin: "0",
            },
        },
        paddingTop: "64px",
        display: "flex",
        height: "100vh",
        backgroundColor: "#f5f1e9",
        backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "80%, 30%",
        [sizes.down("xl")]: {
            backgroundSize: "85%, 32.5%",
        },
        [sizes.down("lg")]: {
            backgroundSize: "100%, 32.5%",
        },
        [sizes.down("md")]: {
            backgroundSize: "100%, 32.5%",
        },
        backgroundPosition: "top left, bottom right",
        backgroundAttachment: "fixed, fixed",
        [sizes.down("sm")]: {
            backgroundImage: `url(${background_top_left_mobile}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "55%, 45%",
            padding: "0",
            margin: "0",
        },
        [sizes.down("mobilelg")]: {
            backgroundImage: `none`, //"url(" + background_bottom + ")",
            backgroundImage: `url(${background_top_left_clean}), url(${background_bottom})`, //"url(" + background_bottom + ")",
            backgroundSize: "55%, 35%",
            backgroundPosition: "top 64px left 0px, bottom right",
            padding: "0",
            margin: "0",
        },
        paddingRight: "0",
    },
    form: {
        // border: "2px solid red",
        display: "flex",
        flexFlow: "column",
        // border: "2px solid red",
        width: "60%",
        height: "80%",
        justifyContent: "center",
        alignContent: "center",
        margin: "auto auto",
        [sizes.down("lg")]: {
            width: "85%",
        },
        [sizes.down("md")]: {
            width: "75%",
        },
        [sizes.down("mobilelg")]: {
            width: "100%",
            padding: "0 2rem",
            paddingTop: "3rem",
            // backgroundColor: "rgba(255,255,255,0.75)",
        },
        [sizes.down("xs")]: {
            width: "95%",
        },

        // backgroundColor: "#7ED957",
        // background: "rgb(126, 217,I87",
        // background:
        //     "linear-gradient(90deg, rgba(54,162,152,1) 0%, rgba(56,178,165,1) 34%, rgba(126,217,87,1) 87%, rgba(123,223,80,1) 100%)",
        // boxShadow: "0px 0px 24px 3px rgba(0,0,0,0.75)",
    },
    container: {
        // border: "2px solid blue",
        width: "75%",
        display: "flex",
        flexFlow: "column",
        margin: "0 auto",
        [sizes.down("mobilelg")]: {
            width: "100%",
            // height: "auto",
        },
    },
    formControl: {
        marginTop: "2rem",
    },
    input: {
        borderRadius: "0",
    },

    cssTextField: {
        margin: theme.spacing(1),
        fontFamily: "'Commissioner', serif",
        [sizes.down("mobilelg")]: {
            marginTop: "0.75rem",
            marginBottom: "0rem",
            paddingBottom: "0.5rem",
        },
    },
    button: {
        margin: theme.spacing(1),
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 48,
        padding: "0 30px",
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        background: "linear-gradient(45deg, #7ED957 30%, #2cbfcd 90%)",
        boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
    },
    messageArea: {
        marginTop: "1.5rem",
        [sizes.down("mobilelg")]: {
            marginBottom: "0.5rem",
        },
        marginBottom: "1rem",
    },
}));

// console.log("INNER HEIGHT::::::::::::::", window.innerHeight);
const winHeight = window.innerHeight;
const winWidth = window.innerWidth;
var numRows = 10;
if (winHeight < 650 && winHeight > 580) {
    numRows = 7;
} else if (winHeight <= 580) {
    numRows = 6;
} else {
    numRows = 10;
}

const regex = RegExp("[a-z\\.\\-A-Z0-9]+@[a-zA-Z0-9]+\\.[a-z]+");
export default function ContactPage() {
    const classes = useStyles();

    const [state, setState] = useState({
        key: false,
    });

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    // const handleSubjectChange = (event) => {
    //     setSubject(event.target.value);
    // };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    // const [subject, setSubject] = React.useState("");
    const [nameIsValid, setNameIsValid] = React.useState(true);
    const [emailIsValid, setEmailIsValid] = React.useState(true);
    const [messageIsValid, setMessageIsValid] = React.useState(true);
    const [emailMessage, setEmailMessage] = React.useState(
        "Wird zur Rückmeldung gebraucht"
    );
    const [formSubmitted, setFormSubmitted] = React.useState(false);

    function validateName(name) {
        if (name.length > 0) {
            setNameIsValid(true);
            console.log("name is valid");
        } else {
            setNameIsValid(false);
            console.log("name is invalid");
        }
    }

    function validateEmail(email) {
        // setEmailIsValid(false);
        console.log("EMAIL.............", email);
        if (email.length > 0) {
            if (regex.test(email)) {
                setEmailIsValid(true);
                console.log("email is valid");
            } else {
                console.log("email is invalid");
                setEmailMessage("Please enter a valid E-Mail address");
                setEmailIsValid(false);
            }
        } else {
            setEmailIsValid(false);
            console.log("email is invalid");
            setEmailMessage("Email can't be empty");
        }
    }

    function validateMessage(message) {
        if (message.length > 0) {
            setMessageIsValid(true);
            console.log("message is valid");
        } else {
            setMessageIsValid(false);
            console.log("message is invalid");
        }
    }

    function sendEmail(e) {
        e.preventDefault();
        // check if the email contains @ and if not return an error

        // ADD LOGIC TO SET ISVALID VARIABLES TO FALSE IF SOMETHING IS NOT VALID
        // validateInput(name, email, message);

        // console.log(
        //     "RESULT FROM VALIDATION",
        //     validateInput(name, email, message)
        // );
        validateName(name);
        validateEmail(email);
        validateMessage(message);
        console.log("NOW VALIDATING INPUT ..................");
        // validateInput(name, email, message);

        console.log(
            "nameIsValid: ",
            nameIsValid,
            " name.length: ",
            name.length,
            " emailIsValid: ",
            emailIsValid,
            " email.length: ",
            email.length,
            " messageIsValid: ",
            messageIsValid,
            " message.length: ",
            message.length
        );
        if (
            nameIsValid &&
            name.length !== 0 &&
            emailIsValid &&
            email.length !== 0 &&
            messageIsValid &&
            message.length !== 0
        ) {
            console.log("==================FORM VALIDATED==================");
            // console.log("e.target:::::::::::", e.target);
            if (nameIsValid && emailIsValid && messageIsValid) {
            }
            console.log("email sent...");
            emailjs
                .sendForm(
                    "gmail",
                    "template_04651hh",
                    e.target,
                    "user_uH4F0eC54z5QAlDrY3Mub"
                )
                .then(
                    (result) => {
                        console.log(result.text);
                        setNameIsValid(true);
                        setEmailIsValid(true);
                        setMessageIsValid(true);
                        setFormSubmitted(true);
                        setName("");
                        setEmail("");
                        setMessage("");
                        setEmailMessage("Wird zur Rückmeldung gebraucht");
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        } else {
            console.log("========= INPUT IS INVALID =========");
        }
    }

    // useEffect(() => {
    //     validateEmail(email);
    // }, [email]);

    useDidMountEffect(() => {
        if (!formSubmitted) {
            validateEmail(email);
        }
        // react please run me if 'key' changes, but not on initial render
    }, [state.key, email]);

    return (
        <div className={classes.root}>
            <Navbar></Navbar>
            {/* <form className={classes.form}> */}
            <div className={classes.container}>
                <form className={classes.form} onSubmit={sendEmail} noValidate>
                    <CssTextField
                        required
                        className={classes.cssTextField}
                        label="Name"
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleNameChange}
                        error={!nameIsValid}
                        helperText={!nameIsValid ? "Name can't be empty" : ""}
                    />
                    {/* <InputLabel>Name</InputLabel> */}
                    <CssTextField
                        required
                        className={classes.cssTextField}
                        label="E-Mail"
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        helperText={emailMessage}
                        error={!emailIsValid}
                    />
                    {/* <InputLabel>Email</InputLabel> */}
                    {/* <CssTextField
                        className={classes.cssTextField}
                        label="Betreff"
                        type="text"
                        name="subject"
                        // value='Geschichts-AG Feedback'
                    /> */}
                    {/* <InputLabel>Message</InputLabel> */}
                    {/* <Textarea name="message" /> */}
                    <CssTextField
                        onChange={handleMessageChange}
                        required
                        className={`${classes.cssTextField} ${classes.messageArea}`}
                        variant="outlined"
                        multiline
                        value={message}
                        rows={numRows}
                        rowsMax={numRows}
                        label="Nachricht"
                        name="message"
                        error={!messageIsValid}
                        style={{
                            fontFamily: "'Commissioner', serif",
                        }}
                        helperText={
                            messageIsValid ? "" : "Message can't be emtpy"
                        }
                    ></CssTextField>
                    {/* <Input type="submit" value="Send" /> */}
                    <Button
                        className={`${classes.cssTextField} ${classes.button}`}
                        variant="outlined"
                        type="submit"
                        style={{
                            padding: "0.75rem 0",
                            fontSize: "1.25rem",
                            fontFamily: "'Commissioner', serif",
                            fontWeight: "300",
                        }}
                    >
                        Senden
                    </Button>
                </form>
            </div>
            {/* </form> */}
        </div>
    );
}

// {
/* <FormControl
    className={classes.formControl}
    // style={{ marginTop: "2rem" }}
    variant="standard"
>
    <InputLabel htmlFor="name">Name</InputLabel>
    <Input
        className={classes.input}
        id="name"
        value={name}
        onChange={handleNameChange}
    />
</FormControl>
<FormControl
    variant="standard"
    className={classes.formControl}
    // style={{ marginTop: "2rem" }}
>
    <InputLabel htmlFor="email">Email</InputLabel>
    <Input
        className={classes.input}
        type="email"
        id="email"
        value={email}
        onChange={handleEmailChange}
    />
</FormControl>
<FormControl
    variant="standard"
    className={classes.formControl}
    // style={{ marginTop: "2rem" }}
>
    <InputLabel htmlFor="subject">Subject</InputLabel>
    <Input
        className={classes.input}
        id="subject"
        value={subject}
        onChange={handleSubjectChange}
    />
</FormControl>
<TextField
    style={{
        marginTop: "4rem",
        borderRadius: "0",
        outline: "none",
    }}
    // className={classes.input}
    id="message"
    label="Your Message"
    multiline
    rowsMax={25}
    value={message}
    onChange={handleMessageChange}
    variant="outlined"
/> */
// }

// const validateInput = (name, email, message) => {
//     console.log("=======NOW VALIDATING INPUT.........===========");
//     console.log("name: ", name, " email: ", email, " message: ", message);

//     if (name.length == 0) {
//         setNameIsValid(false);
//         // console.log("Name can't be empty");
//         // return false;
//     } else {
//         setNameIsValid(true);
//     }
//     if (email.length == 0) {
//         // maybe set the email is valid to a string to be used as helper text if there is an error
//         setEmailIsValid(false);
//         setEmailMessage("Email can't be empty");
//         // return false;
//     } else if (!regex.test(email)) {
//         console.log("email failed the regex");
//         setEmailIsValid(false);
//         setEmailMessage("Please enter a valid E-Mail address");
//         // console.log();
//         // return false;
//     } else {
//         // console.log("EMAIL IS VALID");
//         setEmailIsValid(true);
//     }
//     if (message.length == 0) {
//         // console.log("Message can't be empty");
//         setMessageIsValid(false);
//         // return false;
//     } else {
//         setMessageIsValid(true);
//     }
//     // return true;
// };
// console.log("TESTING REGEX::::::::::::", regex.test("lyanev@mail.bg"));
